import React from "react";
import config from "../config/app.conf.json";
import { EmailInput } from "@cdk-rds/email-input";
import { PasswordInput } from "@cdk-rds/password-input";
import { fortellisTheme } from "@cdk-rds/core";
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink,
} from "@cdk-rds/page-header";
import {
  CardWrapper,
  CardBody,
  CardTitle,
  CardContent,
  CardSubtitle,
} from "@cdk-rds/card";
import { PrimaryButton, SecondaryButton } from "@cdk-rds/button";
import { PropTypes } from "prop-types";
import { ThemeProvider } from "styled-components";
import AppFooter from "../shared/components/AppFooter/appFooter";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Toast,
  TOAST_POSITIONS,
  TOAST_VARIANTS,
  TOAST_VISIBILITY_DURATIONS,
} from "@cdk-rds/toast";
import URLParser from '../shared/utilities/URLParser';
import SignInOnVerify from './signInOnVerify';

const homeURL = config.appPlatformInfo.url;
const cardStyle = {
  boxShadow: "none",
  borderRadius: 0,
  backgroundColor: "inherit",
};
const footerStyle = {
  bottom: 0,
  position: "absolute",
};
const forgotButton = {
  backgroundColor: "inherit",
  marginTop: "24px",
  width: "389px",
};
const forgotButtonBg = {
  backgroundColor: "",
  marginTop: "24px",
  width: "389px",
};

const cdkDealerPortalLoginUrl = config.clientSubDomainApps.cdkDealerPortal.url;
const brandName = config.appPlatformInfo.brandName;

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formDisabled: false,
      captchaResponseToken: "",
      captchaReset: false,
    };
    this.captchaCallback = this.captchaCallback.bind(this);
    this.resetCaptcha = this.resetCaptcha.bind(this);
    this.recaptchaRef = React.createRef();
  }
  captchaCallback(captchaResponse) {
    if (captchaResponse) {
      this.setState({
        formDisabled: false,
        captchaResponseToken: captchaResponse,
      });
    } else {
      this.setState({
        captchaResponseToken: captchaResponse,
      });
    }
  }
  resetCaptcha() {
    this.setState((setState) => ({
      captchaResponseToken: (setState.captchaResponseToken = ""),
    }));
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }
  }
  handleSubmitAction() {
    this.resetCaptcha();
    this.props.handleSubmit("", "");
  }
  render() {
    const { captchaResponseToken } = this.state;
    const isSignInDisabled = this.props.disabled ||
    (this.props.showcaptcha
      ? captchaResponseToken === null ||
        captchaResponseToken === ""
        ? true
        : false
      : false);

    if (this.props.isAccountActivation && this.props.shouldAutoApprove) {
        return (
            <SignInOnVerify
                {...this.props}
                isAccountActivation={this.props.isAccountActivation}
                shouldAutoApprove={this.props.shouldAutoApprove}
            />)
    } 

    return (
      <ThemeProvider
        theme={fortellisTheme}
        style={{ backgroundColor: "white" }}
      >
        <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
        <PageHeaderWrapper className="listOfOrganization__Page-header"  style={{"flex": 0}}>
          <PageHeaderTitle>
            <PageHeaderLink
              className="listOfOrganization__Page-link-home"
              iconPosition="leading"
              text="FORTELLIS"
              href={homeURL}
            />
          </PageHeaderTitle>
        </PageHeaderWrapper>
        <div className="parent-div" style={{flex: 1, padding:"0px"}}>
          <div style={{ marginLeft: "15%", paddingTop:"5%" }}>
            <CardWrapper style={cardStyle}>
              <CardBody>
                <CardTitle
                  className="listOfOrganization__text-head"
                  style={{
                    alignSelf: "center",
                    marginRight: 200,
                    width: "180px",
                  }}
                >
                  Sign In
                </CardTitle>
              </CardBody>
              <CardContent
                className="listOfOrganization__form-wrapper"
                style={{ alignSelf: "center" }}
              >
                {this.props.errorToast.show && 
                  <div>
                    <Toast
                      id="Error-title"
                      content={this.props.errorToast.title}
                      variant={TOAST_VARIANTS.NEGATIVE}
                    />
                  </div>
                }
                {this.props.isAccountActivation && this.props.shouldAutoApprove && <div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px" }}
                >
                Your email is verified. Sign in to continue to your new account.
                </div>}
                {
                  this.props.isUserExists === true &&
                    this.props.inviteStatus === "pending" &&
                    <div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px" }}
                >
                {`Accept your invitation to join ${this.props.orgName} by signing in.`}
                </div>
                }
                <div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px", marginTop: "14px" }}
                >
                  <EmailInput
                    id="username"
                    name="username"
                    label="Work Email"
                    value={
                      this.props.user && this.props.inviteStatus === "pending"
                        ? this.props.user
                        : this.props.username
                    }
                    onChange={this.props.handleChange}
                    isFocused={true}
                    autoFocus
                    isDisabled={
                      (this.props.isUserExists === true &&
                        this.props.inviteStatus === "pending") ||
                        this.props.isInvitedUser
                        ? true
                        : false
                    }
                  />
                </div>
                <div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px" }}
                >
                  <PasswordInput
                    id="password-input"
                    isDisabled={this.props.isInvitedUser ? true : false}
                    isReadOnly={false}
                    isRequired={false}
                    label="Password"
                    name="passwordInput"
                    onChange={this.props.handlePasswordChange}
                    size="standard"
                    type="text"
                    value={this.props.passwordInput}
                  />
                </div>
                <div>
                  {this.props.showcaptcha && (
                    <ReCAPTCHA
                      id="challengecard__recaptcha"
                      ref={this.recaptchaRef}
                      sitekey={this.props.recaptchaSiteKey}
                      onChange={this.captchaCallback}
                    />
                  )}
                </div>
                <div
                  className="listOfOrganization__button-wrapper--primary"
                  style={{ width: "389px" }}
                >
                  <PrimaryButton
                    type=""
                    text="Sign In"
                    className="listOfOrganization__button--primary"
                    onClick={() => this.handleSubmitAction()}
                    isLoading={isSignInDisabled}
                  >
                  </PrimaryButton>
                </div>
                <SecondaryButton
                  style={
                    this.props.errorToast.show ? forgotButtonBg : forgotButton
                  }
                  className="ssocard__forgotpwd-text--center"
                  text={"Forgot Your Password?"}
                  type=""
                  onClick={this.props.goToResetPwd}
                ></SecondaryButton>
                <CardSubtitle
                  className="ssocard__account-text--center"
                  style={{ marginTop: "24px" }}
                >
                  Don't have an Account?{" "}
                  <span
                    className="anchor c-text-signup__link util__anchor--pointer"
                    onClick={this.props.goToSignup}
                  >
                    {"Sign Up."}
                  </span>
                </CardSubtitle>

                <div className="or-divider-line">
                  <h2>
                    <span>{"OR"}</span>
                  </h2>
                </div>

                <div
                  className="listOfOrganization__button-wrapper--primary"
                  style={{ width: "389px" }}
                >
                  <PrimaryButton
                    type=""
                    text="Sign in with connectCDK"
                    className="listOfOrganization__button--primary"
                    style={{'backgroundColor':'#0196d1'}}
                    onClick={() => {
                      window.open(cdkDealerPortalLoginUrl);
                    }}
                  ></PrimaryButton>
                </div>
              </CardContent>
            </CardWrapper>
          </div>
          <div className="image-wrapper">
            <div className="image-login"></div>
          </div>
        </div>

        <div className="listOfOrganization__footer">
          <AppFooter />
        </div>
        </div>
      </ThemeProvider>
    );
  }
}
SignInForm.propTypes = {
  username: PropTypes.string,
  passwordInput: PropTypes.string,
  handleSubmit: PropTypes.func,
  handlePasswordChange: PropTypes.func,
  goToSignup: PropTypes.func,
  goToResetPwd: PropTypes.func,
  disabled: PropTypes.string,
  isUserExists: PropTypes.bool,
  inviteStatus: PropTypes.string,
  errorToast: PropTypes.object,
  isAccountActivation: PropTypes.bool,
  shouldAutoApprove: PropTypes.bool,
  isInvitedUser: PropTypes.bool,
  orgName: PropTypes.string
};
export default SignInForm;
