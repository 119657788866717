import types from "./accountActivationTypes";
import jsonService from "../shared/utilities/jsonService";

const initialState = {
  status: types.UN_INITIALIZED
};
const initalizedState = {
  status: types.INITIALIZED
};
const failState = {
  status: types.FAILED
};

const DEFAULT_ERROR_MSG = "Error has occurred during account activation!";

const getErrorPayloadMsg = apierror => {
  // The reason behind looking for payload.message is because payload is the JSON obj returned by the signup api
  return !!apierror && !!apierror.payload && !!apierror.payload.message
    ? apierror.payload.message
    : DEFAULT_ERROR_MSG;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ACTIVATE_POST_REQUEST:
      return initalizedState;
    case types.ACTIVATE_POST_RECEIVE:
      let parsedObj = jsonService().parse(action.response);
      let successState = {
        status: types.SUCCESS,
        email: parsedObj.payload.email,
        firstName: parsedObj.payload.firstName
      };
      return successState;
    case types.ACTIVATE_POST_ERROR:
      console.error({
        Message: "Account Activation Failed",
        Context: "ACCOUNT_ACTIVATION"
      });
      return Object.assign(failState, {
        errorMessage: getErrorPayloadMsg(action.apierror ? action.apierror : {})
      });
    case types.CLEAR_ACCOUNT_ACTIVATE_FLAG:
      return initialState;
    default:
      return initialState;
  }
};
