import React from "react";
import config from "../config/app.conf.json";
import { fortellisTheme } from "@cdk-rds/core";
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink,
} from "@cdk-rds/page-header";
import {
  CardWrapper,
  CardContent,
} from "@cdk-rds/card";
import { PrimaryButton } from "@cdk-rds/button";
import { PropTypes } from "prop-types";
import { ThemeProvider } from "styled-components";
import AppFooter from "../shared/components/AppFooter/appFooter";
import { withOktaAuth } from '@okta/okta-react';


const homeURL = config.appPlatformInfo.url;
const cardStyle = {
  boxShadow: "none",
  borderRadius: 0,
  backgroundColor: "inherit",
};

class SignInOnVerify extends React.Component {
  constructor(props) {
    super(props);
  }

  handleSubmitAction() {
    this.props.oktaAuth.signInWithRedirect({
        originalUri: '/list-of-organizations'
    })
  }

  render() {

    return (
      <ThemeProvider
        theme={fortellisTheme}
        style={{ backgroundColor: "white" }}
      >
        <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
        <PageHeaderWrapper className="listOfOrganization__Page-header"  style={{"flex": 0}}>
          <PageHeaderTitle>
            <PageHeaderLink
              className="listOfOrganization__Page-link-home"
              iconPosition="leading"
              text="FORTELLIS"
              href={homeURL}
            />
          </PageHeaderTitle>
        </PageHeaderWrapper>
        <div className="parent-div" style={{flex: 1, padding:"0px"}}>
          <div style={{ marginLeft: "15%", paddingTop:"12%" }}>
            <CardWrapper style={cardStyle}>
              <CardContent
                className="listOfOrganization__form-wrapper"
                style={{ alignSelf: "center" }}
              >
                <div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px" }}
                >
                Your email is verified. Sign in to continue to your new account.
                </div>
                <div
                  className="listOfOrganization__button-wrapper--primary"
                  style={{ width: "389px" }}
                >
                  <PrimaryButton
                    type=""
                    text="Sign In"
                    className="listOfOrganization__button--primary"
                    onClick={() => this.handleSubmitAction()}
                    isLoading={this.props.disabled}
                  >
                  </PrimaryButton>
                </div>
              </CardContent>
            </CardWrapper>
          </div>
          <div className="image-wrapper">
            <div className="image-login"></div>
          </div>
        </div>

        <div className="listOfOrganization__footer">
          <AppFooter />
        </div>
        </div>
      </ThemeProvider>
    );
  }
}
SignInOnVerify.propTypes = {
  isAccountActivation: PropTypes.bool,
  shouldAutoApprove: PropTypes.bool,
  disabled: PropTypes.bool
};
export default withOktaAuth(SignInOnVerify);
