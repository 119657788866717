import types from "./registrationTypes";
import { reduxapi } from "@cdkglobal/react-core";
import signupConfig from "../api/signup";
import getTnc from "../api/getLatestTnc";

export function getLatestTnc() {
  return {
    [reduxapi.CALL_API]: {
      ...getTnc(),
      types: [
        types.LATEST_TNC_GET_REQUEST,
        types.LATEST_TNC_GET_RECEIVE,
        types.LATEST_TNC_GET_ERROR
      ]
    }
  };
}

export function saveSignupDetails(signupDetails) {
  return {
    type: types.SAVE_SIGN_UP_DETAILS,
    signupDetails
  };
}

export function signup(data, isInvitedUser = false, isLwfRequest = false) {
  delete data.tncText;
  return {
    [reduxapi.CALL_API]: {
      ...signupConfig(data, isInvitedUser, isLwfRequest),
      types: [
        types.SIGNUP_POST_REQUEST,
        types.SIGNUP_POST_RECEIVE,
        types.SIGNUP_POST_ERROR
      ]
    }
  };
}

export function setInvitedUser(isInvitedUser) {
  return {
      type: types.SET_INVITED_USER,
      isInvitedUser
  };
};