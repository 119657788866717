import jsonService from './jsonService';
// the reason we need/use this jsonService is because the accounts backend sends back the apierror.payload in a string on localhost environments
// but when you send the apierror.payload on dev, or a server, the apierror.payload comes back as an object
const payloadParser = {
    getErrorMessageFromPayload: action => {
        let payloadMessage = '';
        let parsedPayload = {};

        if (action && action.apierror && action.apierror.payload) {
            parsedPayload = jsonService().parse(action.apierror.payload);
            if (
                parsedPayload.errors &&
                parsedPayload.errors.length > 0 &&
                parsedPayload.errors[0].message
            ) {
                payloadMessage = parsedPayload.errors[0].message;
            }
        }
        
        return payloadMessage;
    }
};

export default payloadParser;
