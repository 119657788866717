import checkToken from "../api/checkToken";
import securityAnswer from "../api/securityAnswer";
import newPassword from "../api/newPassword";
import types from "./challengeTypes";
import { reduxapi } from "@cdkglobal/react-core";

export const actions = {
  checkToken: data => {
    return {
      [reduxapi.CALL_API]: {
        ...checkToken(data),
        types: [
          types.CHECK_TOKEN_POST_REQUEST,
          types.CHECK_TOKEN_POST_RECEIVE,
          types.CHECK_TOKEN_POST_ERROR
        ]
      }
    };
  },
  securityAnswer: data => {
    return {
      [reduxapi.CALL_API]: {
        ...securityAnswer(data),
        types: [
          types.SECURITY_ANSWER_POST_REQUEST,
          types.SECURITY_ANSWER_POST_RECEIVE,
          types.SECURITY_ANSWER_POST_ERROR
        ]
      }
    };
  },
  newPassword: data => {
    return {
      [reduxapi.CALL_API]: {
        ...newPassword(data),
        types: [
          types.NEW_PASSWORD_POST_REQUEST,
          types.NEW_PASSWORD_POST_RECEIVE,
          types.NEW_PASSWORD_POST_ERROR
        ]
      }
    };
  }
};
