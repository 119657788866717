import types from "./settingsTypes";

const initialState = {
  userInfo: {
    firstName: "",
    lastName: "",
    email: "",
    primaryPhone: "",
    organization: ""
  },
  type: "",
  requestInProgress: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_INFO_GET_REQUEST:
      return Object.assign({}, state, {
        requestInProgress: true
      });
    case types.USER_INFO_GET_RECEIVE:
      return Object.assign({}, state, {
        userInfo: action.response.profile,
        type: action.type,
        requestInProgress: false
      });
    case types.USER_INFO_GET_ERROR:
      return Object.assign({}, state, {
        type: action.type,
        message: action.apierror.message
          ? action.apierror.message + ". Please refresh."
          : action.apierror.payload.errors[0].message.message,
        requestInProgress: false
      });
    case types.USER_INFO_POST_REQUEST:
      return Object.assign({}, state, {
        requestInProgress: true
      });
    case types.USER_INFO_POST_RECEIVE:
      return Object.assign({}, state, {
        userInfo: action.response.body.profile,
        type: action.type,
        message: "Your changes have been saved.",
        requestInProgress: false
      });
    case types.USER_INFO_POST_ERROR:
      return Object.assign({}, state, {
        type: action.type,
        message: action.apierror.message
          ? action.apierror.message + ". Please refresh."
          : action.apierror.payload.errors[0].message.message,
        requestInProgress: false
      });
    case types.UPDATE_SETTINGS_MESSAGE:
      return Object.assign({}, state, {
        type: action.type,
        message: action.message
      });
    default:
      return state;
  }
};
