import types from "./securitySettingsTypes";
import payloadParser from "../shared/utilities/payloadParser";

const initializedState = {
  actionType: "",
  apiResponse: {},
  message: "",
  status: types.INITIALIZED
};

const MFA_ACTIVATED_MESSAGE = "Two-step verification enabled.";
const CODE_SENT_MESSAGE = "Verification code successfully sent.";

export default (state = initializedState, action) => {
  switch (action.type) {
    case types.GET_FACTORS_REQUEST:
      return Object.assign({}, state, {
        actionType: action.type,
        message: "",
        status: types.INITIALIZED
      });
    case types.GET_FACTORS_RECEIVE:
      return {
        actionType: action.type,
        factors: action.response || [],
        message: "",
        status: types.SUCCESS
      };
    case types.GET_FACTORS_ERROR:
      let apiError = payloadParser.getErrorMessageFromPayload(action);
      console.error({
        Message: "Get Factors Failed",
        error: apiError || "Error while fetching the factors.",
        Context: "Security Settings"
      });
      return {
        actionType: action.type,
        factors: [],
        message: apiError || "Something went wrong. Please try again.",
        status: types.FAILED
      };

    case types.ENROLL_FACTOR_POST_REQUEST:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: "",
        status: types.INITIALIZED
      });
    case types.ENROLL_FACTOR_POST_RECEIVE:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: action.response || {},
        message:
          action.response &&
          action.response.status &&
          action.response.status.toLowerCase() === "active"
            ? MFA_ACTIVATED_MESSAGE
            : "",
        status: types.SUCCESS
      });
    case types.ENROLL_FACTOR_POST_ERROR:
      let apiErr = payloadParser.getErrorMessageFromPayload(action);
      console.error({
        Message: "Failed to enroll factor",
        error: apiErr || "Error occured while enrolling the factor.",
        Context: "Security Settings"
      });
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: apiErr || "Something went wrong. Please try again.",
        status: types.FAILED
      });

    case types.RESEND_FACTOR_POST_REQUEST:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: "",
        status: types.INITIALIZED
      });
    case types.RESEND_FACTOR_POST_RECEIVE:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: action.response || {},
        message: CODE_SENT_MESSAGE,
        status: types.SUCCESS
      });
    case types.RESEND_FACTOR_POST_ERROR:
      let resendErr = payloadParser.getErrorMessageFromPayload(action);
      console.error({
        Message: "Failed to resend the factor",
        error: resendErr || "Error occured while resending the factor.",
        Context: "Security Settings"
      });
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: resendErr || "Something went wrong. Please try again.",
        status: types.FAILED
      });
    case types.ACTIVATE_FACTOR_POST_REQUEST:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: "",
        status: types.INITIALIZED
      });
    case types.ACTIVATE_FACTOR_POST_RECEIVE:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: action.response || {},
        message: MFA_ACTIVATED_MESSAGE,
        status: types.SUCCESS
      });
    case types.ACTIVATE_FACTOR_POST_ERROR:
      let activateErr = payloadParser.getErrorMessageFromPayload(action);
      console.error({
        Message: "Failed to activate the factor",
        error: activateErr || "Error occured while activating the factor.",
        Context: "Security Settings"
      });
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: activateErr || "Something went wrong. Please try again.",
        status: types.FAILED
      });
    case types.DEACTIVATE_FACTOR_POST_REQUEST:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: "",
        status: types.INITIALIZED
      });
    case types.DEACTIVATE_FACTOR_POST_RECEIVE:
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: "",
        status: types.SUCCESS
      });
    case types.DEACTIVATE_FACTOR_POST_ERROR:
      let deactivateErr = payloadParser.getErrorMessageFromPayload(action);
      console.error({
        Message: "Failed to deactivate the factor",
        error: deactivateErr || "Error occured while deactivating the factor",
        Context: "Security Settings"
      });
      return Object.assign({}, state, {
        actionType: action.type,
        apiResponse: {},
        message: deactivateErr || "Something went wrong. Please try again.",
        status: types.FAILED
      });
    case types.UPDATE_SECURITY_SETTINGS_SNACKBAR_MESSAGE:
      return Object.assign({}, state, {
        actionType: action.type,
        message: action.message
      });

    case types.SHOW_CHANGE_NUMBER_WARNING:
      return Object.assign({}, state, {
        shouldShowNumberChangeWarnig: action.showNumberChangeWarnig
      });
    default:
      return state;
  }
};
