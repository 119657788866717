import React from "react";
import PropTypes from "prop-types";

import Confirmation from "../Confirmation/confirmation";
import URLParser from "../shared/utilities/URLParser";
import config from "../config/app.conf.json";

const Success = props => {
  let shouldAutoApprove = URLParser.getQueryStringParamValue(
    "autoapprove",
    props.location.search
  );

  return (
    <Confirmation
      title={"Email Verification Successful"}
      description={
        shouldAutoApprove
          ? `
                        Your email has been verified and activated.
                    `
          : `
                    Thank you! Your application is under review and we’ll email you soon.<br>
                    For more information about what Fortellis is, check out these <a href=${config.nav.docs.url}/docs/general/overview/introduction/>resources.</a>
                    `
      }
      {...props}
    />
  );
};

Success.propTypes = {
  location: PropTypes.object
};

export default Success;
