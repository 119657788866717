import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'normalize-css';

// UX/UI ##ZEBRAFISH## FONTS:
// Load Raleway typeface
import 'typeface-raleway';
// Load Montserrat typeface
import 'typeface-montserrat';
import { initAmplitude } from './utils/amplitude';

initAmplitude();
ReactDOM.render(<App/>, document.getElementById('root'));
