import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

const ShowHide = ({ show = false, className, children }) => {
    const style = {
        display: show ? 'block' : 'none'
    };

    return (
        <div
            className={classnames('showhide', 'showhide__wrapper', className)}
            style={style}
        >
            {children}
        </div>
    );
};

ShowHide.propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.object
};

export default ShowHide;
