import * as config from "../config/app.conf.json";

export default {
  personalInfo: (userId, accessToken) => {
    let configUrl = config.api.personalInfo.replace(":userId", userId);
    return {
      url: configUrl,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    };
  },
  updatePersonalInfo: (userId, data, accessToken) => {
    let configUrl = config.api.personalInfo.replace(":userId", userId);
    return {
      url: configUrl,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    };
  }
};
