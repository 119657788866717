import config from "../config/app.conf.json";

export default function(data, isInvitedUser, isLwfRequest = false) {
  let headers = {
    "Content-Type": "application/json"
  };

  if (isLwfRequest) {
    headers["lwf-email"] = data.email;
  }

  return {
    url: config.api.signup,
    method: "POST",
    data: { ...data, isInvitedUser },
    headers
  };
}
