import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { Footer } from "@fortellis/footer";

import config from "../config/app.conf.json";
function AuthFooter({ auth, mobile }) {
  config.footer.copyright.trademark =
    "©" + new Date().getFullYear() + config.footer.copyright.trademarkString;
  return (
    <Footer
      mobile={mobile}
      authenticated={auth.isAuthenticated}
      items={config.footer}
      routerLink={Link}
      signup={config.signup.url}
    />
  );
}

AuthFooter.propTypes = {
  auth: PropTypes.object.isRequired,
  mobile: PropTypes.bool
};

AuthFooter.defaultProps = {
  mobile: false
};

export default withRouter(withAuth(AuthFooter));
