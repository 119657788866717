import React from "react";
import classnames from "classnames";
import {
  Card,
  TextField,
  Subheading2,
  Button,
  Snackbar,
  TextFieldHelperText
} from "@cdk-uip/react";
import ShowHide from "../shared/components/ShowHide/ShowHide";
import PropTypes from "prop-types";
import PasswordStrengthBar from "react-password-strength-bar";
import passwordValidator from "../shared/utilities/passwordValidator";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      password: "",
      confirmPassword: "",
      isPasswordValid: true,
      passwordsMatch: true,
      currentPasswordIcon: true,
      passwordIcon: true,
    };
    this.isValid = this.isValid.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.showCurrentPasswordIcon = this.showCurrentPasswordIcon.bind(this);
    this.showPasswordIcon = this.showPasswordIcon.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);
    this.getPasswordStrengthLabelClass = this.getPasswordStrengthLabelClass.bind(
      this
    );
  }

  componentDidMount() {
    if (this.state.email || this.state.primaryPhone) {
      this.isValid();
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  validatePassword(event) {
    this.setState(
      {
        isPasswordValid:
          event.target.value.length > 7 &&
          passwordValidator.validate(event.target.value),
        password: event.target.value,
        confirmPassword: event.target.value
      },
      this.saveUserInformation
    );
  }

  isValid() {
    return this.isFormValid();
  }
  isFormValid() {
    return (
      this.state.currentPassword !== "" &&
      this.state.password !== "" &&
      this.state.isPasswordValid
    );
  }

  showCurrentPasswordIcon(e) {
    const { currentPasswordIcon } = this.state;
    this.setState({
      currentPasswordIcon: !currentPasswordIcon
    });
    this.showPasswordValue(e);
  }

  showPasswordIcon(e) {
    const { passwordIcon } = this.state;
    this.setState({
      passwordIcon: !passwordIcon
    });
    this.showPasswordValue(e);
  }

  showPasswordValue(e) {
    var passwordField = document.getElementById(e.target.id);
    var value = passwordField.value;
    if (passwordField.type === "password") {
      passwordField.type = "text";
    } else {
      passwordField.type = "password";
    }
    passwordField.value = value;
  }

  shouldDisable() {
    return (
      this.state.currentPassword === "" ||
      this.state.password === "" ||
      !this.state.isPasswordValid
    );
  }

  shouldHide() {
    return this.state.currentPassword !== "" || this.state.password !== "";
  }
  async hideSnackbar() {
    this.props.updateMessage();
    if (this.props.settingsChangePassword.isPasswordChangeSuccessful) {
      await this.props.auth.logout();
    }
  }
  handleCancel() {
    this.setState({
      currentPassword: "",
      password: "",
      confirmPassword: ""
    });
  }

  handleSubmit() {
    let passwordData = {
      newPassword: this.state.password,
      oldPassword: this.state.currentPassword
    };
    this.props.changePassword(passwordData, this.props.userId, this.props.auth.accessToken);
    this.handleCancel();
  }

  getPasswordStrengthLabelClass() {
    if (this.state.password && this.state.password.length !== 0) {
      return passwordValidator.validate(this.state.password)
        ? "password-strength-label-green"
        : "password-strength-label-red";
    }
    return "";
  }

  render() {
    const { currentPasswordIcon, passwordIcon } = this.state;
    return (
      <Card className="stepper-registration-card settingsBackground">
        <Subheading2 className="settings-panel-header">
          {"Password"}
        </Subheading2>
        <div className="settings-subtitle">
          Enter your current password to make changes
        </div>
        <div>
          <div className="settings-input settings-pwd">
            <TextField
              label={"Current Password"}
              id="currentPassword"
              name="currentPassword"
              className="settngs-password"
              type="password"
              value={this.state.currentPassword}
              autoComplete="new-password"
              onChange={this.handleChange}
              trailingIcon={
                <i
                  className="material-icons visbilityIcon"
                  onClick={this.showCurrentPasswordIcon}
                  id="currentPassword"
                >
                  {currentPasswordIcon ? "visibility_off" : "visibility"}
                </i>
              }
            />
          </div>
          <div className="settings-input settings-pwd">
            <TextField
              label={"New Password"}
              id="password"
              name="password"
              className="settngs-password"
              type="password"
              placeholder="At least 8 characters"
              value={this.state.password}
              autoComplete="new-password"
              onChange={this.validatePassword}
              trailingIcon={
                <i
                  className="material-icons visbilityIcon"
                  onClick={this.showPasswordIcon}
                  id="password"
                >
                  {passwordIcon ? "visibility_off" : "visibility"}
                </i>
              }
            />
            {
              <PasswordStrengthBar
                className={classnames(
                  "password-strength-bar settings-input-password-strength-bar ",
                  this.getPasswordStrengthLabelClass()
                )}
                password={
                  this.state.password.length > 7 &&
                  (passwordValidator.validate(this.state.password)
                    ? this.state.password
                    : "1234567890p")
                }
                minLength={8}
                scoreWords={["WEAK", "WEAK", "GOOD", "GOOD", "STRONG"]}
                barColors={[
                  "#ffffff10",
                  "#CC0000",
                  "#008D00",
                  "#008D00",
                  "#008D00"
                ]}
                shortScoreWord={
                  this.state.password && this.state.password.length !== 0
                    ? "TOO SHORT"
                    : ""
                }
              />
            }
            <TextFieldHelperText
              persistent
              className={classnames("password-helper-text", {
                "password-helper-text--invalid": !this.state.isPasswordValid
              })}
            >
              Minimum 8 characters, mixed capitalization, 1 number, and 1 symbol
            </TextFieldHelperText>
          </div>

          <div className="settingsFooter">
            <ShowHide show={this.shouldHide()}>
              <Button className="cancelButton" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </ShowHide>
            <Button
              type="submit"
              raised
              primary
              disabled={this.shouldDisable()}
              onClick={this.handleSubmit}
            >
              {"SAVE"}
            </Button>
          </div>
        </div>
        <Snackbar
          className="settings-pwd-snackbar"
          show={!!this.props.settingsChangePassword.message}
          timeout={5000}
          multiline={true}
          actionOnBottom={true}
          message={this.props.settingsChangePassword.message}
          onClose={this.hideSnackbar}
        />
      </Card>
    );
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.object,
  settingsChangePassword: PropTypes.func,
  updateMessage: PropTypes.func,
  message: PropTypes.string,
  userId: PropTypes.string.isRequired,
  auth: PropTypes.string.isRequired,
};

export default ChangePassword;
