import React from 'react';
import config from '../../../config/app.conf.json';
import { LoginFooter } from '@cdk-uip/react';

// BRANDNAME
const brandName = config.appPlatformInfo.brandName;

// COPYRIGHT ASSETS & INFO:
const copyrightFooterString = '©' + new Date().getFullYear() + ' CDK Global, LLC';

// OTHER APP CONTENT URLS
const contactTermsUrl =
  config.clientSubDomainApps.developerNetworkApp.urlContentTerms;
const privacyUrl =
  config.clientSubDomainApps.developerNetworkApp.urlContentPrivacy;
const contactUrl =
  config.clientSubDomainApps.developerNetworkApp.urlContentContact;

class AppFooter extends React.Component {
    render() {
        return (
            <LoginFooter>
                {copyrightFooterString}{' '}
                <a
                    href={contactTermsUrl}
                    target="_blank"
                    rel='noopener noreferrer'
                    className="util__anchor--pointer cdk-login-footer__link"
                >
                    {'Terms of Use'}
                </a>{' '}
                <a
                    href={privacyUrl}
                    target="_blank"
                    rel='noopener noreferrer'
                    className="util__anchor--pointer cdk-login-footer__link"
                >
                    {'Privacy & Legal'}
                </a>{' '}
                <a
                    href={contactUrl}
                    target="_blank"
                    rel='noopener noreferrer'
                    className="util__anchor--pointer cdk-login-footer__link"
                >
                    {'Contact ' + brandName}
                </a>
            </LoginFooter>
        );
    }
}
export default AppFooter;
