import React from "react";

import {
  CardBody,
  CardContent,
  CardWrapper,
  CardSubtitle,
  CardTitle
} from "@cdk-rds/card";
import { PrimaryButton, SecondaryButton } from "@cdk-rds/button";
import PropTypes from "prop-types";
import config from "../config/app.conf.json";
import {  withAuth } from "@cdk-prod/fortellis-auth-context";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

//import requestJoinOrg from "../api/requestJoinOrg";
const cardStyle = {
  boxShadow: "none",
  borderRadius: 0,
  backgroundColor: "inherit"
};
const homeURL = config.appPlatformInfo.url;
const defaultRedirectURL = config.nav.user.menu[0].url || window.location.host;
export class CreateOrganization extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  handleSubmit(e) {
    window.location = `${defaultRedirectURL}/registration`;
  }

  handleSkip(e) {
    window.location = defaultRedirectURL;
  }

  render() {
    return (
            <div className="listOfOrganization__Page-wrapper">
              <CardWrapper style={cardStyle}>
                <CardBody>
                  <CardTitle className="listOfOrganization__text-head" style={{width:"600px"}}>
                    Create an Organization
                  </CardTitle>
                  <CardSubtitle className="listOfOrganization__text">
                    Share resources and collaborate with other members of your company.
                    You must be a part of an organization to develop or subscibe to APIs and Apps.
                  </CardSubtitle>
                </CardBody>

                <CardContent className="listOfOrganization__form-wrapper" style={{paddingBottom: "95px"}}>
                  <div className="ssocard-text-field-wrapper"></div>
                  <div className="listOfOrganization__card--wrapper">
                    <div className="listOfOrganization__button-wrapper--primary">
                      <PrimaryButton
                        isDisabled={false}
                        text="Create organization account"
                        type="submit"
                        onClick={this.handleSubmit}
                        className="listOfOrganization__button--primary"
                      ></PrimaryButton>
                      
                    </div>
                    <SecondaryButton style={{width:"100%", marginTop:"25px", backgroundColor:"inherit"}}
                              isDisabled={
                                false
                              }
                              text={
                                "Skip for now"
                              }
                              type=""
                              onClick={this.handleSkip}
                            ></SecondaryButton>
                  </div>
                </CardContent>
              </CardWrapper>
            </div>
    );
  }
}

CreateOrganization.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  sessionToken: PropTypes.string.isRequired
};


export default withAuth(
  withRouter(connect()(CreateOrganization))
);
