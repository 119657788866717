import React from "react";
import PropTypes from "prop-types";
import QueryString from "qs";
import Confirmation from "../Confirmation/confirmation";
import URLParser from "../shared/utilities/URLParser";

const VerifyEmailAddress = props => {
  const email = QueryString.parse(props.location.search, {
    ignoreQueryPrefix: true
  }).email;

  const firstname = QueryString.parse(props.location.search, {
    ignoreQueryPrefix: true
  }).firstname;

  const isLwfRequest =
    URLParser.getQueryStringParamValue("lwf", props.location.search) === "true";
  let subtitle = firstname
    ? `${firstname}, thank you for signing up!`
    : "Thank you for signing up!";
  let description = `We sent a verification email.`;

  if (email) {
    description = description.slice(0, -1);
    description = `${description} to ${email}.`;
  }

  return (
    <Confirmation
      title="Verify Your Email Account"
      description={description}
      subtitle={subtitle}
      isLwfRequest={isLwfRequest}
      {...props}
    />
  );
};

VerifyEmailAddress.propTypes = {
  location: PropTypes.object.isRequired
};

export default VerifyEmailAddress;
