import config from "../config/app.conf.json";
import uuid from "uuid/v4";

export const CustomLoginConfig = (() => {
  let query = (queryString => {
    let query = {};
    let queries = queryString.replace("?", "").split("&");
    queries.forEach(function(queryItem) {
      let keyValuePair = queryItem.split("=");
      query[keyValuePair[0]] = keyValuePair[1];
    });

    return query;
  })(decodeURIComponent(window.location.search));

  return {
    customeLogin: {
      client_id: query["client_id"] || config.api.authorize.query.client_id,
      response_type: query["response_type"] || ["code"],
      redirect_uri:
        query["redirect_uri"] || config.api.authorize.query.redirect_uri,
      scope: (
        (query["scope"] && query["scope"].split(",")) || [
          "openid",
          "email",
          "profile"
        ]
      ).join("%20"),
      display: "page",
      state: query["state"] || uuid(),
      nonce: query["nonce"] || uuid()
    }
  };
})();
