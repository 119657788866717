import apis from "../api/personalInfo";
import types from "./settingsTypes";
import { reduxapi } from "@cdkglobal/react-core";

export const actions = {
  personalInfo: (userId, accessToken) => {
    return {
      [reduxapi.CALL_API]: {
        ...apis.personalInfo(userId, accessToken),
        types: [
          types.USER_INFO_GET_REQUEST,
          types.USER_INFO_GET_RECEIVE,
          types.USER_INFO_GET_ERROR
        ]
      }
    };
  },
  updatePersonalInfo: (userId, data, accessToken) => {
    return {
      [reduxapi.CALL_API]: {
        ...apis.updatePersonalInfo(userId, data, accessToken),
        types: [
          types.USER_INFO_POST_REQUEST,
          types.USER_INFO_POST_RECEIVE,
          types.USER_INFO_POST_ERROR
        ]
      }
    };
  }
};
