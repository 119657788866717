import { connect } from 'react-redux';
import Registration from './registration';
import { withRouter } from 'react-router-dom';
import { saveSignupDetails, signup, getLatestTnc, setInvitedUser } from './registrationActions';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => {
  return {
    signupDetails: state.signupDetails,
    user: state.invite.email,
    isUserExists: state.invite.userExists,
    orgName: state.invite.orgName,
    inviteStatus: state.invite.status
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveSignupDetails,
      signup,
      getLatestTnc,
      setInvitedUser
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Registration)
);
