export default {
  CHECK_TOKEN_POST_REQUEST: "CHECK_TOKEN_POST_REQUEST",
  CHECK_TOKEN_POST_RECEIVE: "CHECK_TOKEN_POST_RECEIVE",
  CHECK_TOKEN_POST_ERROR: "CHECK_TOKEN_POST_ERROR",
  SECURITY_ANSWER_POST_REQUEST: "SECURITY_ANSWER_POST_REQUEST",
  SECURITY_ANSWER_POST_RECEIVE: "SECURITY_ANSWER_POST_RECEIVE",
  SECURITY_ANSWER_POST_ERROR: "SECURITY_ANSWER_POST_ERROR",
  NEW_PASSWORD_POST_REQUEST: "NEW_PASSWORD_POST_REQUEST",
  NEW_PASSWORD_POST_RECEIVE: "NEW_PASSWORD_POST_RECEIVE",
  NEW_PASSWORD_POST_ERROR: "NEW_PASSWORD_POST_ERROR"
};
