import { connect } from "react-redux";
import Challenge from "./challenge";
import { actions } from "./challengeActions";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => {
  return {
    disabled: state.challenge.disabled,
    checkTokenResponse: state.challenge.checkTokenResponse,
    token: state.challenge.token,
    status: state.challenge.status,
    challengeSubmitted: state.challenge.submitted,
    error: state.challenge.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkToken: data => {
      dispatch(actions.checkToken(data));
    },
    securityAnswer: data => {
      dispatch(actions.securityAnswer(data));
    },
    newPassword: data => {
      dispatch(actions.newPassword(data));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Challenge)
);
