import React from "react";
import Confirmation from "../Confirmation/confirmation";

const PasswordChanged = () => {
  return (
    <Confirmation
      title="Password Successfully Changed!"
      description="You may now sign in with your new password."
    />
  );
};

export default PasswordChanged;
