export default {
  ACTIVATE_POST_REQUEST: "ACTIVATE_POST_REQUEST",
  ACTIVATE_POST_RECEIVE: "ACTIVATE_POST_RECEIVE",
  ACTIVATE_POST_ERROR: "ACTIVATE_POST_ERROR",
  UN_INITIALIZED: "UN_INITIALIZED",
  INITIALIZED: "INITIALIZED",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  CLEAR_ACCOUNT_ACTIVATE_FLAG: "CLEAR_ACCOUNT_ACTIVATE_FLAG"
};
