const URLParser = {
    getQueryStringParamValue: (queryParam, URI) => {
        URI === '' ? (URI = ' ') : void 0;
        if (!queryParam || !URI) {
            throw new Error('URLParser: Bad Input');
        }
        let decodedURI = decodeURIComponent(URI);
        let regex = new RegExp('[?&]' + queryParam + '(?:=([^&]*))?');
        let match = regex.exec(decodedURI);
        return match && match.length ? match[1] : null;
    }
};

export default URLParser;
