import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@cdk-uip/react";
import "@cdk-uip/icons";
import PropTypes from "prop-types";

class FortellisDialog extends Component {
  render() {
    const {
      open,
      header,
      body,
      onAccept,
      accceptText,
      onCancel,
      cancelText
    } = this.props;
    return (
      <Dialog open={open}>
        <DialogHeader>
          <div className="fortellis-dialog--title">{header}</div>
        </DialogHeader>
        <DialogBody>
          {<div className="mfa-verify__dialog--subtitle">{body}</div>}
        </DialogBody>
        <DialogFooter>
          <Button className="dialog-button" onClick={onCancel}>
            {cancelText ? cancelText : "Cancel"}
          </Button>
          <Button className="dialog-button" onClick={onAccept}>
            {accceptText ? accceptText : "Accept"}
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

FortellisDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  header: PropTypes.node,
  body: PropTypes.node,
  accceptText: PropTypes.string,
  cancelText: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func.isRequired
};

export default FortellisDialog;
