import React from "react";
import PropTypes from "prop-types";
import { fortellisTheme } from "@cdk-rds/core";
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink
} from "@cdk-rds/page-header";
import { CardWrapper, CardBody, CardTitle, CardSubtitle } from "@cdk-rds/card";
import { ThemeProvider } from "styled-components";
import AppFooter from "../shared/components/AppFooter/appFooter";
import config from "../config/app.conf";
import QueryString from "qs";

const homeURL = config.appPlatformInfo.url;
const cardStyle = {
  boxShadow: "none",
  borderRadius: 0,
  paddingTop: "3%"
};
const parentDivStyles = {
  height: window.innerHeight - 84,
  backgroundColor: "white"
};
class ResetPasswordEmail extends React.Component {
  constructor(props) {
    super(props);
    this.goToExchange = this.goToExchange.bind(this);
    this.getDisplayText = this.getDisplayText.bind(this);
  }

  goToExchange() {
    window.location = config.clientSubDomainApps.developerNetworkApp.url;
  }

  getDisplayText() {
    const email = QueryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).email;
    return email
      ? `We've sent an email to ${email} with instruction to reset your password.`
      : `We've sent an email to your email address with instruction to reset your password.`;
  }

  render() {
    return (
      <ThemeProvider theme={fortellisTheme}>
        <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
        <PageHeaderWrapper className="listOfOrganization__Page-header" style={{"flex" : 0}}>
          <PageHeaderTitle>
            <PageHeaderLink
              className="listOfOrganization__Page-link-home"
              iconPosition="leading"
              text="FORTELLIS"
              href={homeURL}
            />
          </PageHeaderTitle>
        </PageHeaderWrapper>
        <div classname="parent-div" style={{flex: 1, padding:"0px", backgroundColor: "#ffffff"}}>
          <CardWrapper style={cardStyle}>
            <CardBody>
              <CardTitle
                className="listOfOrganization__text-head"
                style={{
                  alignSelf: "center",
                  marginRight: 8,
                  width: "440px"
                }}
              >
                {"Check Your Email"}
              </CardTitle>
              <CardSubtitle
                className="resetpwd-email_text"
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                {this.getDisplayText()}
              </CardSubtitle>
              <CardSubtitle
                className="listOfOrganization__text"
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                Didn't get the email?
                <a href={"/resetpwd"}> Resend it.</a>
              </CardSubtitle>
            </CardBody>
          </CardWrapper>
          <div className="resetpwd-image"></div>
        </div>
        <div className="listOfOrganization__footer">
          <AppFooter />
        </div>
        </div>
      </ThemeProvider>
    );
  }
}

ResetPasswordEmail.propTypes = {
  location: PropTypes.object.isRequired
};

export default ResetPasswordEmail;
