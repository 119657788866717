import { connect } from "react-redux";
import signIn from "./signin";
import { actions } from "./signinActions";
import { withRouter } from "react-router-dom";
import types from "./signinActionTypes";

const mapStateToProps = state => {
  return {
    signinStatus: state.signIn,
    disabled: state.signIn.disabled,
    error: state.signIn.error,
    tncStatus: state.signIn.tncStatus || {},
    entities: state.signIn.entities,
    changePassStatus: state.changePwd.status,
    user: state.invite.email,
    isUserExists: state.invite.userExists,
    orgName: state.invite.orgName,
    inviteStatus: state.invite.status,
    orgId: state.invite.orgId,
    token: state.invite.token,
    isLoading: state.signIn.isLoading,
    invitationId: state.signIn.invitationId,
    email: state.accountActivation.email,
    invitedUserName: state.signupDetails && state.signupDetails.email ? state.signupDetails.email : undefined,
    invitedUserPswd: state.signupDetails && state.signupDetails.password ? state.signupDetails.password : undefined,
    isInvitedUser: state.signupDetails && state.signupDetails.isInvitedUser ? state.signupDetails.isInvitedUser : false
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signin: (data, sessionRequired) => {
      dispatch(actions.signin(data, sessionRequired));
    },
    resendVerifyEmail: data => {
      dispatch(actions.resendVerifyEmail(data));
    },
    putTncVersion: (data, version) => {
      dispatch(actions.putTncVersion(data, version));
    },
    closeTncErrorDialog: () => {
      dispatch({
        type: types.CLOSE_TNC_ERROR_DIALOG
      });
    },
    sendAuthnVerification: (userId, data, isToRemeberDevice) => {
      dispatch(actions.sendAuthnVerification(userId, data, isToRemeberDevice));
    },
    resendAuthnVerification: (userId, data, isToRemeberDevice) => {
      dispatch(
        actions.resendAuthnVerification(userId, data, isToRemeberDevice)
      );
    },
    verifyAuthnPassCode: (userId, data, isToRemeberDevice) => {
      dispatch(actions.verifyAuthnPassCode(userId, data, isToRemeberDevice));
    },
    initiateRequest: () => {
      dispatch(actions.initiateRequest());
    },
    updateInvitation: token => {
      dispatch(actions.updateInvitation(token));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(signIn));
