import amplitude from "amplitude-js";

export const initAmplitude = () => {
  try {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
  } catch (error) {
    console.error({
      Error: error
    });
  }
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  try {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  } catch (error) {
    console.error({
      Error: error
    });
  }
};
