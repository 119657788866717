import React from "react";
import { CardTitle, CardSubtitle } from "@cdk-uip/react";
import PropTypes from "prop-types";

class ErrorToaster extends React.Component {
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <section className={"errorblock"}>
        <CardTitle className={"errorblock__title"}>
          {this.props.title}
        </CardTitle>
        <CardSubtitle className={"errorblock__subtitle"}>
          {this.props.subTitle}
        </CardSubtitle>
      </section>
    );
  }
}

ErrorToaster.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.node,
  show: PropTypes.bool
};

ErrorToaster.defaultProps = {
  title: "Something has gone wrong.",
  subTitle: "You might have to try that again.",
  show: false
};

export default ErrorToaster;
