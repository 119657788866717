import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  TextField
} from "@cdk-uip/react";
import "@cdk-uip/icons";
import PropTypes from "prop-types";

class MultiFactorAuthenticationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.onVerify = this.onVerify.bind(this);
  }

  handleChange(e) {
    this.setState({
      verificationCode: e.target.value
    });
  }

  onVerify() {
    const verifyCode = this.state.verificationCode;
    this.props.onAccept(verifyCode);
    this.setState({
      verificationCode: ""
    });
  }
  render() {
    const {
      open,
      onCancel,
      contactNumber,
      isDirtyForm,
      resendVerificationCode
    } = this.props;
    const { verificationCode } = this.state;
    return (
      <Dialog open={open}>
        <DialogHeader>
          <div className="dialog-header-container">
            {isDirtyForm
              ? "Security settings have not been saved."
              : "Check that it works"}
          </div>
        </DialogHeader>
        <DialogBody>
          {isDirtyForm ? (
            <div className="error-dialog-message">
              Two-step verification requires a mobile number. Complete the form
              to stay secure, or cancel and live dangerously.
            </div>
          ) : (
            <div>
              <div className="mfa-verify__dialog--subtitle">
                {`A verification code was sent to (***)***-${contactNumber &&
                  contactNumber.slice(
                    -4
                  )}. Please enter the code below to continue.`}
              </div>
              <div className="mfa-verify__dialog--content">
                <div className="mfa-verification__input">
                  <TextField
                    label={"Enter verification code"}
                    id="verifyCode"
                    name="verifyCode"
                    value={verificationCode}
                    onChange={e => {
                      this.handleChange(e);
                    }}
                  />
                </div>
                <p>Didn’t receive an verification code?</p>
                <span
                  className="anchor util__anchor--pointer"
                  onClick={resendVerificationCode}
                >
                  Request a new code
                </span>
              </div>
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button className="dialog-button" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className="dialog-button"
            disabled={!isDirtyForm && !verificationCode}
            onClick={this.onVerify}
          >
            {isDirtyForm ? "Keep Working" : "Verify"}
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

MultiFactorAuthenticationDialog.propTypes = {
  open: PropTypes.bool,
  isDirtyForm: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  resendVerificationCode: PropTypes.func,
  contactNumber: PropTypes.string
};

export default MultiFactorAuthenticationDialog;
