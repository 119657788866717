import types from "./signinActionTypes";

const initializedState = {
  status: types.INITIALIZED,
  disabled: "disabled",
  error: {},
  invitationId: "",
  message: "",
  isLoading: false
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.SIGNIN_POST_REQUEST:
      return initializedState;
    case types.SIGNIN_POST_RECEIVE:
      if (action.response.status === "MFA_REQUIRED") {
        return Object.assign({}, state, {
          status: types.MFA_REQUIRED,
          showMFASelection: true,
          showMFAVerification: false,
          factors: action.response._embedded.factors,
          stateToken: action.response.stateToken,
          user: action.response._embedded.user,
          error: {}
        });
      } else if (action.response.payload.tncVersion) {
        return {
          status: types.TNC_UPDATE,
          showMFASelection: false,
          showMFAVerification: false,
          tncVersion: action.response.payload.tncVersion,
          tncText: JSON.parse(action.response.payload.tncText),
          tncCreatedOn: action.response.payload.createdOn,
          error: {}
        };
      } else {
        return {
          status: types.SUCCESS,
          showMFASelection: false,
          showMFAVerification: false,
          sessionToken: action.response.payload.sessionToken,
          disabled: "disabled",
          error: {}
        };
      }
    case types.SIGNIN_POST_ERROR:
      let apierror = action.apierror.payload;
      let isTncError = apierror && apierror.isFailedToGetTnc;
      if (isTncError) {
        console.error({
          Message: "SignIn failed to get latest tnc",
          Context: "SIGN-IN"
        });
        return {
          error: {},
          status: types.FAILED,
          tncStatus: {
            error: "Failed to get latest tnc"
          }
        };
      } else {
        console.error({
          Message: "SignIn Failed",
          Context: "SIGN-IN"
        });
        return {
          error: apierror || {},
          status: types.FAILED
        };
      }

    case types.ENTITIES_GET_REQUEST:
      return Object.assign({}, state, {
        entities: {
          error: ""
        }
      });
    case types.ENTITIES_GET_RECEIVE:
      return Object.assign({}, state, {
        entities: {
          error: "",
          list: action.response.entitiesList || []
        }
      });
    case types.ENTITIES_GET_ERROR:
      console.error({
        Message: "Failed to load entities",
        error: action.apierror
      });
      return Object.assign({}, state, {
        entities: {
          error: "Failed+to+fetch+user+entities+during+sso",
          list: [] // Setting the list to empty when fetching entities fail to handle edge-case gracefully
        }
      });

    case types.ACCEPT_TNC_REQUEST:
      return Object.assign({}, state, {
        tncStatus: {
          error: false,
          requesting: true
        }
      });
    case types.ACCEPT_TNC_RECEIVE:
      return Object.assign({}, state, {
        tncStatus: {
          error: false,
          requesting: false,
          accepted: true
        }
      });
    case types.ACCEPT_TNC_ERROR:
      console.error({
        Message: "Failed to accept tnc",
        error: action.apierror
      });
      return Object.assign({}, state, {
        tncStatus: {
          requesting: false,
          error: "Failed to accept tnc"
        }
      });

    case types.CLOSE_TNC_ERROR_DIALOG:
      return Object.assign({}, state, {
        tncStatus: {
          error: false
        }
      });
    case types.MFA_AUTHN_VERIFICATION_CODE_REQUEST:
      return Object.assign({}, state, {
        verificationApiStatus: {
          status: types.INITIALIZED,
          disabled: true,
          error: {}
        }
      });
    case types.MFA_AUTHN_VERIFICATION_CODE_RECEIVE:
      return Object.assign({}, state, {
        status: types.MFA_REQUIRED,
        showMFASelection: false,
        showMFAVerification: true,
        stateToken: action.response.stateToken,
        selectedFactor: action.response._embedded.factor,
        error: {},
        verificationApiStatus: {
          status: types.SUCCESS,
          disabled: true,
          error: {}
        }
      });
    case types.MFA_AUTHN_VERIFICATION_CODE_ERROR:
      let vcErr = action.apierror.payload;
      console.error({
        Message: "Failed to send verification code",
        error: vcErr
      });
      return Object.assign({}, state, {
        error: {},
        verificationApiStatus: {
          status: types.FAILED,
          disabled: false,
          error: vcErr
        }
      });
    case types.MFA_AUTHN_VERIFY_REQUEST:
      return Object.assign({}, state, {
        verifyApiStatus: {
          status: types.INITIALIZED,
          disabled: true,
          error: {}
        }
      });
    case types.MFA_AUTHN_VERIFY_RECEIVE:
      if (
        !(action.response.payload && action.response.payload.rememberDevice)
      ) {
        localStorage.removeItem(`device-${state.user.profile.login}`);
      }
      return Object.assign({}, state, {
        status: types.SUCCESS,
        showMFASelection: false,
        showMFAVerification: false,
        disabled: "disabled",
        error: {},
        verifyApiStatus: {
          status: types.SUCCESS,
          disabled: true,
          error: {}
        }
      });
    case types.MFA_AUTHN_VERIFY_ERROR:
      let verifyErr = action.apierror.payload;
      console.error({
        Message: "Failed to verify the code",
        error: verifyErr
      });
      return Object.assign({}, state, {
        error: {},
        verifyApiStatus: {
          status: types.FAILED,
          disabled: false,
          error: verifyErr
        }
      });
    case types.MFA_AUTHN_VERIFY_RESEND_REQUEST:
      return Object.assign({}, state, {
        verifyApiStatus: {
          status: types.INITIALIZED,
          disabled: true,
          error: {}
        }
      });
    case types.MFA_AUTHN_VERIFY_RESEND_RECEIVE:
      return Object.assign({}, state, {
        status: types.MFA_REQUIRED,
        showMFASelection: false,
        showMFAVerification: true,
        stateToken: action.response.stateToken,
        selectedFactor: action.response._embedded.factor,
        error: {},
        verifyApiStatus: {
          status: types.SUCCESS,
          disabled: true,
          error: {}
        }
      });
    case types.MFA_AUTHN_VERIFY_RESEND_ERROR:
      let resendVerifyErr = action.apierror.payload;
      console.error({
        Message: "Failed to verify the code",
        error: resendVerifyErr
      });
      return Object.assign({}, state, {
        error: {},
        verifyApiStatus: {
          status: types.FAILED,
          disabled: false,
          error: resendVerifyErr
        }
      });
    case types.UPDATE_INVITATION_REQUEST:
      return {
        message: "",
        isLoading: true
      };
    case types.UPDATE_INVITATION_RESPONSE:
      return { ...state, ...action.response, message: "", isLoading: false };
    case types.UPDATE_INVITATION_ERROR:
      return { ...state, message: action.response.message, isLoading: false };
    default:
      return state;
  }
};
