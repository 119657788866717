import config from "../config/app.conf.json";

export default(data, userId, accessToken) => {
   let configUrl = config.api.changePassword.replace(":userId", userId);
    return {
      url: configUrl,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }
    }
};
