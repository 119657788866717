import React from "react";
import { Tabs, Tab, TabList, TabPanel } from "./fortellisTabs";
import types from "./settingsTypes";
import { Button, Snackbar } from "@cdk-uip/react";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import AuthHeader from "../Header/AuthHeader";
import AuthFooter from "../Footer/AuthFooter";
import { Headline } from "@cdk-uip/react-typography";
import PersonalInformation from "../PersonalInformation/personalInformation";
import ChangePassword from "./settingsChangePasswordContainer";
import SecuritySettings from "../SecuritySettings/securitySettingsContainer";
import {  withAuth } from "@cdk-prod/fortellis-auth-context";
import PropTypes from "prop-types";
import securityTypes from "../SecuritySettings/securitySettingsTypes";

const title = "Settings";
const FOOTER_HEIGHT = "-388px";
const ButtonTab = props => {
  let { text } = props;
  return <Button className="Button">{text}</Button>;
};

class SettingsContainer extends React.Component {
  render(){
    return this.props.auth.userData.sub
    ? <Settings {...this.props}/>
    :<></>
  }

}
export class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.updateUser = this.updateUser.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);
    this.personalInfoElement = React.createRef();
    this.state = {
      mobile: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    switch (nextProps.settings.type) {
      case types.USER_INFO_GET_RECEIVE:
        this.personalInfoElement.current.updatePersonalInfoState(
          nextProps.settings.userInfo
        );
        break;
      case types.USER_INFO_POST_RECEIVE:
        this.personalInfoElement.current.updatePersonalInfoState(
          nextProps.settings.userInfo
        );
        break;
      default:
        break;
    }
    return true;
  }

  updateUser(userId, data, accessToken) {
    this.props.updatePersonalInfo(userId, data, accessToken);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateMobile();
    window.addEventListener("resize", this.updateMobile);
    let { auth } = this.props;
    this.props.personalInfo(
      auth.userData.sub,
      auth.accessToken // this.state.userId
    );
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.updateMobile);
  }

  updateMobile = () => {
    if (!this.mounted) {
      return;
    }

    if (window.innerWidth <= 1039) {
      this.setState({
        mobile: true
      });
    } else {
      this.setState({
        mobile: false
      });
    }
  };

  hideSnackbar() {
    this.props.updateMessage();
  }

  render() {
    return (
      <React.Fragment>
        <AuthHeader mobile={this.state.mobile} />
        <div
          className="settingsLayout"
          ref={node => {
            this.node = node;
          }}
        >
          <div className="form-container">
            <h1 className="SettingTitle">{title}</h1>
            <Tabs selected={0}>
              <TabList>
                <Tab>
                  <ButtonTab text="Personal Information" />
                </Tab>
                <Tab>
                  <ButtonTab text="Password" />
                </Tab>
                <Tab>
                  <ButtonTab text="Security" />
                </Tab>
              </TabList>

              <TabPanel>
                <PersonalInformation
                  updateUser={this.updateUser}
                  ref={this.personalInfoElement}
                  auth={this.props.auth}
                  userInfo={this.props.settings.userInfo}
                  {...this.props}
                />
              </TabPanel>
              <TabPanel>
                <ChangePassword
                    userId={this.props.auth.userData.sub}
                    auth={this.props.auth}
                />
              </TabPanel>
              <TabPanel>
                <SecuritySettings
                  auth={this.props.auth}
                  containerRef={this.node}
                />
              </TabPanel>
            </Tabs>
          </div>
          {this.props.settings.requestInProgress ||
          this.props.settingsChangePassword.requestInProgress ||
          this.props.securitySettings.status === securityTypes.INITIALIZED ? (
            <div className="c-circular-progress--overlay-wrapper">
              <Headline>Please wait...</Headline>
              <CircularProgress className="c-circular-progress--overlay" />
            </div>
          ) : null}
        </div>
        <Snackbar
          className=""
          show={!!this.props.settings.message}
          timeout={8000}
          multiline={true}
          actionOnBottom={true}
          message={this.props.settings.message}
          onClose={this.hideSnackbar}
        />
        <div
          className="customFooter"
          style={{
            position: "absolute",
            bottom: `${FOOTER_HEIGHT}`,
            width: "100%"
          }}
        >
          <AuthFooter mobile={this.state.mobile} />
        </div>
      </React.Fragment>
    );
  }
}

Settings.propTypes = {
  settings: PropTypes.object,
  personalInfo: PropTypes.func,
  updatePersonalInfo: PropTypes.func,
  updateMessage: PropTypes.func,
  settingsChangePassword: PropTypes.object,
  securitySettings: PropTypes.object
};

ButtonTab.propTypes = {
  text: PropTypes.string.isRequired
};

ButtonTab.defaultProps = {
  text: "Submit"
};

export default withAuth(SettingsContainer);
