import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from "styled-components";
import { fortellisTheme } from "@cdk-rds/core";
import {
    PageHeaderTitle,
    PageHeaderWrapper,
    PageHeaderLink,
  } from "@cdk-rds/page-header";
  import {
    CardWrapper,
    CardBody,
    CardTitle,
    CardContent
  } from "@cdk-rds/card";
import '@cdk-uip/icons';
import { Toast, TOAST_VARIANTS } from "@cdk-rds/toast";
import ReCAPTCHA from 'react-google-recaptcha';
import TermsCheckbox from './termsCheckbox';
import config from '../config/app.conf.json';
import URLParser from '../shared/utilities/URLParser';
import UserInformation from '../UserInformation/userInformation.js';
import RegistrationFooter from './registrationFooter';
import AppFooter from '../shared/components/AppFooter/appFooter';
import ErrorMessageDialog from './errorMessageDialog';
import constants from '../constants/index.js';
import ErrorToaster from '../ErrorToaster/errorToaster';
import  { sendAmplitudeData } from '../utils/amplitude';
import { Card } from 'cdk-radial';
import './registration.scss'
const homeURL = config.appPlatformInfo.url; 

const cardStyle = {
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: "inherit",
  };

class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {

            errorToaster: {
                show: false,
                message: {}
            }
        };

        this.recaptchaRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.toggleErrorDialog = this.toggleErrorDialog.bind(this);
    }

    componentDidMount() {
        this.logAmplitudeSignupVisit('Signup Visit');
        this.props.getLatestTnc();
        const { signupDetails, saveSignupDetails, user, isUserExists} = this.props;
        if(user !== '' && !isUserExists) saveSignupDetails({...signupDetails, email: user});
    }
    componentDidUpdate() {
        const { errorMessage } = this.props.signupDetails;
        if (errorMessage === '' || errorMessage === {}) {
            return;
        }
        this.setState({
            errorToaster: {
                show: true,
                message: errorMessage
            }
        }, () => {
            this.props.saveSignupDetails({
                errorMessage: ''
            });
        });
    }

    async handleSubmit() {
        const { signupDetails, history, signup } = this.props;

        let isLwfRequest = this.isLwfRequest();

        if (!this.isFormValid()) {
            this.props.saveSignupDetails({
                // errorMessage: {title: 'Signup Error.',subtitle: 'Error has occurred during the signup!'},
                enableErrorAgaistField: true
            });
            return;
        }

        this.props.saveSignupDetails({ enableErrorAgaistField: false });
        const email = signupDetails.email;
        const firstname = signupDetails.firstName;
        const isInvitedUser = this.props.isUserExists===false && this.props.inviteStatus=== "pending"
        const result = await signup(signupDetails, isInvitedUser, isLwfRequest);
        this.resetCaptcha();
        if (result.apierror) {
            return;
        }
        
        if(isInvitedUser)
        {
            this.props.setInvitedUser(isInvitedUser);
            history.push({
                pathname:'/login',
            })
        }
        else{
            history.push({
                pathname: '/verify-email-address',
                search: `?email=${email}&firstname=${firstname}${isLwfRequest ? '&lwf=true' : ''}`
            });
        } 
        this.logAmplitudeSignupSubmission('Signup Done'); 
    }

    logAmplitudeSignupVisit = event => {
        const ampliData = {
            AppName: 'Fortellis Sign up Page',
        };
        sendAmplitudeData(event, ampliData);
    };

    logAmplitudeSignupSubmission = event => {
        const { signupDetails } = this.props;
        const ampliData = {
            FirstName: signupDetails.firstName,
            LastName: signupDetails.lastName,
            Email: signupDetails.email,
            Mobile: signupDetails.primaryPhone,
            CompanyName: signupDetails.organization
        };

        sendAmplitudeData(event, ampliData);
    };

    goToLogin() {
        this.props.history.push('/login');
    }

    isFormValid() {
        var { signupDetails: { isValid, termsAccepted, captchaResponseToken } } = this.props;
        if (this.isLwfRequest()) {
            termsAccepted = true;
        }
        if (!isValid) {
            return false;
        }
        return captchaResponseToken;
    }

    toggleErrorDialog() {
        this.setState({
            isErrorNotified: true
        });
    }

    isLwfRequest() {
        return URLParser.getQueryStringParamValue('lwf', this.props.location.search) === 'true';
    }

    resetCaptcha() {
        if (this.recaptchaRef.current) {
            this.recaptchaRef.current.reset();
        }
        this.props.saveSignupDetails({
            captchaResponseToken: null
        });
    }

    render() {
        const { signupDetails, saveSignupDetails } = this.props;
        const { isErrorNotified } = this.state;
        const isLwfRequest = this.isLwfRequest();
        let termsAndCaptchaError = false;
        let termsNotAccepted = false;
        let captchaResponseTokenError = false;
        if((this.props.signupDetails.enableErrorAgaistField && this.props.signupDetails.captchaResponseToken === "") || (this.props.signupDetails.enableErrorAgaistField && this.props.signupDetails.captchaResponseToken === null)) {
            captchaResponseTokenError = true;
        }
        if(this.props.signupDetails.enableErrorAgaistField &&
            !this.props.signupDetails.termsAccepted) {
                termsNotAccepted = false;
            if(captchaResponseTokenError) {
                termsAndCaptchaError = true;
            }
        }
        

        return (
          <ThemeProvider
            theme={fortellisTheme}
            style={{ backgroundColor: "white" }}
          >
            <PageHeaderWrapper className="listOfOrganization__Page-header">
              <PageHeaderTitle>
                <PageHeaderLink
                  className="listOfOrganization__Page-link-home"
                  iconPosition="leading"
                  text="FORTELLIS"
                  href={homeURL}
                />
              </PageHeaderTitle>
            </PageHeaderWrapper>
            <div className="sign_up_layout">
              <div className="sign-up-parent-div">
                <div className="sign-up-left-img">
                  <CardBody>
                    <div
                      style={{
                        width: "584px",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="signup__header__text">
                          Create a Fortellis Account
                        </span>
                        {this.props.isUserExists === false &&
                          this.props.inviteStatus === "pending" && (
                            <span className="sign-up-hearder-message-large">
                              {`Accept your invitation to join ${this.props.orgName} by
                          creating an account.`}
                            </span>
                          )}
                      </div>
                    </div>
                  </CardBody>
                  <CardContent
                    className="listOfOrganization__form-wrapper"
                    style={{ alignSelf: "center" }}
                  >
                    <div>
                      <div className="image-signup"></div>
                    </div>
                  </CardContent>
                </div>
                <div className="sign-up-form-gap"></div>
                <Card className="sign-up-card">
                  <div className="sign-up-form">
                    <div className="form-container">
                      <div className="create-account-header">
                        Create Fortellis Account
                      </div>
                      <UserInformation errorToaster={this.state.errorToaster} />
                      {!isLwfRequest && (
                        <div className="check-box-container">
                          <TermsCheckbox
                            signupDetails={signupDetails}
                            saveSignupDetails={saveSignupDetails}
                          />
                        </div>
                      )}
                    </div>
                    <div className="captcha-wrapper">
                      <ReCAPTCHA
                        data-testid="recaptcha"
                        ref={this.recaptchaRef}
                        sitekey={config.recaptchaSiteKey}
                        onChange={token =>
                          this.props.saveSignupDetails({
                            captchaResponseToken: token
                          })
                        }
                      />
                    </div>
                    {termsAndCaptchaError && (
                      <div
                        style={{
                          maxWidth: "420px",
                          padding: "10px",
                          marginLeft: "23px"
                        }}
                      >
                        <Toast
                          content="Please accept the Terms and Conditions and confirm the CAPTCHA."
                          variant={TOAST_VARIANTS.NEGATIVE}
                          id="toast-1"
                        />
                      </div>
                    )}
                    {!termsAndCaptchaError && termsNotAccepted && (
                      <div
                        style={{
                          maxWidth: "420px",
                          padding: "10px",
                          marginLeft: "23px"
                        }}
                      >
                        <Toast
                          content="Please accept the Terms and Conditions."
                          variant={TOAST_VARIANTS.NEGATIVE}
                          id="toast-1"
                        />
                      </div>
                    )}
                    {!termsAndCaptchaError && captchaResponseTokenError && (
                      <div
                        style={{
                          maxWidth: "420px",
                          padding: "10px",
                          marginLeft: "23px"
                        }}
                      >
                        <Toast
                          content="Please confirm the CAPTCHA."
                          variant={TOAST_VARIANTS.NEGATIVE}
                          id="toast-1"
                        />
                      </div>
                    )}
                    <RegistrationFooter
                      data-testid="registration"
                      goToLogin={this.goToLogin}
                      handleSubmit={this.handleSubmit}
                      disableButton={signupDetails.formIsSubmitting}
                      registrationButtonDisplayText={
                        signupDetails.registrationButtonDisplayText
                      }
                    />
                  </div>
                </Card>
              </div>

              <div className="listOfOrganization__footer">
                <AppFooter />
              </div>

              <ErrorMessageDialog
                data-testid="errorMsgDialog"
                open={!signupDetails.tncVersion && !isErrorNotified}
                onCancel={this.toggleErrorDialog}
                title={constants.errorTitle}
                message={constants.errorMessage}
              />
            </div>
          </ThemeProvider>
        );
    }
}

Registration.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object,
    signupDetails: PropTypes.object.isRequired,
    saveSignupDetails: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
    formIsSubmitting: PropTypes.bool.isRequired,
    getLatestTnc: PropTypes.func,
    isUserExists: PropTypes.bool,
    inviteStatus: PropTypes.string,
    orgName: PropTypes.string
};

Registration.defaultProps = {
    formIsSubmitting: false
};

export default Registration;
