import config from "../config/app.conf.json";

let sessionSuffix = "?token=true";
export default (data, sessionRequired) => ({
  url: sessionRequired
    ? `${config.api.signin}${sessionSuffix}`
    : config.api.signin,
  method: "POST",
  data: data,
  headers: {
    "Content-Type": "application/json"
  }
});
