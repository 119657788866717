import { connect } from "react-redux";
import Settings from "./settings";
import { actions } from "./settingsActions";
import { withRouter } from "react-router-dom";
import types from "./settingsTypes";

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    personalInfo: (userId, accessToken) => {
      dispatch(actions.personalInfo(userId, accessToken));
    },
    updatePersonalInfo: (userId, data, accessToken) => {
      dispatch(actions.updatePersonalInfo(userId, data, accessToken));
    },
    updateMessage: (message = "") => {
      dispatch({
        type: types.UPDATE_SETTINGS_MESSAGE,
        message
      });
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
