export default {
  SIGNIN_POST_RECEIVE: "SIGNIN_POST_RECEIVE",
  SIGNIN_POST_ERROR: "SIGNIN_POST_ERROR",
  SIGNIN_POST_REQUEST: "SIGNIN_POST_REQUEST",
  RESEND_POST_RECEIVE: "RESEND_POST_RECEIVE",
  RESEND_POST_ERROR: "RESEND_POST_ERROR",
  RESEND_POST_REQUEST: "RESEND_POST_REQUEST",
  INITIALIZED: "INITIALIZED",
  UN_INITALIZED: "UN_INITALIZED",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  TNC_UPDATE: "TNC_UPDATE",
  ENTITIES_GET_REQUEST: "ENTITIES_GET_REQUEST",
  ENTITIES_GET_RECEIVE: "ENTITIES_GET_RECEIVE",
  ENTITIES_GET_ERROR: "ENTITIES_GET_ERROR",
  ACCEPT_TNC_REQUEST: "ACCEPT_TNC_REQUEST",
  ACCEPT_TNC_RECEIVE: "ACCEPT_TNC_RECEIVE",
  ACCEPT_TNC_ERROR: "ACCEPT_TNC_ERROR",
  CLOSE_TNC_ERROR_DIALOG: "CLOSE_TNC_ERROR_DIALOG",
  MFA_REQUIRED: "MFA_REQUIRED",
  MFA_AUTHN_VERIFICATION_CODE_REQUEST: "MFA_AUTHN_VERIFICATION_REQUEST",
  MFA_AUTHN_VERIFICATION_CODE_RECEIVE: "MFA_AUTHN_VERIFICATION_RECEIVE",
  MFA_AUTHN_VERIFICATION_CODE_ERROR: "MFA_AUTHN_VERIFICATION_ERROR",
  MFA_AUTHN_VERIFY_REQUEST: "MFA_AUTHN_VERIFY_REQUEST",
  MFA_AUTHN_VERIFY_RECEIVE: "MFA_AUTHN_VERIFY_RECEIVE",
  MFA_AUTHN_VERIFY_ERROR: "MFA_AUTHN_VERIFY_ERROR",
  MFA_AUTHN_VERIFY_RESEND_REQUEST: "MFA_AUTHN_VERIFY_RESEND_REQUEST",
  MFA_AUTHN_VERIFY_RESEND_RECEIVE: "MFA_AUTHN_VERIFY_RESEND_RECEIVE",
  MFA_AUTHN_VERIFY_RESEND_ERROR: "MFA_AUTHN_VERIFY_RESEND_ERROR",
  UPDATE_INVITATION_REQUEST: "UPDATE_INVITATION_REQUEST",
  UPDATE_INVITATION_RESPONSE: "UPDATE_INVITATION_RESPONSE",
  UPDATE_INVITATION_ERROR: "UPDATE_INVITATION_ERROR"
};
