import React, { Component } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@cdk-uip/react-dialog";
import { Button } from "@cdk-uip/react-button";
import "@cdk-uip/icons";
import PropTypes from "prop-types";

class ErrorMessageDialog extends Component {
  render() {
    const { open, onCancel, title, message } = this.props;
    return (
      <Dialog open={open} className="error-dialog">
        <DialogHeader>
          <div className="dialog-header-container">{title}</div>
        </DialogHeader>
        <DialogBody>
          <div className="error-dialog-message">{message}</div>
        </DialogBody>
        <DialogFooter>
          <Button className="dialog-button" onClick={onCancel}>
            OK
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

ErrorMessageDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
};

export default ErrorMessageDialog;
