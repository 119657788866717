import React from 'react';
import {
    CircularProgress,
    Fluid,
    FluidItem
} from '@cdk-uip/react';
import { ThemeProvider } from "styled-components";
import { fortellisTheme } from "@cdk-rds/core";
import {
    PageHeaderTitle,
    PageHeaderWrapper,
    PageHeaderLink,
} from "@cdk-rds/page-header";
import {
    CardBody,
    CardContent,
    CardWrapper,
    CardSubtitle,
    CardTitle
} from "@cdk-rds/card";
import { PrimaryButton } from "@cdk-rds/button";
import { Radio, RadioGroup, RADIO_LABEL_POSITIONS, RADIO_SIZES } from '@cdk-rds/radio';
import ShowHide from '../shared/components/ShowHide/ShowHide';
import {
    Toast,
    TOAST_VARIANTS
} from "@cdk-rds/toast";
import types from '../Signin/signinActionTypes';
import PropTypes from 'prop-types';
import AppFooter from '../shared/components/AppFooter/appFooter';
import config from '../config/app.conf.json';

const homeURL = config.appPlatformInfo.url;
const parentDivStyles = {
    height: window.innerHeight - 84,
};
const cardStyle = { boxShadow: "none", borderRadius: 0, backgroundColor: "inherit" };
class MultiFactorSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            factor: '',
            factorType: '',
            factorId: '',
            factors: this.getMaskedFactors(this.props.factorsForSelection),
            sendVerificationClicked: false
        };
        this.onFactorSelect = this.onFactorSelect.bind(this);
        this.getMaskedFactors = this.getMaskedFactors.bind(this);
        this.sendAuthCode = this.sendAuthCode.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);

    }

    getMaskedFactors(factorsToMask) {
        const maskedArr = [];
        const emailFactor = {};
        if (factorsToMask !== undefined) {
            factorsToMask.forEach(factor => {
                if (factor.factorType === 'email') {
                    emailFactor.id = factor.id;
                    emailFactor.factorType = factor.factorType;
                    emailFactor.factorValue = factor.profile.email ? factor.profile.email.replace('...', '***') : '';
                } else if (factor.factorType === 'sms') {
                    const maskedPhoneNo = factor.profile.phoneNumber ? `(***)***-${factor.profile.phoneNumber.slice(-4)}` : '';
                    const factorObj = {
                        id: factor.id,
                        factorType: factor.factorType,
                        factorValue: maskedPhoneNo
                    };
                    maskedArr.push(factorObj);
                }
            });
            maskedArr.push(emailFactor);
        }
        return maskedArr;
    }

    onFactorSelect(e) {
        var value = this.state.factors.filter(function (item) {
            return item.factorValue === e.target.value;
        });
        this.setState({
            disabled: false,
            factor: e.target.value,
            factorId: value[0].id,
            factorType: value[0].factorType
        });
        console.log(value);
    }

    sendAuthCode() {
        if(!this.state.disabled) {
            this.setState({
                disabled: true,
                sendVerificationClicked: false
            });
            this.props.getVerificationCode(this.state.factorId);
        } else {
            this.setState({
                sendVerificationClicked: true
            });
        }
        
    }

    getErrorMessage() {
        const isVerifyError = this.props.verifyApiStatus && this.props.verifyApiStatus.error.errors;
        if (isVerifyError) {
            let error = this.props.verifyApiStatus.error;
            return error.errors.constructor === Object ? error.errors.errorSummary : error.errors.constructor === Array ? error.errors[0].message : 'Something went wrong. Please try again.';;
        } else {
            return 'Something went wrong. Please try again.'
        }
    };

    render() {
        const { factors, factor } = this.state;
        const progressIndicator = this.props.verificationApiStatus && this.props.verificationApiStatus.status === types.INITIALIZED;
        const status = this.props.verifyApiStatus ? this.props.verifyApiStatus.status : null;
        const errorMsg = this.getErrorMessage();

        return (
            <ThemeProvider theme={fortellisTheme}>
                <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
                <PageHeaderWrapper className="listOfOrganization__Page-header" style={{"flex": 0}}>
                    <PageHeaderTitle>
                        <PageHeaderLink
                            className="listOfOrganization__Page-link-home"
                            iconPosition="leading"
                            text="FORTELLIS"
                            href={homeURL}
                        />
                    </PageHeaderTitle>
                </PageHeaderWrapper>
                <div className="parent-div"style={{flex: 1, padding:"0px"}}>
                    <div style={{ marginLeft: "15%", paddingTop: "5%" }}>
                        <CardWrapper style={cardStyle}>
                            <CardBody>
                                <CardTitle className="listOfOrganization__text-head" style={{
                                    alignSelf: "center",
                                    marginRight: 8,
                                    width: "634px",
                                }}>
                                    {'Request Verification Code'}
                                </CardTitle>
                                <CardSubtitle style={{ width: "389px", whiteSpace: "initial", marginLeft: 10 }}>
                                    For your security, we need to verify your identity. We can send you a verification code via text or email. Please select an option below.
                      </CardSubtitle>
                            </CardBody>
                            <CardContent className="listOfOrganization__form-wrapper" >
                                <ShowHide show={progressIndicator}>
                                    <Fluid
                                        className="circularprogress__outer-wrapper"
                                        halign="center"
                                        valign="middle">
                                        <FluidItem className="circularprogress__direct-wrapper">
                                            <CircularProgress className="circularprogress__component" />
                                        </FluidItem>
                                    </Fluid>
                                </ShowHide>
                                {((status === types.FAILED) || (this.state.sendVerificationClicked && this.state.disabled)) ? <div>
                                    <Toast
                                        id="Error-title"
                                        content={'Two-step verification error'}
                                        variant={TOAST_VARIANTS.NEGATIVE}
                                    />
                                </div> : <div></div>}
                                <div className='ssocard-text-field-wrapper' style={{ width: "389px", display: "flex", marginTop: "14px", marginRight: "20px" }}>
                                    <RadioGroup
                                        name="factors"
                                        onChange={this.onFactorSelect.bind(this)}
                                        value={factor}
                                        options=
                                        {factors.map((factor, key) => ({
                                            id: key,
                                            label: factor.factorType === 'email' ? 'Email: ' + `${factor.factorValue}` : 'Text: ' + `${factor.factorValue}`,
                                            value: factor.factorValue

                                        }))
                                        }
                                    >
                                    </RadioGroup>

                                </div>

                                <div className="listOfOrganization__button-wrapper--primary" style={{ width: "389px" }}>
                                    <PrimaryButton
                                        data-testid="sendVerification"
                                        text="Send Verification"
                                        type="submit"
                                        onClick={this.sendAuthCode}
                                        className="resetpwdcard__button--primary"
                                    >
                                    </PrimaryButton>
                                </div>

                            </CardContent>
                        </CardWrapper>
                    </div>
                    <div className="mfa-image-wrapper">
                        <div
                            className="image-mfa-selection"
                        ></div>
                    </div>
                </div>
                <div className="listOfOrganization__footer">
                    <AppFooter />
                </div>
                </div>
            </ThemeProvider>
        );
    }
}

MultiFactorSelection.propTypes = {
    status: PropTypes.string,
    isAuthCodeSent: PropTypes.bool,
    getVerificationCode: PropTypes.func.isRequired,
    factorsForSelection: PropTypes.array.isRequired
};

export default MultiFactorSelection;
