import React from "react";
import { withRouter } from "react-router-dom";
import { CardHeader } from "@cdk-uip/react";
import PropTypes from "prop-types";
import types from "./accountActivationTypes";
import URLParser from "../shared/utilities/URLParser";
import config from "../config/app.conf.json";

const brandName = config.appPlatformInfo.brandName;

let messageFactory = (status, variables) => {
  const activationMessages = {
    SUCCESS: {
      title: <React.Fragment>{brandName} Account</React.Fragment>,
      subtitle: (
        <React.Fragment>
          <span className={"mdc-card__subtitle--custom-emphasized"}>
            {variables ? variables.firstName : status}, Your Email Has Been
            Confirmed!
          </span>
          <br />
          Congratulations! Your account has been successfully verified.
        </React.Fragment>
      )
    },
    FAILED: {
      title: "Account Activation Failed",
      subtitle:
        "We are unable to activate your account, please try resetting your password again."
    },
    INITIALIZED: {
      title: "Activating Your Account...",
      subtitle: "",
      progressIndicator: true
    }
  };

  if (status === types.SUCCESS) {
    return activationMessages.SUCCESS;
  }
  if (status === types.FAILED) {
    return activationMessages.FAILED;
  }
  if (status === types.INITIALIZED) {
    return activationMessages.INITIALIZED;
  }
};

class VerifyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidPath: false
    };
  }

  login() {
    this.props.history.push({
      pathname: "/login"
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    const status = nextProps.accountActivation.status;
    if (
      status === types.SUCCESS ||
      status === types.FAILED ||
      status === types.INITIALIZED
    ) {
      this.props.updateActivationStatus(status);
      this.props.updateActivationMessage(
        messageFactory(status, nextProps.accountActivation)
      );
    }
    if (status === types.SUCCESS) {
      const email = nextProps.accountActivation.email;
      this.props.updateActivationUsername(email);
    }
  }

  componentDidMount() {
    const queryStringParams = window.location.search;
    const uid = URLParser.getQueryStringParamValue("uid", queryStringParams);
    const verifier = URLParser.getQueryStringParamValue(
      "verifier",
      queryStringParams
    );
    const autoapprove = URLParser.getQueryStringParamValue(
      "autoapprove",
      queryStringParams
    );

    if (uid) {
      this.props.activateAccount(uid, verifier, autoapprove);
    } else {
      this.setState({
        invalidPath: true
      });
    }
  }

  render() {
    return <React.Fragment></React.Fragment>
      {
        /*<CardHeader className="util__text--center">
         {this.state.invalidPath && (
-          <InvalidActivationURL
-            loginHandler={this.login}
-            loginRedirectMsg={loginRedirectMessage}
-          />
-        )}
-        {this.props.accountActivation.status === types.SUCCESS && (
-          <ActivationSuccessful
-            loginHandler={this.login}
-            loginRedirectMsg={loginRedirectMessage}
-          />
-        )}
-        {this.props.accountActivation.status === types.FAILED && (
-          <ActivationFailed
-            loginHandler={this.login}
-            loginRedirectMsg={loginRedirectMessage}
-          />
-        )}
-        {this.props.accountActivation.status === types.INITIALIZED && (
-          <ActivationInProgress
-            loginHandler={this.login}
-            loginRedirectMsg={loginRedirectMessage}
-          />
-        )} 
      </CardHeader>*/
    }
    ;
  }
}

VerifyPage.defaultProps = {
  accountActivation: {
    status: types.UN_INITIALIZED
  }
};

VerifyPage.propTypes = {
  accountActivation: PropTypes.object.isRequired,
  uidValue: PropTypes.string,
  history: PropTypes.object,
  updateActivationStatus: PropTypes.func,
  updateActivationMessage: PropTypes.func,
  updateActivationUsername: PropTypes.func,
  activateAccount: PropTypes.func
};

export default withRouter(VerifyPage);
