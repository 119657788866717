import Cookies from "universal-cookie";

import config from "../config/app.conf.json";

export default (question, answer, password) => {
  const cookie = new Cookies();
  const userId = cookie.get("userId");

  return {
    url: config.api.updateSecurityQuestion.replace(":userId", userId),
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      recovery_question: {
        question,
        answer
      },
      password: {
        value: password
      }
    }
  };
};
