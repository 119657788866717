import React from "react";
import Confirmation from "../Confirmation/confirmation";

class SuspendedAccount extends React.Component {
  render() {
    return (
      <Confirmation
        title="Your Account Has Been Suspended."
        description={
          "Your account has been suspended. If you have any questions," +
          " please contact customer support."
        }
      />
    );
  }
}

export default SuspendedAccount;
