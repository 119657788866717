import React, { Component } from 'react';
import AccountActivation from '../AccountActivation/accountActivationContainer';
import types from './signinActionTypes';
import ErrorToaster from '../ErrorToaster/errorToaster';
import authz from '../api/authorize';
import SignInForm from './signinForm';
import config from '../config/app.conf.json';
import URLParser from '../shared/utilities/URLParser';
import accountActivationTypes from '../AccountActivation/accountActivationTypes';
import ChangePassword from '../ChangePassword/changePasswordContainer';
import SignInTncDialog from './signInTncDialog';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import ErrorMessageDialog from '../Registration/errorMessageDialog';
import constants from '../constants/index.js';
import MultiFactorSelection from '../MultifactorAuthentication/multiFactorSelection';
import MultiFactorVerification from '../MultifactorAuthentication/multiFactorVerification';
import uuid from 'uuid/v4';
import { sendAmplitudeData } from '../utils/amplitude';
const developerAccountUrl =
  config.clientSubDomainApps.developerNetworkApp.developerAccount;

const AUTHORIZE_REDIRECT_QUERY_PARAM = 'key';

const cookies = new Cookies();

const brandName = config.appPlatformInfo.brandName;

const defaultRedirectURL =
    config.clientSubDomainApps.developerNetworkApp.url || window.location.host;

const defaultOrgManagementUrl =
    config.nav.user.menu[0].url || window.location.host;
class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMFASelection: false,
            showMFAVerification: false,
            showTnc: false,
            isErrorNotified: false,
            showTncRequiredMessage: false,
            username: '',
            password: '',
            activationStatus: '',
            disabled: '',
            activationMessages: {
                ssoCardTitle: '',
                ssoCardSubtitle: ''
            },
            isInvitedAutologinTriggered: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.updateActivationStatus = this.updateActivationStatus.bind(this);
        this.updateActivationUsername = this.updateActivationUsername.bind(this);
        this.updateActivationMessage = this.updateActivationMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goToSignup = this.goToSignup.bind(this);
        this.goToResetPwd = this.goToResetPwd.bind(this);
        this.goToResendVerifyEmail = this.goToResendVerifyEmail.bind(this);
        this.initiateOAuthRedirect = this.initiateOAuthRedirect.bind(this);
        this.initiateSucessfulLoginRedirect = this.initiateSucessfulLoginRedirect.bind(this);
        this.closeTncDialog = this.closeTncDialog.bind(this);
        this.closeErrorDialog = this.closeErrorDialog.bind(this);
        this.getVerificationCode =this.getVerificationCode.bind(this);
        this.resendAuthnVerification = this.resendAuthnVerification.bind(this);
        this.verifyAuthnPassCode = this.verifyAuthnPassCode.bind(this);
        this.getKeyForDeviceToken = this.getKeyForDeviceToken.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }
    closeErrorDialog() {
        this.setState({
            showTncRequiredMessage: false,
            showTnc: true,
            isErrorNotified: true
        });
    }

    closeTncDialog() {
        this.setState({
            showTnc: false,
            showTncRequiredMessage: true
        });
    }

    updateActivationStatus(status) {
        this.setState({
            activationStatus: status
        });
    }

    updateActivationUsername(email) {
        this.setState({
            username: email
        });
    }

    updateActivationMessage({ title, subtitle }) {
        this.setState({
            activationMessages: {
                ssoCardTitle: title,
                ssoCardSubtitle: subtitle
            }
        });
    }

    getKeyForDeviceToken(username) {
        return `device-${username}`;
    }

    componentDidMount() {
      let verifier = URLParser.getQueryStringParamValue('verifier', this.props.location.search);
      let shouldAutoApprove = URLParser.getQueryStringParamValue('autoapprove', this.props.location.search);
      let initiatedFrom = URLParser.getQueryStringParamValue(
        "initiated_from",
        this.props.location.search
      );
      if(initiatedFrom !== "identity" && !verifier && !shouldAutoApprove){
        window.location = developerAccountUrl
      }
      if(this.props.isInvitedUser && this.props.invitedUserName && this.props.invitedUserPswd && !this.state.isInvitedAutologinTriggered) {
          this.setState({isInvitedAutologinTriggered: true, username: this.props.invitedUserName, password: this.props.invitedUserPswd }, ()=> {
              this.handleSubmit({ username : this.props.invitedUserName, password : this.props.invitedUserPswd });
          });
      }  
    }

    handleSubmit({ username = '', password = '' }) {
        let sessionRequired = !URLParser.getQueryStringParamValue(
            'client_id',
            this.props.location.search
        );
        
        let deviceToken = localStorage.getItem(this.getKeyForDeviceToken(username || this.state.username||this.props.user));
        if(!deviceToken){
            deviceToken = uuid().replace(/-/g, '');
            localStorage.setItem(this.getKeyForDeviceToken(username || this.state.username), deviceToken);
        }
        this.props.signin(
            {  
                username: username || this.state.username || this.props.user,
                password: password || this.state.password,
                context: {deviceToken}
            },
            sessionRequired
        );
    }

    goToSignup() {
        this.props.history.push({
            pathname: '/signup',
            search: this.props.location.search
        });
    }

    goToResetPwd() {
        this.props.history.push({
            pathname: '/resetpwd',
            search: this.props.location.search
        });
    }

    goToResendVerifyEmail() {
        this.props.resendVerifyEmail({
            username: this.state.username
        });
        this.props.history.push({
            pathname: '/verify-email-address',
            search: `?email=${this.state.username}`
        });
    }

        initiateOAuthRedirect(sessionToken) {
        let redirectURL = URLParser.getQueryStringParamValue(
            'client_id',
            this.props.location.search
        );
        if(this.props.inviteStatus === "pending"){     
            this.props.updateInvitation(this.props.token)
            // Timeout for 5 seconds after updating invitation, to allow entitlements to be updated
            setTimeout(() => {}, 5000);
            return
        }
        redirectURL
        ? (window.location = authz.getAuthorizeRedirectURLWithSessionToken(sessionToken))
        : (window.location = defaultRedirectURL);
    }

    componentWillReceiveProps(nextProps){
        if(this.props.invitationId !== nextProps.invitationId && nextProps.invitationId && !nextProps.isLoading)
            {window.location = defaultOrgManagementUrl;}
    }
    
    initiateSucessfulLoginRedirect() {
        let redirectURL = URLParser.getQueryStringParamValue(
            'referrer',
            this.props.location.search
        );
        window.location = redirectURL || defaultRedirectURL;
    }

    authorizedOauthRedirect() {
        // authzRedirectKey is the key returned by okta on login redirect which maintains the oauth redirect details - client_id, redirect_uri, response_mode, response_type, scopes, nonce, state
        this.props.initiateRequest();
        const authzRedirectKey = URLParser.getQueryStringParamValue(AUTHORIZE_REDIRECT_QUERY_PARAM, this.props.location.search);
        window.location = authz.getAuthorizeRedirectURLWithKey(authzRedirectKey);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.signinStatus.status === types.TNC_UPDATE) {
            if (prevState.username !== this.state.username || prevState.password !== this.state.password) {
                return true;
            }
            if (!prevState.showTnc && this.props.signinStatus.tncVersion) {
                this.setState({
                    showTnc: true
                });
            }
            if (prevState.showTnc && this.props.tncStatus.accepted) {
                //this.handleSubmit({});
            }
        }

        if (this.props.signinStatus.status === types.SUCCESS) {
            cookies.set('username', this.state.username);
            let isAuthorizeOauthRedirect = !!URLParser.getQueryStringParamValue(AUTHORIZE_REDIRECT_QUERY_PARAM, this.props.location.search);
            if (this.state.activationStatus === accountActivationTypes.SUCCESS) {
                this.initiateSucessfulLoginRedirect(this.props.location.search);
            } else {
                if (isAuthorizeOauthRedirect) {
                    this.authorizedOauthRedirect();
                } else {
                    this.initiateOAuthRedirect(
                        this.props.signinStatus.sessionToken,
                        this.props.location.search
                    );
                }
            }
        }

        if (
            prevState.activationStatus === accountActivationTypes.INITIALIZED &&
            this.state.activationStatus === accountActivationTypes.SUCCESS
        ) {
            let shouldAutoApprove = URLParser.getQueryStringParamValue('autoapprove', this.props.location.search);
            if(shouldAutoApprove) {
                this.logAmplitudeEmailVerification(this.state);
                this.setState({
                    activationMessages: {
                        ssoCardTitle: '',
                        ssoCardSubtitle: "Your email is verified. Sign in to continue to your new account."
                    },
                    username: this.props.email
                });
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldAutoApprove = URLParser.getQueryStringParamValue('autoapprove', this.props.location.search);
        if (
            this.state.activationStatus === accountActivationTypes.INITIALIZED &&
            nextState.activationStatus === accountActivationTypes.SUCCESS
        ) {
            if(!shouldAutoApprove) {
                this.logAmplitudeEmailVerification(nextState);
                this.props.history.push({
                    pathname: `/email-verification-success`,
                    search: shouldAutoApprove ? '?autoapprove=true' : ''
                });
                return false;
            }
        }

        return true;
    }

    logAmplitudeEmailVerification(state) {
        const ampliData = {
            AppName: 'Fortellis Sign up Page',
            UserName: state && state.username,
            ActivationStatus: state && state.activationStatus
        };

        sendAmplitudeData('Email Verify', ampliData);
    }

    getVerificationCode(factorId) {
        let reqBody = { stateToken: this.props.signinStatus.stateToken }
        this.props.sendAuthnVerification(factorId, reqBody);
    }   

    resendAuthnVerification(factorId, isToRememberDevice) {
        let reqBody = { stateToken: this.props.signinStatus.stateToken }
        this.props.resendAuthnVerification(factorId, reqBody, isToRememberDevice);
    }  
  
    verifyAuthnPassCode(factorId, passCode, isToRememberDevice) {
        let sessionRequired = !URLParser.getQueryStringParamValue(
            'client_id',
            this.props.location.search
        );
        let reqBody = { stateToken: this.props.signinStatus.stateToken, passCode: passCode, token: sessionRequired, email: this.state.username };
        this.props.verifyAuthnPassCode(factorId, reqBody, isToRememberDevice);
    }

    render() {
        if (this.props.error.userStatus === 'VERIFIED') {
            this.props.history.push({
                pathname: '/awaiting-approval'
            });
        }
        if (this.props.error.userStatus === 'SUSPENDED') {
            this.props.history.push({
                pathname: '/suspended'
            });
        }

        const searchParams = window.location.search || '';
        const uidValue = URLParser.getQueryStringParamValue('uid', searchParams);
        let shouldAutoApprove = URLParser.getQueryStringParamValue('autoapprove', this.props.location.search);
        const isAccountActivation = !!uidValue;  
        
        const signinStatus = this.props.signinStatus.status;
        let userStatus = '';
        if (this.props.error.payload) {
            userStatus = this.props.error.payload.userStatus;
        }
        const isPasswordExpired = userStatus === 'PASSWORD_EXPIRED';
        const isPasswordChanged = this.props.changePassStatus === types.SUCCESS;
        const { signinStatus: { tncVersion, tncText, tncCreatedOn }, tncStatus } = this.props;
        const isSigninDisabled = this.props.disabled ==='disabled' || this.props.isInvitedUser ? 'disabled' : (this.props.isLoading=== true ? 'disabled' : '' )
        const getMessage = () => {
            if (isAccountActivation) {
                return this.state.activationMessages;
            } else if (!isAccountActivation) {
                if(this.props.isUserExists===true && this.props.inviteStatus==="pending"){
                    return {
                        ssoCardTitle: <React.Fragment>Welcome to {brandName}</React.Fragment>,
                        ssoCardSubtitle: <React.Fragment>Accept your invitation to join {this.props.orgName} by signing in.</React.Fragment>
                    };
                }
                else{return {
                    ssoCardTitle: <React.Fragment>Welcome to {brandName}</React.Fragment>
                };
            }
            }
           
        };
        const Errortitle=
            this.props.error.userStatus === 'STAGED'? 'Email Has Not Been Verified':
            (this.props.error.payload !== undefined && this.props.error.payload.userStatus === 'LOCKED_OUT') || (this.props.error.payload !== undefined && this.props.error.payload.totalAttempts >=10) ?'Account has been locked':(this.props.error.payload !== undefined && this.props.error.payload.totalAttempts > 3 ? 'You have only '+(10-this.props.error.payload.totalAttempts)+' attempts left': 'Incorrect Username/Password');
        
        if(this.props.signinStatus.showMFASelection) {
            return (<MultiFactorSelection 
                        factorsForSelection={this.props.signinStatus.factors}
                        getVerificationCode={this.getVerificationCode}
                        verificationApiStatus ={this.props.signinStatus.verificationApiStatus}
                        />);
        } else if(this.props.signinStatus.showMFAVerification) {
            return (<MultiFactorVerification 
                        verifyAuthnPassCode ={this.verifyAuthnPassCode}
                        resendAuthnVerification={this.resendAuthnVerification}
                        verifyApiStatus={this.props.signinStatus.verifyApiStatus}
                        selectedFactor={this.props.signinStatus.selectedFactor}
                        />);
        } else {
        return (
            isPasswordExpired ? <ChangePassword userId={this.state.username} tempPassword={this.state.password} onPasswordChange={this.handleSubmit} />
                : <div
                    {...getMessage()}
                    progressIndicator={
                        signinStatus === types.INITIALIZED ||
                        signinStatus === types.SUCCESS ||
                        this.state.activationStatus === types.INITIALIZED || this.props.isLoading ===true
                    }
                >
                    
                                <React.Fragment>
                                    {!isPasswordChanged
                                        ?
                                        <React.Fragment>
                                        {isAccountActivation && <AccountActivation
                                updateActivationUsername={this.updateActivationUsername}
                                updateActivationStatus={this.updateActivationStatus}
                                updateActivationMessage={this.updateActivationMessage}
                            />}
                                        <SignInForm
                                            {...this.props}
                                            isAccountActivation={isAccountActivation}
                                            shouldAutoApprove={shouldAutoApprove}
                                            showcaptcha={this.props.error.payload !== undefined && this.props.error.payload.showCaptcha?true:false}
                                            username={this.props.user? this.props.user : this.state.username}
                                            passwordInput={this.state.password}
                                            handleSubmit={this.handleSubmit}
                                            handleChange={this.handleChange}
                                            handlePasswordChange={this.handlePasswordChange}
                                            goToSignup={this.goToSignup}
                                            goToResetPwd={this.goToResetPwd}
                                            disabled={isSigninDisabled}
                                            errorToast={{show:signinStatus === types.FAILED, title:Errortitle}}
                                            isUserExists={this.props.isUserExists}
                                            inviteStatus={this.props.inviteStatus}
                                            recaptchaSiteKey={config.recaptchaSiteKey}
                                            isInvitedUser={this.props.isInvitedUser}
                                            orgName={this.props.orgName}
                                        /></React.Fragment> 
                                         : <div align={'center'}><br /><br />Password changed successfully. Signing you in...</div> /** HACK - Inline styling  */}

                                    <SignInTncDialog
                                        tncVersion={tncVersion}
                                        tncText={tncText}
                                        tncStatus={tncStatus}
                                        updatedOn={tncCreatedOn}
                                        open={this.state.showTnc && tncVersion}
                                        onAccept={() => {
                                            this.props.putTncVersion(
                                                {
                                                    username: this.state.username,
                                                    password: this.state.password
                                                },
                                                tncVersion
                                            );
                                        }}
                                        onReject={this.closeTncDialog}
                                    />

                                    <ErrorMessageDialog
                                        open={this.state.showTncRequiredMessage && !this.state.isErrorNotified}
                                        onCancel={this.closeErrorDialog}
                                        title={'Terms and Conditions Required'}
                                        message={'You will not be able to login without agreeing to the new terms and conditions.'}
                                    />

                                    <ErrorMessageDialog
                                        open={tncStatus.error}
                                        onCancel={this.props.closeTncErrorDialog}
                                        title={constants.errorTitle}
                                        message={constants.errorMessage}
                                    />

                                </React.Fragment>
                            
                </div>
        );
     }
    }
}

SignIn.propTypes = {
    location: PropTypes.object.isRequired,
    passwordInput: PropTypes.string,
    signin: PropTypes.func.isRequired,
    history: PropTypes.object,
    resendVerifyEmail: PropTypes.func.isRequired,
    signinStatus: PropTypes.object,
    error: PropTypes.object,
    disabled: PropTypes.bool,
    entities: PropTypes.object,
    changePassStatus: PropTypes.string,
    tncStatus: PropTypes.object,
    putTncVersion: PropTypes.func,
    closeTncErrorDialog: PropTypes.func,
    sendAuthnVerification: PropTypes.func,
    verifyAuthnPassCode: PropTypes.func,
    email: PropTypes.string
};

SignIn.defaultProps = {
    signinStatus: {
        status: types.UN_INITALIZED
    },
    error: {},
    activationStatus: types.UN_INITALIZED,
    activationMessages: {
        title: {
            brandName
        } + 'Account',
        subtitle: ''
    },
    changePassStatus: types.UN_INITALIZED
};

export default SignIn;