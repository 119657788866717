import React from 'react';
import {
    CircularProgress,
    Fluid,
    FluidItem
} from '@cdk-uip/react';
import { ThemeProvider } from "styled-components";
import { fortellisTheme } from "@cdk-rds/core";
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink,
} from "@cdk-rds/page-header";
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import {
  CardBody,
  CardContent,
  CardWrapper,
  CardSubtitle,
  CardTitle
} from "@cdk-rds/card";
import { PrimaryButton, SecondaryButton } from "@cdk-rds/button";
import {Input} from "@cdk-rds/input";
import {
    Toast,
    TOAST_VARIANTS
  } from "@cdk-rds/toast";
import { Checkbox } from '@cdk-rds/checkbox';
import ShowHide from '../shared/components/ShowHide/ShowHide';
import types from '../Signin/signinActionTypes';

import PropTypes from 'prop-types';
import AppFooter from '../shared/components/AppFooter/appFooter';
import config from '../config/app.conf.json';

const homeURL = config.appPlatformInfo.url;
const parentDivStyles = {
    height: window.innerHeight - 84,
  };
const cardStyle = { boxShadow: "none", borderRadius: 0, backgroundColor: "inherit" };
export class MultiFactorVerification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            verificationCode: '',
            isRemembered: false,
            continueClicked: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleRememberPassword = this.toggleRememberPassword.bind(this);
        this.resendVerificationCode = this.resendVerificationCode.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.getMaskedfactorValue = this.getMaskedfactorValue.bind(this);
    }

    handleSubmit() {
        if(!this.state.disabled) {
            this.props.verifyAuthnPassCode(this.props.selectedFactor.id, this.state.verificationCode, this.state.isRemembered);
            this.setState({
                disabled: true,
                verificationCode: '',
                isRemembered: false,
                continueClicked: false
            });
        } else {
            this.setState({
                continueClicked: true
            });
        }
    }

    handleChange(e) {
        this.setState({
            verificationCode: e.target.value,
            disabled: !e.target.value && true
        });
    }

    resendVerificationCode() {
        this.props.resendAuthnVerification(this.props.selectedFactor.id, this.state.isRemembered);
        this.setState({           
            continueClicked: false
        });
    }

    toggleRememberPassword(e) {
        this.setState({
            isRemembered: e.target.checked
        });
    }

    getErrorMessage() {
        const isVerificationError = this.props.verifyApiStatus && this.props.verifyApiStatus.error.errors;
        if (isVerificationError) {
            let error = this.props.verifyApiStatus.error;
            return error.errors.constructor === Object ? error.errors.errorSummary : error.errors.constructor === Array ? error.errors[0].message : 'Something went wrong. Please try again.';;
        } else {
            return 'Something went wrong. Please try again.'
        }
    };

    getMaskedfactorValue(){
        if(this.props.selectedFactor ){
            if (this.props.selectedFactor.factorType === 'email') {
                return this.props.selectedFactor.profile.email.replace('...', '***') 
            } else if( this.props.selectedFactor.factorType === 'sms') {
                return `(***)***-${this.props.selectedFactor.profile.phoneNumber.slice(-4)}`
            }
            else{
                return  "the selected factor";
            }
        } else{
            return  "the selected factor";
        }

    }

    render() {
        const { verificationCode, isRemembered } = this.state;
        const {verifyApiStatus}= this.props;
        const factor = this.getMaskedfactorValue();
        const status = verifyApiStatus ? verifyApiStatus.status : null;
        const errorMsg = this.getErrorMessage();
        const progressIndicator = verifyApiStatus && verifyApiStatus.status === types.INITIALIZED;
        const apiStatusErrMsg = verifyApiStatus ? verifyApiStatus.error.errors : null

        return (
            <ThemeProvider theme={fortellisTheme}>
            <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
            <PageHeaderWrapper className="listOfOrganization__Page-header" style={{"flex": 0}}>
              <PageHeaderTitle>
                <PageHeaderLink
                  className="listOfOrganization__Page-link-home"
                  iconPosition="leading"
                  text="FORTELLIS"
                  href={homeURL}
                />
              </PageHeaderTitle>
            </PageHeaderWrapper>
            <div className="parent-div" style={{flex: 1, padding:"0px"}}>
                <div style={{marginLeft: "15%", paddingTop:"5%" }}>
                <CardWrapper style={cardStyle}>
                <CardBody>
                  <CardTitle className="listOfOrganization__text-head" style={{
                  alignSelf: "center",
                  marginRight: 8,
                  width: "634px",
                }}>
                    {'Enter Verification Code'}
                  </CardTitle>               
                  <CardSubtitle style={{width: "389px", whiteSpace: "initial", marginLeft:10}}>
                  {`A verification code was sent to ${factor}.`}
                      </CardSubtitle>    
                </CardBody>
                <CardContent className="listOfOrganization__form-wrapper" >
                <ShowHide show={progressIndicator} className="listOfOrganization__spinner">
                        <Fluid
                            className="circularprogress__outer-wrapper"
                            halign="center"
                            valign="middle">
                            <FluidItem className="circularprogress__direct-wrapper">
                                <CircularProgress className="circularprogress__component" />
                            </FluidItem>
                        </Fluid>
                    </ShowHide>
                    {((status === types.FAILED) || (this.state.continueClicked && this.state.disabled)|| apiStatusErrMsg)?<div>
                    <div className="mfa-verification__toast-wrapper">
                    <Toast                      
                      id="Error-title"                      
                      content={!this.state.continueClicked && apiStatusErrMsg && verifyApiStatus.error.errors.constructor === Array ? verifyApiStatus.error.errors[0].message: 'Two-step verification error'}
                      variant={TOAST_VARIANTS.NEGATIVE}
                    />
                    </div>
                  </div>:<div></div>}
                        <div className='ssocard-text-field-wrapper' style={{width: "389px", display: "flex", marginTop:"14px", marginRight:"20px"}}>
                        <Input
                        label={'Enter verification code'}
                        id='verifyCode'
                        name='verifyCode'
                        value={verificationCode}
                        onChange={e => { this.handleChange(e); }}
                        />
                        
                        </div>
                        <div className='mfa-verification__checkbox-wrapper'>
                            <Checkbox
                                data-testid="rememberPassword"
                                checked={isRemembered}
                                name='rememberPassword'
                                onChange={e => { this.toggleRememberPassword(e); }}
                            />
                            <label>
                                Remember this device
                            </label>
                        </div>
                        
                        <div style={{width: "389px"}}>
                            <PrimaryButton style={{width: "389px"}}
                                text="Continue"
                                type="submit"
                                onClick={this.handleSubmit}
                            >
                            </PrimaryButton>
                        </div>
                        <div className="mfa-verification__resend-wrapper">
                                 Didn’t receive a verification code? 
                                
                            </div>
                            <SecondaryButton style={{backgroundColor:"#ffffff", width:"389px"}}
                               data-testid="resendVerification"
                               className="ssocard__forgotpwd-text--center"
                                text={"Send a new code"}
                                type=""
                                onClick={this.resendVerificationCode}
                            ></SecondaryButton>                
                </CardContent>
                </CardWrapper> 
                </div>
                <div className="mfa-image-wrapper">
          <div
            className="image-mfa-selection"
          ></div>
          </div>
                </div>
                <div className="listOfOrganization__footer">
                  <AppFooter />
                </div>
                </div>
            </ThemeProvider>
        );
    }
}

MultiFactorVerification.propTypes = {
    verifyAuthnPassCode: PropTypes.func.isRequired,
    resendAuthnVerification: PropTypes.func.isRequired,
    verifyApiStatus: PropTypes.object 
};

export default withAuth(MultiFactorVerification);
