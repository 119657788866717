import { connect } from "react-redux";
import ResetPassword from "./resetPassword";
import { actions } from "./resetPasswordActions";
import { withRouter } from "react-router-dom";
import { showSnack } from "react-redux-snackbar";
import types from "./resetPasswordTypes";

const mapStateToProps = state => {
  return {
    status: state.resetPwd.status,
    resetSubmitted: state.resetPwd.resetSubmitted,
    error: state.resetPwd.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetpwd: data => {
      dispatch(actions.resetpwd(data));
      dispatch(
        showSnack("successfulResetId", {
          label: "Reset success!",
          timeout: 7000,
          button: {
            label: "HIDE"
          }
        })
      );
    },
    clearPasswordReset: () => {
      dispatch({
        type: types.CLEAR_PASSWORD_RESET_FLAG
      });
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
