import signin from "../api/authn";
import mfaVerification from "../api/authnMFA";
import resendVerifyEmail from "../api/resendVerifyEmail";
import authz from "../api/authorize";
import types from "./signinActionTypes";
import { reduxapi } from "@cdkglobal/react-core";
import config from "../config/app.conf.json";

export const actions = {
  signin: (data, sessionRequired) => {
    return {
      [reduxapi.CALL_API]: {
        ...signin(data, sessionRequired),
        types: [
          types.SIGNIN_POST_REQUEST,
          types.SIGNIN_POST_RECEIVE,
          types.SIGNIN_POST_ERROR
        ]
      }
    };
  },
  resendVerifyEmail: data => {
    return {
      [reduxapi.CALL_API]: {
        ...resendVerifyEmail(data),
        types: [
          types.RESEND_POST_REQUEST,
          types.RESEND_POST_RECEIVE,
          types.RESEND_POST_ERROR
        ]
      }
    };
  },
  authz: data => {
    return {
      [reduxapi.CALL_API]: {
        ...authz(data),
        types: [
          types.AUTHZ_POST_REQUEST,
          types.AUTHZ_POST_RECEIVE,
          types.AUTHZ_POST_ERROR
        ]
      }
    };
  },
  putTncVersion: (data, version) => {
    return {
      [reduxapi.CALL_API]: {
        url: config.api.acceptTnc,
        method: "PUT",
        data: data,
        headers: {
          "Content-Type": "application/json",
          version
        },
        types: [
          types.ACCEPT_TNC_REQUEST,
          types.ACCEPT_TNC_RECEIVE,
          types.ACCEPT_TNC_ERROR
        ]
      }
    };
  },
  sendAuthnVerification: (factorId, data, isToRemeberDevice) => {
    return {
      [reduxapi.CALL_API]: {
        ...mfaVerification(factorId, data, isToRemeberDevice),
        types: [
          types.MFA_AUTHN_VERIFICATION_CODE_REQUEST,
          types.MFA_AUTHN_VERIFICATION_CODE_RECEIVE,
          types.MFA_AUTHN_VERIFICATION_CODE_ERROR
        ]
      }
    };
  },
  resendAuthnVerification: (factorId, data, isToRemeberDevice) => {
    return {
      [reduxapi.CALL_API]: {
        ...mfaVerification(factorId, data, isToRemeberDevice),
        types: [
          types.MFA_AUTHN_VERIFY_RESEND_REQUEST,
          types.MFA_AUTHN_VERIFY_RESEND_RECEIVE,
          types.MFA_AUTHN_VERIFY_RESEND_ERROR
        ]
      }
    };
  },
  verifyAuthnPassCode: (factorId, data, isToRemeberDevice) => {
    return {
      [reduxapi.CALL_API]: {
        ...mfaVerification(factorId, data, isToRemeberDevice),
        types: [
          types.MFA_AUTHN_VERIFY_REQUEST,
          types.MFA_AUTHN_VERIFY_RECEIVE,
          types.MFA_AUTHN_VERIFY_ERROR
        ]
      }
    };
  },
  initiateRequest: () => {
    return {
      type: types.SIGNIN_POST_REQUEST
    };
  },
  updateInvitation: token => {
    return {
      [reduxapi.CALL_API]: {
        url: config.api.updateInvitation,
        method: "PUT",
        data: {
          token: token
        },
        headers: {
          "Content-Type": "application/json"
        },
        types: [
          types.UPDATE_INVITATION_REQUEST,
          types.UPDATE_INVITATION_RESPONSE,
          types.UPDATE_INVITATION_ERROR
        ]
      }
    };
  }
};
