import changePassword from "../api/changePassword";
import types from "./settingsChangePasswordTypes";
import { reduxapi } from "@cdkglobal/react-core";

export const actions = {
  changePassword: ( data, userId, accessToken) => {
    return {
      [reduxapi.CALL_API]: {
        ...changePassword(data, userId, accessToken),
        types: [
          types.SETTINGS_CHANGE_PASSWORD_POST_REQUEST,
          types.SETTINGS_CHANGE_PASSWORD_POST_RECEIVE,
          types.SETTINGS_CHANGE_PASSWORD_POST_ERROR
        ]
      }
    };
  },
};
