import { connect } from "react-redux";
import { actions } from "./changePasswordActions";
import ChangePassword from "./changePassword";

const mapStateToProps = state => {
  return {
    changePassStatus: state.changePwd,
    disabled: state.changePwd.disabled,
    error: state.changePwd.error
  };
};

const mapDispachToProps = dispatch => {
  return {
    changePassword: (data, userId) => {
      dispatch(actions.changePassword(data, userId));
    }
  };
};

export default connect(mapStateToProps, mapDispachToProps)(ChangePassword);
