import React from "react";
import { CardTitle, CardSubtitle, CardText } from "@cdk-uip/react";
import PropTypes from "prop-types";
import status from "./challengeStatus";
import ChangePasswordForm from "./PasswordChange";
import ChallengeCard from "./ChallengeCard";
import {
  Toast,
  TOAST_POSITIONS,
  TOAST_VARIANTS,
  TOAST_VISIBILITY_DURATIONS
} from '@cdk-rds/toast';
import config from "../config/app.conf.json";

const homeURL = config.appPlatformInfo.url;

class Challenge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      disabled: "",
      snackbar: {
        show: false,
        message: "Loading message..."
      },
      status:""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);
    this.checkOktaToken = this.checkOktaToken.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handlePasswordSubmit(password, captchaResponseToken) {
    // #TODO: note - haven't yet done any logic to check the passwords match
    // or even that you even entered anything in the second box
    // should be able to share this logic with the sign up page
    // maybe even use that page instead of this one, in a different 'mode'?
    // that supplies the token and hides the rest of the fields?
    if (this.props.token) {
      this.token = this.props.token;
    }
    const authToken = this.props.token ? this.props.token : this.token;
    this.props.newPassword({
      newPassword: password,
      state: authToken,
      captchaResponseToken: captchaResponseToken
    });
  }

  componentDidMount() {
    this.checkOktaToken();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.status === status.PASSWORD_SET) {
      this.goToLogin();
      return false;
    }
    return true;
  }


  static getDerivedStateFromProps(props,state) {
    if (status.PASSWORD_NOT_SET === props.status && state.status !==props.status) {
      return {
        snackbar: {
          show: true,
        },
        status:props.status
      }
    }
      return{
        status:props.status
      }
  }

  goToLogin() {
    this.props.history.push({
      pathname: "/login"
    });
  }

  hideSnackbar() {
    this.setState({
      snackbar: {
        ...this.state.snackbar,
        show: false
      }
    });
  }

  checkOktaToken() {
    // call out to okta to verify token
    // then update state to trigger re-render
    this.props.checkToken(window.location.search.split("uuid=")[1]);
    this.setState({
      status: status.PENDING
    });
  }

  render() {
    // #TODO: 3 different potential states for this page
    // a 'working...' page while we're checking the validity of the token
    // an 'invalid token' page if the token turns out to be invalid - tells the user to restart the flow
    // a 'valid token' page that asks the user their security question
    // after that, we need a page for an invalid security question answer
    // and a page for a correct answer that lets the user change their password
    // then a page saying the password has been successfully changed and linking to the login screen

    // are these all different components? or different states of the same component?
    // they all fiddle around with the content of a fairly consistent page
    // so I'm tempted to keep it all in one component

    let title;
    let content;
    let error;
    let errorTitle;
    let snackbar;
    switch (this.props.status) {
      case status.TOKEN_PENDING:
        title = (
          <React.Fragment>
            <CardTitle className="ssocard__title ssocard__signin--title" large>
              Working on that request...
            </CardTitle>
            <CardSubtitle className="util__text--secondary">
              We appreciate your patience.
            </CardSubtitle>
          </React.Fragment>
        );
        content = null;
        snackbar = null;
        break;
      case status.TOKEN_INVALID:
        errorTitle = "Link Expired";
        error = "The link to reset your password has expired.";
        title = (
          <React.Fragment>
            <CardTitle
              className="ssocard__title ssocard__signin--title"
              large
            >
              {errorTitle}
            </CardTitle>
            <CardSubtitle className="util__text--secondary" style={{ padding: 0 }}>
              {error}
            </CardSubtitle>
          </React.Fragment>
        );
        content = (
          <CardText className="resetpwd-email-link">
            <a href={"/resetpwd"}>Resend change password email.</a>
          </CardText>
        );
        snackbar = null;
        break;

      // eslint-disable-next-line
      case status.PASSWORD_NOT_SET:
        title = (
          <React.Fragment>
            <CardTitle
              className="listOfOrganization__text-head"
              style={{
                alignSelf: "center",
                marginRight: 8,
                marginLeft: 0,
                width: "440px"
              }}
            >
              Change Password
              </CardTitle>
          </React.Fragment>
        );
        content = (
          <ChangePasswordForm
            recaptchaSiteKey={config.recaptchaSiteKey}
            goToLogin={this.goToLogin}
            submit={this.handlePasswordSubmit}
          />
        );
        snackbar = this.state.snackbar.show && (
          <Toast
            actions={[
              {
                onClick: this.hideSnackbar,
                text: 'Dismiss'
              }
            ]}
            content={"Your new password was previously used."}
            id="toast"
            variant={TOAST_VARIANTS.NEGATIVE}
            visibilityDuration={TOAST_VISIBILITY_DURATIONS.SIX_SECONDS}
            onVisibilityDurationEnd={() =>
              this.hideSnackbar
            }
            position={TOAST_POSITIONS.FIXED}
          />
        );

        break;
      case status.TOKEN_VALID:
        title = (
          <React.Fragment>
            <CardTitle className="ssocard__title ssocard__signin--title change-password-title" large>
              Change Password
            </CardTitle>
          </React.Fragment>
        );
        content = (
          <ChangePasswordForm
            recaptchaSiteKey={config.recaptchaSiteKey}
            goToLogin={this.goToLogin}
            submit={this.handlePasswordSubmit}
            error={this.props.error}
          />
        );
        snackbar = null;
        break;
      default:
        title = null;
        content = null;
        snackbar = null;
    }

    return (
      <ChallengeCard
        title={title}
        content={content}
        homeURL={homeURL}
        snackbar={snackbar}
        currentStatus={this.props.status}
      />
    );
  }
}

Challenge.challengeSubmitted = PropTypes.object.isRequired;
Challenge.propTypes = {
  token: PropTypes.string.isRequired,
  newPassword: PropTypes.string.isRequired,
  checkToken: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  checkTokenResponse: PropTypes.object.isRequired,
  disabled: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired
};

export default Challenge;
