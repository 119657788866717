import React, { Fragment } from "react";
import {
  Select,
  TextField,
  TextFieldHelperText,
  Subheading2
} from "@cdk-uip/react";

import PropTypes from "prop-types";
import ShowHide from "../shared/components/ShowHide/ShowHide";

class SecurityQuestions extends React.Component {
  constructor(props) {
    super(props);

    const recoveryQuestions = [
      {
        question: "What is the food you least liked as a child?"
      },
      {
        question: "What is the name of your first stuffed animal?"
      },
      {
        question: "What did you earn your first medal or award for?"
      },
      {
        question: "What is the toy/stuffed animal you liked the most as a kid?"
      },
      {
        question: "What was the first computer game you played?"
      },
      {
        question: "What is your favorite movie quote?"
      },
      {
        question: "What was the mascot of the first sports team you played on?"
      },
      {
        question: "What music album or song did you first purchase?"
      },
      {
        question: "What is your favorite piece of art?"
      },
      {
        question: "What was your grandmother's favorite dessert?"
      },
      {
        question: "What was the first thing you learned to cook?"
      },
      {
        question: "What was your dream job as a child?"
      },
      {
        question: "Where did you meet your spouse/significant other?"
      },
      {
        question: "Where did you go for your favorite vacation?"
      },
      {
        question: "Where were you on New Year's Eve in the year 2000?"
      },
      {
        question: "Who is your favorite speaker/orator?"
      },
      {
        question: "Who is your favorite book/movie character?"
      },
      {
        question: "Who is your favorite sports player?"
      }
    ];

    this.state = {
      recoveryQuestions: recoveryQuestions,
      initialLoad: true
    };

    this.clearInitialLoad = this.clearInitialLoad.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.clearInitialLoad();
    this.props.onAnswerChange(event);
  }

  clearInitialLoad() {
    if (this.state.initialLoad) {
      this.setState({
        initialLoad: false
      });
    }
  }

  render() {
    let shouldShowError = false;
    let errorMessage = "";
    let answerValidClass = "mdc-text-field--upgraded";

    if (
      !this.state.initialLoad &&
      this.props.securityQuestion.indexOf(this.props.securityAnswer) !== -1
    ) {
      shouldShowError = true;
      errorMessage = "Answer cannot be a part of the question.";
      answerValidClass = "mdc-text-field--upgraded mdc-input-invalid";
    }

    if (!this.state.initialLoad && this.props.securityAnswer.length < 4) {
      shouldShowError = true;
      errorMessage = "Minimum length is 4 characters.";
      answerValidClass = "mdc-text-field--upgraded mdc-input-invalid";
    }

    return (
      <Fragment>
        <Subheading2>Security Question</Subheading2>
        <p className={"form-instructions"}>
          {`Select a security question below. Make sure your answer is memorable and secret. This will be used if you forget your login credentials. Your answer must not contain words from the security question.`}
        </p>
        <Select
          label={"Choose a Question*"}
          required
          id="recoveryQuestion"
          name="recoveryQuestion"
          onChange={this.props.onQuestionChange}
          value={this.props.securityQuestion}
        >
          {this.state.recoveryQuestions.map(({ question }) => (
            <option key={question} value={question}>
              {question}
            </option>
          ))}
        </Select>
        <TextField
          required
          label="Answer"
          name="recoveryAnswer"
          className={answerValidClass}
          type="text"
          inputMode="text"
          autoComplete="off"
          id="recoveryAnswer"
          onChange={this.onChange}
          onBlur={this.clearInitialLoad}
          value={this.props.securityAnswer}
          helperText={
            <ShowHide show={shouldShowError}>
              <TextFieldHelperText
                className="mdc-label-invalid"
                persistent
                validation
              >
                {errorMessage}
              </TextFieldHelperText>
            </ShowHide>
          }
        />
      </Fragment>
    );
  }
}

SecurityQuestions.propTypes = {
  securityQuestion: PropTypes.string.isRequired,
  securityAnswer: PropTypes.string.isRequired,
  onQuestionChange: PropTypes.func.isRequired,
  onAnswerChange: PropTypes.func.isRequired
};

export default SecurityQuestions;
