import config from "../config/app.conf.json";

export default data => ({
  url: config.api.resetpwd,
  method: "POST",
  data: data,
  headers: {
    "Content-Type": "application/json"
  }
});
