import React from "react";
import PropTypes from "prop-types";
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { EntityProvider as FrcEntityProvider } from "@cdk-prod/fortellis-entity-context";

import { useOktaAuth } from '@okta/okta-react';
import config from "../config/app.conf.json";

function EntityProvider({ auth, children }) {
  const { oktaAuth } = useOktaAuth();

  async function updateEntity(entityId) {
    await oktaAuth.signInWithRedirect({
      state: `${entityId !== 'personal-account' ? entityId : '*'}`
    });
  }
  return (
    <FrcEntityProvider
      accessToken={auth.accessToken || ""}
      authenticated={auth.isAuthenticated}
      env={config.env}
      userId={(auth.userData && auth.userData.sub) || ""}
      // secure={false} // Add this line for allowing EntityProvider to read from localhost cookie
      updateEntity={updateEntity}
    >
      {children}
    </FrcEntityProvider>
  );
}

EntityProvider.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default withAuth(EntityProvider);
