import React from 'react';
import { Provider } from 'react-intl-redux';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { history } from './history';
import { store } from './store';
import SignIn from './Signin/signinContainer';
import ResetPassword from './ResetPassword/resetPasswordContainer';
import JoinOrganization from './JoinOrganization/JoinOrganization';
import Challenge from './Challenge/challengeContainer';
import AccountActivation from './AccountActivation/accountActivationContainer';
import PageNotFound from './NotFound/pageNotFound';
import { ConnectedRouter } from 'react-router-redux';
import Success from './ConfirmationPages/success';
import Failure from './ConfirmationPages/failure';
import ChangeSecurityQuestion from './ChangeSecurityQuestion/ChangeSecurityQuestion';
import ChangePassword from './ChangePassword/changePasswordContainer';
import Registration from './Registration/registrationContainer';
import VerifyEmailAddress from './ConfirmationPages/verifyEmailAddress';
import SuspendedAccount from './ConfirmationPages/suspendedAccount';
import PasswordChanged from './ConfirmationPages/passwordChanged';
import ResetPasswordEmail from './ConfirmationPages/resetPasswordEmail';
import EntityProvider from './EntityContext/EntityProvider';
import { IntlProvider, addLocaleData } from 'react-intl';
import { LocaleProvider, LocaleConsumer } from '@fortellis/locale';
import { withAuthLoader } from './authLoader';
import config from './config/app.conf.json';
import headerLocaleData from '@fortellis/header/locales/data.json';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import './index.css';
import Settings from './SettingsTab/settingsContainer';
import Logout from './logout';
import multiFactorSelection from './MultifactorAuthentication/multiFactorSelection';
import MultiFactorVerification from './MultifactorAuthentication/multiFactorVerification';
import AuthSessionRefreshDialog from './Header/AuthSessionRefreshDialog';
import Invite from './Invite/invite';

import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { getAuthContext, HideSignOut } from '@cdk-prod/fortellis-auth-context';

const localeData = {
    en: {
        ...headerLocaleData.en
    },
    fr: {
        ...headerLocaleData.fr
    }
};

const baseUrl = `${config.api.authorize.protocol}://${config.api.authorize.domain}/${config.api.authorizeV2.path}`;
const clientId = config.api.authorize.query.client_id;
const scope = config.api.authorize.query.scope_str;
const accountServerURL = config.accountServerURL;

addLocaleData([...en, ...fr]);


const env = {
  issuer: accountServerURL,
  clientId: clientId,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false
};

class Root extends React.Component {
  constructor(props) {
    super(props);

  }
  
  render(){
    return (
        
            <Security
              oktaAuth={this.props.oktaAuth}
              restoreOriginalUri={this.props.restoreOriginalUri}
                >
                    <LocaleProvider>
                        <LocaleConsumer>
                            {localeContext => (
                                <IntlProvider
                                    locale={localeContext.locale}
                                    messages={localeData[localeContext.locale]}
                                >
                                    <EntityProvider>
                                    <HideSignOut/>
                                        <AuthSessionRefreshDialog />
                                        <div>
                                            <Switch>
                                                <Route exact path='/' component={SignIn} />
                                                <Route path='/invite' component={Invite} />
                                                <Route exact path='/login' component={SignIn} />
                                                <Route exact path='/factor-selection' component={multiFactorSelection} />
                                                <Route exact path='/factor-verification' component={MultiFactorVerification} />
                                                <Route exact path='/signup' component={Registration} />
                                                <Route exact path='/resetpwd' component={ResetPassword} />
                                                <SecureRoute
                                                    exact path='/list-of-organizations' component={JoinOrganization}
                                                />
                                                <SecureRoute
                                                    exact path='/settings' component={Settings}
                                                />
                                                <SecureRoute
                                                    exact path='/logout' component={Logout}
                                                />
                                                <Route path='/create-new-password' component={Challenge} />
                                                <Route exact path='/verify' component={AccountActivation} />
                                                <Route path='/email-verification-success' component={Success} />
                                                <Route exact path='/email-verification-failure' component={Failure} />
                                                <Route exact path='/change-password' component={ChangePassword} />
                                                <Route exact path='/change-security-question' component={ChangeSecurityQuestion} />
                                                <Route exact path='/verify-email-address' component={VerifyEmailAddress} />
                                                <Route exact path='/awaiting-approval' component={Success} />
                                                <Route exact path='/suspended' component={SuspendedAccount} />
                                                <Route exact path='/password-changed' component={PasswordChanged} />
                                                <Route exact path='/reset-password-email' component={ResetPasswordEmail} />
                                                <Route exact path='/login/callback' component={LoginCallback} />
                                                <Route path='*' exact={true} component={PageNotFound} />
                                            </Switch>
                                        </div>
                                    </EntityProvider>
                                </IntlProvider>
                            )}
                        </LocaleConsumer>
                    </LocaleProvider>
                </Security>
    )

  }
   

  }



const RootWithRouterAccess = withRouter(
  getAuthContext({ WrappedComponent: Root, env})
);
 
export default class App extends React.Component {
  render() {
    return <Provider store={store}>
        <Router history={history}>
            <RootWithRouterAccess/>
        </Router>
    </Provider>
  }
}
