import config from "../config/app.conf.json";
import types from "./accountActivationTypes";
import { reduxapi } from "@cdkglobal/react-core";

let apiConfig = {
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  }
};

const buildApiConfig = (apiConfig, uid, verifier, autoapprove) => {
  apiConfig.url = config.api.verify.replace(":uid", uid);
  apiConfig.data = {
    verifier,
    autoapprove
  };
  return apiConfig;
};

export const actions = {
  activateAccount: (uid, verifier, autoapprove) => {
    return {
      [reduxapi.CALL_API]: {
        ...buildApiConfig(apiConfig, uid, verifier, autoapprove),
        types: [
          types.ACTIVATE_POST_REQUEST,
          types.ACTIVATE_POST_RECEIVE,
          types.ACTIVATE_POST_ERROR
        ]
      }
    };
  }
};
