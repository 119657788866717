import React from "react";
import {
  Card,
  TextField,
  TextFieldHelperText,
  Subheading2
} from "@cdk-uip/react";
import { Tooltip } from "@cdk-uip/react-tooltip";
import ShowHide from "../shared/components/ShowHide/ShowHide";
import PropTypes from "prop-types";
import SettingsFooter from "../SettingsTab/settingsFooter";

class PersonalInformation extends React.Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, primaryPhone, email } = this.props.userInfo;
    this.state = {
      firstName,
      lastName,
      primaryPhone,
      email,
      isPhoneNumberValid: true,
      isEmailValid: true,
      isSaveDisabled: true,
      updatedData: {}
    };
    this.isValid = this.isValid.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    this.updatePersonalInfoState = this.updatePersonalInfoState.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    if (this.state.email || this.state.primaryPhone) {
      this.isValid();
    }
  }

  updatePersonalInfoState(userInfo) {
    this.setState(
      {
        ...userInfo
      },
      this.updateUserInformation
    );
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      this.updateUserInformation
    );
  }

  validatePhoneNumber(event) {
    let number = event.target.value.replace(/\D/g, "");
    this.setState(
      {
        isPhoneNumberValid: number.length === 10,
        primaryPhone: number
      },
      this.updateUserInformation
    );
  }

  validateEmail(event) {
    const state = {
      email: event.target.value,
      isEmailValid: event.target.value.match(".+@.+\\..+")
    };

    this.setState(
      {
        ...state
      },
      this.updateUserInformation
    );
  }

  formatPhoneNumber(number) {
    if (number === undefined) {
      number = "";
    }
    const phoneNumber = number.replace(/\D/g, "");
    const matches = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !matches ? number : `(${matches[1]}) ${matches[2]}-${matches[3]}`;
  }

  updateUserInformation() {
    let userData = {};
    if (this.state.firstName !== this.props.userInfo.firstName) {
      userData.firstName = this.state.firstName;
    }
    if (this.state.lastName !== this.props.userInfo.lastName) {
      userData.lastName = this.state.lastName;
    }
    if (this.state.primaryPhone !== this.props.userInfo.primaryPhone) {
      userData.primaryPhone = this.state.primaryPhone;
    }
    if (Object.keys(userData).length > 0) {
      const state = {
        isSaveDisabled: !this.state.isPhoneNumberValid,
        updatedData: userData
      };
      this.setState({
        ...state
      });
    } else {
      const state = {
        isSaveDisabled: true,
        updatedData: {}
      };
      this.setState({
        ...state
      });
    }
  }

  save(event) {
    this.props.updateUser(
      this.props.auth.userData.sub,
      this.state.updatedData,
      this.props.auth.accessToken
    );
  }

  cancel(event) {
    this.updatePersonalInfoState(this.props.userInfo);
  }

  isValid() {
    this.validateEmail({
      target: {
        value: this.state.email
      }
    });
    this.validatePhoneNumber({
      target: {
        value: this.state.primaryPhone
      }
    });
    return this.isFormValid();
  }

  isFormValid() {
    return (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.email !== "" &&
      this.state.email.match(".+@.+\\..+") &&
      this.state.primaryPhone !== "" &&
      this.state.primaryPhone.length === 10
    );
  }

  render() {
    return (
      <Card className="stepper-registration-card settingsBackground">
        <Subheading2 className="settings-panel-header">
          {"Personal Information"}
        </Subheading2>
        <div>
          <div className="settings-input">
            <TextField
              autoFocus
              label={"First Name"}
              id="firstName"
              name="firstName"
              type="text"
              inputMode="text"
              autoComplete="given-name"
              value={this.state.firstName}
              onChange={this.handleChange}
            />
          </div>
          <div className="settings-input">
            <TextField
              label={"Last Name"}
              id="lastName"
              name="lastName"
              type="text"
              inputMode="text"
              autoComplete="family-name"
              value={this.state.lastName}
              onChange={this.handleChange}
            />
          </div>
          <div className="settings-input">
            <TextField
              readOnly
              label={
                <div>
                  <span>Email</span>
                  <span>
                    <i
                      id="tooltip1"
                      className="material-icons help-label-icon help-icon"
                      style={{
                        pointerEvents: "auto"
                      }}
                    >
                      help
                    </i>
                  </span>
                </div>
              }
              id="email"
              name="email"
              type="email"
              inputMode="email"
              autoComplete="email"
              pattern=".+@.+\..+"
              value={this.state.email}
              helperText={
                <ShowHide show={!this.state.isEmailValid}>
                  <TextFieldHelperText persistent className="mdc-label-invalid">
                    Please enter a valid email address.
                  </TextFieldHelperText>
                </ShowHide>
              }
              onChange={this.validateEmail}
              onBlur={this.validateEmail}
            />
            <Tooltip className="toolTip" htmlFor="tooltip1">
              <p className="text">
                <span className="toolTipContactFortellis">
                  Contact Fortellis{" "}
                </span>
                to change your email address. In the future you be able to
                change this yourself.
              </p>
            </Tooltip>
          </div>
          <div className="settings-input">
            <TextField
              label={"Mobile Number"}
              id="primaryPhone"
              name="primaryPhone"
              type="tel"
              inputMode="tel"
              autoComplete="tel"
              helperText={
                <ShowHide show={!this.state.isPhoneNumberValid}>
                  <TextFieldHelperText
                    persistent
                    className={"mdc-label-invalid customErrorMsg"}
                  >
                    Please enter a valid North American phone number.
                  </TextFieldHelperText>
                </ShowHide>
              }
              value={this.formatPhoneNumber(this.state.primaryPhone)}
              onChange={this.validatePhoneNumber}
              onBlur={this.validatePhoneNumber}
            />
          </div>
          <SettingsFooter
            cancel={this.cancel}
            handleSubmit={this.save}
            disableButton={this.state.isSaveDisabled}
          />
        </div>
      </Card>
    );
  }
}

PersonalInformation.propTypes = {
  updateUser: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

export default PersonalInformation;
