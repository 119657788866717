import {
  getFactors,
  enrollFactor,
  resendFactor,
  activateFactor,
  deactivateFactor
} from "../api/securitySettings";
import types from "./securitySettingsTypes";
import { reduxapi } from "@cdkglobal/react-core";

export const actions = {
  getFactors: (accessToken, userId) => {
    return {
      [reduxapi.CALL_API]: {
        ...getFactors(accessToken, userId),
        types: [
          types.GET_FACTORS_REQUEST,
          types.GET_FACTORS_RECEIVE,
          types.GET_FACTORS_ERROR
        ]
      }
    };
  },
  enrollFactor: (accessToken, data, userId) => {
    return {
      [reduxapi.CALL_API]: {
        ...enrollFactor(accessToken, data, userId),
        types: [
          types.ENROLL_FACTOR_POST_REQUEST,
          types.ENROLL_FACTOR_POST_RECEIVE,
          types.ENROLL_FACTOR_POST_ERROR
        ],
        resend: "test"
      }
    };
  },
  resendFactor: (accessToken, data, factorId, userId) => {
    return {
      [reduxapi.CALL_API]: {
        ...resendFactor(accessToken, data, factorId, userId),
        types: [
          types.RESEND_FACTOR_POST_REQUEST,
          types.RESEND_FACTOR_POST_RECEIVE,
          types.RESEND_FACTOR_POST_ERROR
        ]
      }
    };
  },
  activateFactor: (accessToken, data, factorId, userId) => {
    return {
      [reduxapi.CALL_API]: {
        ...activateFactor(accessToken, data, factorId, userId),
        types: [
          types.ACTIVATE_FACTOR_POST_REQUEST,
          types.ACTIVATE_FACTOR_POST_RECEIVE,
          types.ACTIVATE_FACTOR_POST_ERROR
        ]
      }
    };
  },
  deactivateFactor: (accessToken, data, userId) => {
    return {
      [reduxapi.CALL_API]: {
        ...deactivateFactor(accessToken, data, userId),
        types: [
          types.DEACTIVATE_FACTOR_POST_REQUEST,
          types.DEACTIVATE_FACTOR_POST_RECEIVE,
          types.DEACTIVATE_FACTOR_POST_ERROR
        ]
      }
    };
  }
};
