import changePassword from "../api/changePassword";
import types from "./changePasswordTypes";
import { reduxapi } from "@cdkglobal/react-core";

export const actions = {
  changePassword: (data, userId) => {
    return {
      [reduxapi.CALL_API]: {
        ...changePassword(data, userId),
        types: [
          types.CHANGE_PASSWORD_POST_REQUEST,
          types.CHANGE_PASSWORD_POST_RECEIVE,
          types.CHANGE_PASSWORD_POST_ERROR
        ]
      }
    };
  }
};
