let jsonService = function() {
    let parse = function(jsonBody) {
        if (typeof jsonBody === 'string') {
            try {
                return JSON.parse(jsonBody);
            } catch (e) {
                console.error(e);
            }
        } else {
            return jsonBody;
        }
    };

    let stringify = function(jsonBody) {
        if (typeof jsonBody === 'string') {
            return jsonBody;
        } else {
            return JSON.stringify(jsonBody);
        }
    };

    return {
        parse,
        stringify
    };
};

export default jsonService;
