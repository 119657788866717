import config from "../config/app.conf.json";
import { CustomLoginConfig } from "../Signin/CustomLoginConfig";
import UriBuilder from "../shared/utilities/UriBuilder";

const getAuthorizeRedirectURLWithSessionToken = SessionToken => {
  let buildUrl = new UriBuilder();
  CustomLoginConfig.customeLogin.sessionToken = SessionToken;
  let url = buildUrl
    .protocol(config.api.authorize.protocol)
    .domain(config.api.authorize.domain)
    .path(config.api.authorize.path)
    .query(CustomLoginConfig.customeLogin)
    .build();

  return url;
};

const getAuthorizeRedirectURLWithKey = (key, error) => {
  let buildUrl = new UriBuilder();
  let queryParams = {
    key
  };
  if (error) {
    queryParams["ssoerror"] = error;
  }
  let url = buildUrl
    .protocol(config.api.authorizeV2.protocol)
    .domain(config.api.authorizeV2.domain)
    .path(config.api.authorizeV2.path)
    .query(queryParams)
    .build();

  return url;
};

export default {
  getAuthorizeRedirectURLWithSessionToken,
  getAuthorizeRedirectURLWithKey
};
