export default {
  RESETPWD_POST_RECEIVE: "RESETPWD_POST_RECEIVE",
  RESETPWD_POST_ERROR: "RESETPWD_POST_ERROR",
  RESETPWD_POST_REQUEST: "RESETPWD_POST_REQUEST",
  CLEAR_PASSWORD_RESET_FLAG: "CLEAR_PASSWORD_RESET_FLAG",
  INITIALIZED: "INITIALIZED",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  ACCOUNT_NOT_FOUND_ERROR_CODE: "E0000095",
  ACCOUNT_NOT_ACTIVATED_ERROR_CODE: "E0000034"
};
