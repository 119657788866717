import config from "../config/app.conf.json";

export default data => {
  let configurl = config.api.resendVerifyEmail.replace(
    ":username",
    data.username
  );
  return {
    url: configurl,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json"
    }
  };
};
