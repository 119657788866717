export default {
  TOKEN_PENDING: "TOKEN_PENDING",
  TOKEN_VALID: "TOKEN_VALID",
  TOKEN_INVALID: "TOKEN_INVALID",
  ANSWER_PENDING: "ANSWER_PENDING",
  ANSWER_CORRECT: "ANSWER_CORRECT",
  ANSWER_INCORRECT: "ANSWER_INCORRECT",
  PASSWORD_PENDING: "PASSWORD_PENDING",
  PASSWORD_SET: "PASSWORD_SET",
  PASSWORD_NOT_SET: "PASSWORD_NOT_SET",
  INITIALIZED: "INITIALIZED"
};
