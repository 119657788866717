import * as config from "../config/app.conf.json";

const invitation = verifier => {
  let configUrl = config.api.invitation;
  return {
    url: `${configUrl}${verifier}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  };
};

export default {
  invitation
};
