import React from "react";
import {
  Card,
  Button,
  TextField,
  TextFieldHelperText,
  Snackbar,
  Subheading2
} from "@cdk-uip/react";
import ShowHide from "../shared/components/ShowHide/ShowHide";
import MultiFactorAuthenticationDialog from "./multiFactorAuthenticationDialog";
import FortellisDialog from "./fortellisDialog";
import PropTypes from "prop-types";
import types from "./securitySettingsTypes";

class SecuritySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMfaOn: false,
      isContactNumberValid: false,
      contactNumber: "",
      showDialog: false,
      isDirtyForm: false,
      targetElement: "",
      isVerified: false,
      isEditable: false,
      resendStatus: {
        show: false,
        msg: ""
      },
      enrolledFactor: {}
    };
    this.toggleButton = this.toggleButton.bind(this);
    this.validateContactNumber = this.validateContactNumber.bind(this);
    this.formatContactNumber = this.formatContactNumber.bind(this);
    this.cancel = this.cancel.bind(this);
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
    this.onDialogCancel = this.onDialogCancel.bind(this);
    this.onDialogAccept = this.onDialogAccept.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.showCancel = this.showCancel.bind(this);
    this.resendVerificationCode = this.resendVerificationCode.bind(this);
    this.checkActiveNumber = this.checkActiveNumber.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);
    this.validateAndSendVerificationCode = this.validateAndSendVerificationCode.bind(
      this
    );
    this.onCancelNumberChangeWarning = this.onCancelNumberChangeWarning.bind(
      this
    );
  }

  componentDidMount() {
    const { auth } = this.props;
    this.props.getFactors(auth.accessToken, auth.userData.sub);
  }

  shouldComponentUpdate(nextProps, nextState) {
    switch (nextProps.actionType) {
      case types.GET_FACTORS_RECEIVE:
        if (nextProps.factors && !nextState.isVerified) {
          this.verifyFactorStatus(nextProps.factors);
        }
        break;
      case types.ENROLL_FACTOR_POST_RECEIVE:
        if (nextProps.apiResponse !== this.state.enrolledFactor) {
          let newEnrolledFactor = nextProps.apiResponse;
          if (
            nextProps.apiResponse &&
            nextProps.apiResponse.status &&
            nextProps.apiResponse.status.toLowerCase() ===
              "pending_activation" &&
            !nextState.showDialog
          ) {
            this.setState({
              enrolledFactor: newEnrolledFactor,
              showDialog: true
            });
          } else if (
            nextProps.apiResponse &&
            nextProps.apiResponse.status &&
            nextProps.apiResponse.status.toLowerCase() === "active"
          ) {
            this.setState({
              enrolledFactor: newEnrolledFactor,
              isEditable: false
            });
            // this.hideSnackbar();
          }
        }
        break;
      case types.ACTIVATE_FACTOR_POST_RECEIVE:
        if (
          nextProps.apiResponse &&
          nextProps.apiResponse.status &&
          nextProps.apiResponse.status.toLowerCase() === "active" &&
          nextState.showDialog
        ) {
          this.setState({
            enrolledFactor: nextProps.apiResponse,
            isEditable: false,
            showDialog: false
          });
        }
        break;
      case types.DEACTIVATE_FACTOR_POST_RECEIVE:
        if (
          nextState.enrolledFactor &&
          nextState.enrolledFactor.status &&
          nextState.enrolledFactor.status.toLowerCase() === "active"
        ) {
          this.setState({
            enrolledFactor: {}
          });
        }
        break;
      default:
        break;
    }
    return true;
  }

  verifyFactorStatus(factors) {
    if (factors) {
      const smsFactor = factors.filter(
        factor => factor.factorType.toLowerCase() === "sms"
      );
      if (smsFactor[0] && smsFactor[0].status.toLowerCase() === "active") {
        const contactNo = smsFactor[0].profile.phoneNumber
          ? smsFactor[0].profile.phoneNumber.slice(2)
          : "";
        this.setState(
          {
            contactNumber: contactNo,
            enrolledFactor: smsFactor[0],
            isContactNumberValid: contactNo.length === 10,
            isVerified: true
          },
          () => {
            this.toggleButton("verify");
          }
        );
      }
    }
  }

  validateContactNumber(event) {
    let number = event.target.value.replace(/\D/g, "");
    this.setState(
      {
        isContactNumberValid: number.length === 10,
        contactNumber: number,
        isEditable: true
      },
      this.updateUserInformation
    );
  }

  formatContactNumber(number) {
    if (number === undefined) {
      number = "";
    }
    const ContactNumber = number.replace(/\D/g, "");
    const matches = ContactNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !matches ? number : `(${matches[1]}) ${matches[2]}-${matches[3]}`;
  }

  toggleButton(calledFrom) {
    const { isMfaOn, enrolledFactor } = this.state;
    const { auth } = this.props;
    if (isMfaOn) {
      if (
        enrolledFactor &&
        enrolledFactor.id &&
        enrolledFactor.status.toLowerCase() === "active"
      ) {
        const email = auth.userData && auth.userData.email;
        const name = auth.userData && auth.userData.name;
        this.props.deactivateFactor(
          auth.accessToken,
          [enrolledFactor.id, email, "name:" + name],
          auth.userData.sub
        );
      }
    }
    this.handleClick();
    if (!isMfaOn && calledFrom !== "verify") {
      this.setState({
        contactNumber: ""
      });
    }
    this.setState(prevState => ({
      isMfaOn: !prevState.isMfaOn,
      isEditable: false
    }));
  }

  validateAndSendVerificationCode() {
    if (
      this.state.enrolledFactor &&
      this.state.enrolledFactor.status &&
      this.state.enrolledFactor.status.toLowerCase() === "active"
    ) {
      this.props.showChangeNumberWarning(true);
    } else {
      this.sendVerificationCode();
    }
  }

  onCancelNumberChangeWarning() {
    this.props.showChangeNumberWarning(false);
  }

  sendVerificationCode() {
    const { auth, enrollFactor } = this.props;
    const { contactNumber, enrolledFactor } = this.state;
    const payload = {
      factorType: "sms",
      email: auth.userData && auth.userData.email,
      name: auth.userData && auth.userData.name,
      provider: "OKTA",
      profile: {
        phoneNumber: "+1" + contactNumber
      }
    };
    if (
      enrolledFactor &&
      enrolledFactor.status &&
      enrolledFactor.status.toLowerCase() === "active"
    ) {
      payload.oldFactorId = enrolledFactor.id;
    }
    if (this.props.shouldShowNumberChangeWarnig) {
      this.props.showChangeNumberWarning(false);
    }
    enrollFactor(auth.accessToken, payload, auth.userData.sub);
  }

  hideSnackbar() {
    this.props.updateMessage();
  }

  cancel() {
    const { enrolledFactor } = this.state;
    if (
      enrolledFactor &&
      enrolledFactor.status &&
      enrolledFactor.status.toLowerCase() === "active"
    ) {
      this.setState({
        contactNumber: enrolledFactor.profile.phoneNumber.slice(2),
        isEditable: false
      });
    } else {
      this.toggleButton();
      this.setState({
        showDialog: false,
        isDirtyForm: false
      });
    }
  }
  onDialogCancel() {
    if (this.state.isDirtyForm) {
      this.state.targetElement.click();
      this.setState({
        targetElement: ""
      });
    }
    this.setState({
      showDialog: false,
      isDirtyForm: false
    });
  }
  onDialogAccept(code) {
    const { auth, activateFactor } = this.props;
    const { enrolledFactor } = this.state;
    if (code) {
      activateFactor(
        auth.accessToken,
        {
          email: auth.userData && auth.userData.email,
          name: auth.userData && auth.userData.name,
          passCode: code
        },
        enrolledFactor.id,
        auth.userData.sub
      );
    } else {
      this.setState({
        showDialog: false,
        isDirtyForm: false,
        targetElement: ""
      });
    }
  }

  showCancel() {
    return (
      this.state.isEditable &&
      this.state.contactNumber !== "" &&
      !this.checkActiveNumber()
    );
  }

  checkActiveNumber() {
    const { contactNumber, enrolledFactor } = this.state;
    return (
      enrolledFactor &&
      enrolledFactor.profile &&
      enrolledFactor.profile.phoneNumber.slice(2) === contactNumber &&
      enrolledFactor.status &&
      enrolledFactor.status.toLowerCase() === "active"
    );
  }

  resendVerificationCode() {
    const { auth, resendFactor } = this.props;
    const { enrolledFactor } = this.state;
    this.hideSnackbar();
    resendFactor(auth.accessToken, {}, enrolledFactor.id, auth.userData.sub);
  }

  handleClick(e) {
    const factorIsActive =
      this.state.enrolledFactor &&
      this.state.enrolledFactor.status &&
      this.state.enrolledFactor.status.toLowerCase() === "active";
    if (e && !this.state.targetElement && !factorIsActive) {
      e.preventDefault();
      this.setState({
        targetElement:
          e.target.tagName.toLowerCase() === "svg"
            ? e.target.parentElement
            : e.target
      });
    }
    if (!this.state.isDirtyForm) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
      if (this.state.isMfaOn && e && !factorIsActive) {
        this.setState({
          isDirtyForm: true,
          showDialog: true
        });
      }
    } else {
      if (!this.state.isMfaOn) {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.setState({
          isDirtyForm: false,
          showDialog: false
        });
      }
      if (this.state.isMfaOn && this.state.isDirtyForm) {
        this.setState({
          showDialog: true
        });
      }
    }
  }

  handleOutsideClick(e) {
    if (
      e.target &&
      e.target.ownerDocument &&
      e.target.ownerDocument.activeElement &&
      e.target.ownerDocument.activeElement.href &&
      this.props.containerRef &&
      !this.props.containerRef.contains(e.target)
    ) {
      this.handleClick(e);
    } else if (
      e.target &&
      e.target.classList &&
      e.target.classList.contains("mdc-dialog__backdrop")
    ) {
      this.onDialogCancel();
    } else {
      // Do nothing and ignore clicks on the component itself
    }
  }
  render() {
    const {
      isMfaOn,
      contactNumber,
      isContactNumberValid,
      isEditable,
      isDirtyForm,
      showDialog
    } = this.state;
    return (
      <div ref={this.props.containerRef}>
        <Card className="stepper-registration-card settingsBackground">
          <Subheading2 className="settings-panel-header mfa-security--title">
            {"Security"}
          </Subheading2>
          <div className="mfa-security--sub-title">Two-step verification</div>
          <div className="mfa-security--content">
            This extra step will help keep your account safe, even if someone
            obtains your password. We'll text your mobile number with a
            verification code that you will need to enter before we allow an
            unrecognized device to sign in to your account.
          </div>
          <div className="mfa-security__toggle--container">
            <span>
              Two-step verification is currently turned {isMfaOn ? "on" : "off"}
              .
            </span>
            <Button
              name="btn-MFA"
              className="mfa-security__button"
              onClick={this.toggleButton}
            >
              {isMfaOn ? "TURN OFF" : "TURN ON"}
            </Button>
          </div>
          <ShowHide show={isMfaOn}>
            <p className="mfa-security__contact--sub-title">Contact Methods</p>
            <p className="mfa-security__contact--content">
              Fortellis will only use this information for account security.
              Message and data rates may apply.
            </p>
            <div className="mfa-security__contact mfa-verification__input">
              <TextField
                label={"Mobile Number"}
                id="primaryContact"
                name="primaryContact"
                type="tel"
                inputMode="tel"
                autoComplete="tel"
                helperText={
                  <ShowHide show={contactNumber && !isContactNumberValid}>
                    <TextFieldHelperText
                      persistent
                      className={"mdc-label-invalid customErrorMsg"}
                    >
                      Please enter a valid North American phone number.
                    </TextFieldHelperText>
                  </ShowHide>
                }
                value={this.formatContactNumber(contactNumber)}
                onChange={this.validateContactNumber}
                onBlur={this.validateContactNumber}
              />
            </div>
            <div className="settingsFooter">
              <ShowHide show={this.showCancel()}>
                <Button className="cancelButton" onClick={this.cancel}>
                  CANCEL
                </Button>
              </ShowHide>
              <Button
                type="submit"
                raised
                primary
                disabled={
                  !isContactNumberValid ||
                  !isEditable ||
                  this.checkActiveNumber()
                }
                onClick={this.validateAndSendVerificationCode}
              >
                {"Next"}
              </Button>
            </div>
          </ShowHide>
          <MultiFactorAuthenticationDialog
            open={showDialog}
            isDirtyForm={isDirtyForm}
            contactNumber={contactNumber}
            onCancel={() => this.onDialogCancel()}
            onAccept={code => this.onDialogAccept(code)}
            resendVerificationCode={() => this.resendVerificationCode()}
          />
          <FortellisDialog
            open={this.props.shouldShowNumberChangeWarnig}
            header={"Disable current mobile number."}
            body={
              "Click continue to stop using your current mobile number for two-step verification."
            }
            onAccept={this.sendVerificationCode}
            accceptText="CONTINUE"
            onCancel={this.onCancelNumberChangeWarning}
            cancelText="CANCEL"
          />
          {(!!this.props.message ||
            this.props.status === types.INITIALIZED) && (
            <div className="mfa-security__snackbar">
              <Snackbar
                show={!!this.props.message}
                timeout={5000}
                message={this.props.message}
                onClose={this.hideSnackbar}
              />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

SecuritySettings.propTypes = {
  auth: PropTypes.object.isRequired,
  getFactors: PropTypes.func,
  enrollFactor: PropTypes.func,
  resendFactor: PropTypes.func,
  activateFactor: PropTypes.func,
  deactivateFactor: PropTypes.func,
  updateMessage: PropTypes.func,
  factors: PropTypes.array,
  apiResponse: PropTypes.object,
  containerRef: PropTypes.object,
  actionType: PropTypes.string,
  status: PropTypes.string,
  message: PropTypes.string
};

export default SecuritySettings;
