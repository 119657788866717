import React from "react";
import { Button } from "@cdk-uip/react";
import "@cdk-uip/icons";
import PropTypes from "prop-types";

const SettingsFooter = props => {
  const { cancel, handleSubmit, disableButton, submitButtonText } = props;

  return (
    <div className="settingsFooter">
      {disableButton ? null : (
        <Button className="cancelButton" onClick={() => cancel()}>
          CANCEL
        </Button>
      )}
      <Button
        raised
        primary
        disabled={disableButton}
        onClick={() => handleSubmit()}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};

SettingsFooter.propTypes = {
  cancel: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired
};

SettingsFooter.defaultProps = {
  handleSubmit: e => {
    alert("Submit function not definded");
  },
  cancel: e => {
    alert("Cancel function not definded");
  },
  disableButton: true,
  submitButtonText: "Save"
};

export default SettingsFooter;
