import { connect } from "react-redux";
import AccountActivation from "./accountActivation";
import { actions } from "./accountActivationActions";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => {
  return {
    accountActivation: state.accountActivation,
    email: state.accountActivation.email,
    firstName: state.accountActivation.firstName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    activateAccount: (uid, verifier, autoapprove) => {
      dispatch(actions.activateAccount(uid, verifier, autoapprove));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountActivation)
);
