import {
  CHANGE_SECURITY_QUESTION_REQUEST,
  CHANGE_SECURITY_QUESTION_RECEIVE,
  CHANGE_SECURITY_QUESTION_ERROR,
  UPDATE_SECURITY_QUESTIION_MESSAGE
} from "./ChangeSecurityQuestionTypes";

const initialState = {
  question: "",
  message: "",
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SECURITY_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case CHANGE_SECURITY_QUESTION_RECEIVE:
      const { question } = action.response.payload;
      return {
        ...state,
        question,
        isLoading: false
      };
    case CHANGE_SECURITY_QUESTION_ERROR:
      const message = action.apierror.payload.errors[0].message[0].errorSummary;
      return {
        ...state,
        isLoading: false,
        message
      };
    case UPDATE_SECURITY_QUESTIION_MESSAGE:
      return {
        ...state,
        message: action.message
      };
    default: {
      return state;
    }
  }
};
