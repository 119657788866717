import types from "./resetPasswordTypes";
import payloadParser from "../shared/utilities/payloadParser";

const initializedState = {
  status: types.INITIALIZED
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.RESETPWD_POST_REQUEST:
      return initializedState;
    case types.RESETPWD_POST_RECEIVE:
      return {
        status: types.SUCCESS,
        resetSubmitted: true
      };
    case types.RESETPWD_POST_ERROR:
      console.error({
        Message: "Password Reset Request Failed",
        Context: "RESET-PWD"
      });
      return {
        status: types.FAILED,
        error:
          payloadParser.getErrorMessageFromPayload(action) ||
          "Error has occurred during the password reset request!"
      };
    case types.CLEAR_PASSWORD_RESET_FLAG:
      return {
        resetSubmitted: undefined
      };
    default:
      return state;
  }
};
