import React from "react";
import { ThemeProvider } from "styled-components";
import { fortellisTheme } from "@cdk-rds/core";
import { Loader } from "@cdk-rds/loader";
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink
} from "@cdk-rds/page-header";
import {
  CardBody,
  CardContent,
  CardWrapper,
  CardSubtitle,
  CardTitle
} from "@cdk-rds/card";
import { PrimaryButton } from "@cdk-rds/button";
import { EmailInput } from "@cdk-rds/email-input";
import { Toast, TOAST_VARIANTS } from "@cdk-rds/toast";
import PropTypes from "prop-types";
import types from "./resetPasswordTypes";
import AppFooter from "../shared/components/AppFooter/appFooter";
import config from "../config/app.conf.json";
import ReCAPTCHA from "react-google-recaptcha";

const brandName = config.appPlatformInfo.brandName;
const homeURL = config.appPlatformInfo.url;
const contactUsURL = `${config.appPlatformInfo.url}/contact-us`;
const cardStyle = {
  boxShadow: "none",
  borderRadius: 0,
  backgroundColor: "inherit"
};

export class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formDisabled: true,
      captchaResponseToken: "",
      captchaReset: false,
      isSubmitClicked: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isvalidEmail = this.isvalidEmail.bind(this);
    this.goToSignup = this.goToSignup.bind(this);
    this.recaptchaRef = React.createRef();
    this.captchaCallback = this.captchaCallback.bind(this);
  }

  handleChange(e) {
    if (this.state.captchaResponseToken && this.isvalidEmail(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
        formDisabled: false
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        formDisabled: true
      });
    }
  }

  handleSubmit(e) {
    if(!this.state.formDisabled) {
      this.props.resetpwd({
        email: this.state.email,
        captchaResponseToken: this.state.captchaResponseToken
      });
      this.setState({
        formDisabled: true,
        captchaReset: false,
        isSubmitClicked: false
      });
    } else {
      this.setState({
        isSubmitClicked: true
      });
    }
  }

  isvalidEmail(inputEmail) {
    return inputEmail.match(".+@.+\\..+");
  }

  componentDidMount() {
    this.props.clearPasswordReset();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.resetSubmitted) {
      this.props.history.push({
        pathname: "/reset-password-email",
        search: `?email=${this.state.email}`
      });
      return false;
    }
    if (nextProps.status === types.FAILED && !this.state.captchaReset) {
      this.resetCaptcha();
    }
    return true;
  }

  goToSignup() {
    this.props.history.push({
      pathname: "/signup",
      search: this.props.location.search
    });
  }

  resetCaptcha() {
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }
    if (this.state.captchaResponseToken && !this.state.captchaReset) {
      this.setState({
        captchaResponseToken: null,
        captchaReset: true
      });
    }
  }

  captchaCallback(captchaResponse) {
    if (captchaResponse && this.isvalidEmail(this.state.email)) {
      this.setState({
        formDisabled: false,
        captchaResponseToken: captchaResponse
      });
    } else if (captchaResponse) {
      this.setState({
        captchaResponseToken: captchaResponse,
        formDisabled: true
      });
    } else if (this.isvalidEmail(this.state.email)) {
      this.setState({
        formDisabled: false
      });
    }
  }

  render() {
    return (
      <ThemeProvider theme={fortellisTheme}>
        <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
        <PageHeaderWrapper className="listOfOrganization__Page-header" style={{"flex" : 0}}>
          <PageHeaderTitle>
            <PageHeaderLink
              className="listOfOrganization__Page-link-home"
              iconPosition="leading"
              text="FORTELLIS"
              href={homeURL}
            />
          </PageHeaderTitle>
        </PageHeaderWrapper>
        <div className="parent-div" style={{flex: 1, padding:"0px"}}>
          <div style={{ marginLeft: "15%", paddingTop: "5%" }}>
            <CardWrapper style={cardStyle}>
              <CardBody>
                <CardTitle
                  data-testid="resetPassword"
                  className="listOfOrganization__text-head"
                  style={{
                    alignSelf: "center",
                    marginRight: 8,
                    width: "400px"
                  }}
                >
                  Reset Password
                </CardTitle>
                <CardSubtitle
                  data-testid="subtitle"
                  style={{
                    width: "400px",
                    whiteSpace: "initial",
                    marginLeft: 10
                  }}
                >
                  Enter your email address and we'll send you instrucions to
                  reset your password
                </CardSubtitle>
                <div
                  style={{
                    display:
                      this.props.status === types.INITIALIZED ? "block" : "none"
                  }}
                >
                  <Loader
                    data-testid="loader"
                    hideLabel={false}
                    label=""
                    spinnerClassName="circularprogress__component"
                    variant="inline"
                  />
                </div>
              </CardBody>
              <CardContent
                className="listOfOrganization__form-wrapper"
                style={{ alignSelf: "center" }}
              >
                <div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px", marginTop: "14px" }}
                >
                { ((this.state.isSubmitClicked && this.state.formDisabled) || this.props.error) && <Toast
                      id="Error-title"
                      content="Error has occured"
                      variant={TOAST_VARIANTS.NEGATIVE}
                    />
}
</div>
<div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px", marginTop: "14px" }}
                >
                  <EmailInput
                    id="email"
                    name="email"
                    label="Email"
                    value={this.state.email}
                    isRequired={false}
                    helperText=""
                    onChange={this.handleChange}
                    isFocused={true}
                    autoFocus
                  />
                </div>
                <ReCAPTCHA
                  id="resetpwd__recaptcha"
                  ref={this.recaptchaRef}
                  sitekey={config.recaptchaSiteKey}
                  onChange={this.captchaCallback}
                />
                <div
                  className="listOfOrganization__button-wrapper--primary"
                  style={{ width: "389px" }}
                >
                  <PrimaryButton
                    data-testid="sendInstructions"
                    text="Send Instructions"
                    type="submit"
                    onClick={this.handleSubmit}
                    className="resetpwdcard__button--primary"
                  ></PrimaryButton>
                </div>
                <CardSubtitle
                  className="ssocard__account-text--center"
                  style={{ marginTop: "24px" }}
                >
                  Don't have an Account?{" "}
                  <span
                    data-testid="goToSignup"
                    className="anchor c-text-signup__link util__anchor--pointer"
                    onClick={this.goToSignup}
                  >
                    {"Sign Up."}
                  </span>
                </CardSubtitle>
              </CardContent>
            </CardWrapper>
          </div>
          <div className="image-wrapper">
            <div className="image-forgotPassword"></div>
          </div>
        </div>
        <div className="listOfOrganization__footer">
          <AppFooter />
        </div>
        </div>
      </ThemeProvider>
    );
  }
}

ResetPassword.propTypes = {
  resetSubmitted: PropTypes.bool,
  resetpwd: PropTypes.func.isRequired,
  clearPasswordReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  status: PropTypes.string,
  error: PropTypes.object,
};

export default withAuth(ResetPassword);
