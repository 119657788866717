import React, { Component } from "react";
import { connect } from "react-redux";
import URLParser from "../shared/utilities/URLParser";
import { withRouter } from "react-router-dom";
import { verifyInviteUser, setInviteToken } from "./inviteActions";
import { bindActionCreators } from "redux";
import AppFooter from "../shared/components/AppFooter/appFooter";
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardHeader,
  CardText,
  Icon,
  Login
} from "@cdk-uip/react";
import { sendAmplitudeData } from "../utils/amplitude";

export class Invite extends Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
  }
  login() {
    this.props.history.push({
      pathname: "/login",
      search: this.props.location.search
    });
  }

  signup() {
    this.props.history.push({
      pathname: "/signup",
      search: this.props.location.search
    });
  }

  componentDidMount() {
    this.logAmplitudeInvitationClicked();
    setTimeout(() => {
      this.updateState();
    }, 1000); //timeout for redux store to get initialized
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.status !== nextProps.status &&
      nextProps.status === "pending"
    ) {
      if (nextProps.userExists) {
        this.login();
        this.logAmplitudeExistingUser();
      } else {
        this.signup();
        this.logAmplitudeNewUser();
      }
    }
  }

  updateState() {
    const queryStringParams = window.location.search;
    const verifier = URLParser.getQueryStringParamValue(
      "verifier",
      queryStringParams
    );
    this.props.setInviteToken(verifier);
    this.props.verifyInviteUser(verifier);
  }

  logAmplitudeExistingUser = _ => {
    const ampliData = {
      UserStatus: "Existing User"
    };
    sendAmplitudeData("Existing User Invitation Click", ampliData);
  };

  logAmplitudeNewUser = _ => {
    const ampliData = {
      UserStatus: "New User"
    };
    sendAmplitudeData("New User Invitation Click", ampliData);
  };

  logAmplitudeInvitationClicked = _ => {
    const ampliData = {
      Incident: "User Invitation"
    };
    sendAmplitudeData("Invitation Click", ampliData);
  };

  render() {
    const { status, message } = this.props;
    if (
      message === "Action URL is not valid" ||
      status === "joined" ||
      status === "denied"
    ) {
      return (
        <Login id={"cdk-login"}>
          <Card className="successcard">
            <CardHeader className="util__text--center">
              <CardTitle className="util__text--bold" large>
                Sorry...
              </CardTitle>
              <CardSubtitle className="util__text--secondary">
                The invitation you are trying to accept is either expired or
                invalid.
              </CardSubtitle>
            </CardHeader>
            <CardText className="util__text--center">
              <Icon className="page-not-found__icon">
                sentiment_dissatisfied
              </Icon>
            </CardText>
            <CardText className="c-text-login util__text--center util__text--secondary">
              Already have an Account?{" "}
              <span
                className="anchor c-text-login__link util__anchor--pointer"
                onClick={this.login}
              >
                {"Sign In"}
              </span>
            </CardText>
            <CardText className="c-text-signup util__text--center util__text--secondary">
              Don't have an Account?{" "}
              <span
                className="anchor c-text-signup__link util__anchor--pointer"
                onClick={this.signup}
              >
                {"Sign Up."}
              </span>
            </CardText>
          </Card>
          <AppFooter />
        </Login>
      );
    } else {
      return (
        <Login id={"cdk-login"}>
          <Card className="successcard">
            <CardHeader className="util__text--center">
              <CardTitle className="util__text--bold" large>
                Loading...
              </CardTitle>
            </CardHeader>
          </Card>
          <AppFooter />
        </Login>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    email: state.invite.email,
    orgName: state.invite.orgName,
    orgId: state.invite.orgId,
    userExists: state.invite.userExists,
    status: state.invite.status,
    invitationId: state.invite.invitationId,
    token: state.invite.token,
    message: state.invite.message
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setInviteToken,
      verifyInviteUser
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invite));
