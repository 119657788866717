import config from "../config/app.conf.json";

const remeberDeviceTrueSuffix = "?rememberDevice=true";
const remeberDeviceFalseSuffix = "?rememberDevice=false";
export default (factorId, data, isToRemeberDevice = false) => {
  let URL = config.api.mfaAuthnSendVerificationCode.replace(
    ":factorId",
    factorId
  );
  return {
    url: isToRemeberDevice
      ? `${URL}${remeberDeviceTrueSuffix}`
      : `${URL}${remeberDeviceFalseSuffix}`,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
};
