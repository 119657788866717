import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@cdk-uip/react";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";

class TermsCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAccepted: "",
      termsOpen: false
    };
    this.toggleTerms = this.toggleTerms.bind(this);
    this.toggleTncAccept = this.toggleTncAccept.bind(this);
  }

  toggleTerms() {
    let {
      signupDetails: { tncVersion }
    } = this.props;
    if (tncVersion) {
      this.setState(prevState => ({
        termsOpen: !prevState.termsOpen
      }));
    }
  }

  toggleTncAccept(checked) {
    const { saveSignupDetails } = this.props;
    this.setState(
      {
        termsAccepted: checked,
        termsOpen: false
      },
      () => {
        saveSignupDetails({
          termsAccepted: checked
        });
      }
    );
  }

  render() {
    const { termsAccepted, termsOpen } = this.state;
    const { signupDetails } = this.props;
    return (
      <div className="check-box">
        <Checkbox
          disabled={!signupDetails.tncVersion}
          required
          checked={termsAccepted}
          name="termsAccepted"
          onChange={event => {
            const { checked } = event.target;
            this.toggleTncAccept(checked);
          }}
        />
        <label className="checkbox">
          I accept the
          <span onClick={this.toggleTerms} className="fake-link">
            Terms and Conditions
          </span>
        </label>

        <TermsAndConditionsDialog
          tncVersion={signupDetails.tncVersion}
          tncText={signupDetails.tncText}
          updatedOn={signupDetails.createdOn}
          open={termsOpen}
          onCancel={() => this.toggleTncAccept(false)}
          onAccept={() => this.toggleTncAccept(true)}
        />
      </div>
    );
  }
}

TermsCheckbox.propTypes = {
  signupDetails: PropTypes.object.isRequired,
  saveSignupDetails: PropTypes.func.isRequired
};

export default TermsCheckbox;
