import React from "react";
import { Loader } from "@cdk-rds/loader";
import {  withAuth } from "@cdk-prod/fortellis-auth-context";


class LogoutContainer extends React.Component {
  render(){
    return this.props.auth.userData.sub
    ? <Logout {...this.props}/>
    :<></>
  }
}

export class Logout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let { auth } = this.props;
    auth.logout()
  }

  render() {
    return (
      <React.Fragment>
       loading ...
      </React.Fragment>
    );
  }
}



export default withAuth(LogoutContainer);
