import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  CardText,
  Form
} from "@cdk-uip/react";
import { Toast, TOAST_VARIANTS } from "@cdk-rds/toast";
import { PasswordInput } from '@cdk-rds/password-input';
import { PrimaryButton } from "@cdk-rds/button";
import ReCAPTCHA from "react-google-recaptcha";
import { TextFieldIcon } from "@cdk-uip/react-text-field";
import PasswordStrengthBar from "react-password-strength-bar";
import passwordValidator from "../shared/utilities/passwordValidator";

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      invalid: false,
      captchaResponseToken: "",
      passwordVisibility: false,
      canSubmitPassword: false,
      changePasswordError: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.captchaCallback = this.captchaCallback.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.passwordVisibilityOnOff = this.passwordVisibilityOnOff.bind(this);
    this.getPasswordStrengthLabelClass = this.getPasswordStrengthLabelClass.bind(
      this
    );
  }

  canSubmit() {
    if (passwordValidator.validate(this.state.password)) {
      if (this.state.captchaResponseToken) {
        return true;
      }
    }
    return false;
  }

  captchaCallback(captchaResponse) {
    this.setState({
      captchaResponseToken: captchaResponse
    });
  }

  handleChange(e) {
    const isPass = e.target.name === "password";
    this.setState({
      [e.target.name]: e.target.value,
      ...(isPass && {
        invalid: !passwordValidator.validate(e.target.value)
      })
    });
  }

  handleSubmit() {
    if (this.canSubmit()) {
      this.props.submit(this.state.password, this.state.captchaResponseToken);
      this.setState({canSubmitPassword: true, changePasswordError: "" });
    } else {
      this.setState({canSubmitPassword: false, changePasswordError: "Error has occured"});
    }
  }

  passwordVisibilityOnOff() {
    this.setState({
      passwordVisibility: !this.state.passwordVisibility
    });
  }

  getPasswordStrengthLabelClass() {
    if (this.state.password && this.state.password.length !== 0) {
      return passwordValidator.validate(this.state.password)
        ? "password-strength-label-green"
        : "password-strength-label-red";
    }
    return "";
  }

  render() {
    const { invalid } = this.state;
    return (
      <React.Fragment>
        <CardText className="challengecard__form-wrapper">
          <Form onSubmit={this.handleSubmit}>
            <div style={{ width: "389px" }}>
            <div
                  className="ssocard-text-field-wrapper"
                  style={{ width: "389px", marginTop: "14px" }}
                >
                { ((this.props.error && this.props.error !== "") || this.state.changePasswordError !== "") && <Toast
                      id="Error-title"
                      content="Error has occured"
                      variant={TOAST_VARIANTS.NEGATIVE}
                    />
}
</div>
            <PasswordInput className={"u-text--is-required"}
                    enableCustomValidation={false}
                    errorMessage=""
                    hasError={false}
                    helperText="Min. 8 characters, mixed capitalization, 1 number, and 1 symbol"
                    id="password"
                    isDisabled={false}
                    isReadOnly={false}
                    isFocused={true}
                    autoFocus
                    label="Password"
                    maxLength={100}
                    minLength={0}
                    name="password"
                    onChange={this.handleChange}
                    placeholder=""
                    prefixText=""
                    size="standard"
                    suffixText=""
                    toggleHideLabel="Show value"
                    toggleShowLabel="Hide value"
                    type="text"
                    value={this.state.password}
                />
                <PasswordStrengthBar
                    className={classnames("password-strength-bar", this.getPasswordStrengthLabelClass())}
                    password={(this.state.password.length > 7 && (passwordValidator.validate(this.state.password)?this.state.password:'1234567890p'))} minLength={8} 
                    scoreWords={['WEAK', 'WEAK','GOOD', 'GOOD', 'STRONG']}
                    barColors={['#ffffff10','#CC0000', '#008D00', '#008D00', '#008D00']}
                    shortScoreWord={this.state.password && this.state.password.length!==0 ?'TOO SHORT':''}
                    />
                </div>
            <div className="challengecard__pwdchange-recaptcha">
           <ReCAPTCHA 
              id="password__recaptcha"
              sitekey={this.props.recaptchaSiteKey}
              onChange={this.captchaCallback}
            />
            </div>
                <div
                  className="listOfOrganization__button-wrapper--primary"
                  style={{ width: "389px" }}
                >
                  <PrimaryButton
                    data-testid="changePassword"
                    isDisabled={false}
                    text="Change Password"
                    type="submit"
                    onClick={this.handleSubmit}
                    className="resetpwdcard__button--primary"
                  ></PrimaryButton>
                </div>
            
            <div className="ssocard__account-text--center" style={{width:"389px"}}>
              Already have an account?{" "}
              <span
                className="anchor c-text-signup__link util__anchor--pointer"
                onClick={this.props.goToLogin}
              >
                {"Sign in"}
              </span>
            </div>
          </Form>
        </CardText>
      </React.Fragment>
    );
  }
}

ChangePasswordForm.propTypes = {
  recaptchaSiteKey: PropTypes.string,
  goToLogin: PropTypes.func,
  submit: PropTypes.func,
  error: PropTypes.string
};

export default ChangePasswordForm;
