export default {
  SAVE_SIGN_UP_DETAILS: "SAVE_SIGN_UP_DETAILS",
  SIGNUP_POST_RECEIVE: "SIGNUP_POST_RECEIVE",
  SIGNUP_POST_ERROR: "SIGNUP_POST_ERROR",
  SIGNUP_POST_REQUEST: "SIGNUP_POST_REQUEST",
  LATEST_TNC_GET_REQUEST: "LATEST_TNC_GET_REQUEST",
  LATEST_TNC_GET_RECEIVE: "LATEST_TNC_GET_RECEIVE",
  LATEST_TNC_GET_ERROR: "LATEST_TNC_GET_ERROR",
  SET_INVITED_USER: 'SET_INVITED_USER'
};
