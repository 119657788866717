import { connect } from "react-redux";
import { actions } from "./settingsChangePasswordActions";
import ChangePassword from "./settingsChangePassword";
import { withRouter } from "react-router-dom";
import types from "./settingsChangePasswordTypes";

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (data, userId, accessToken) => {
      dispatch(actions.changePassword(data, userId, accessToken));
    },
    updateMessage: (message = "") => {
      dispatch({
        type: types.SETTINGS_UPDATE_CHANGE_PASSWORD_MESSAGE,
        message
      });
    }
  };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
