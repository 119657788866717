import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { TextFieldHelperText } from "@cdk-uip/react";
import { Input, INPUT_SIZES } from "@cdk-rds/input";
import { EmailInput } from "@cdk-rds/email-input";
import { PasswordInput } from "@cdk-rds/password-input";
import { bindActionCreators } from "redux";
import { Toast, TOAST_VARIANTS } from "@cdk-rds/toast";

import ShowHide from "../shared/components/ShowHide/ShowHide";
import PropTypes from "prop-types";
import { TextFieldIcon } from "@cdk-uip/react-text-field";
import PasswordStrengthBar from "react-password-strength-bar";
import passwordValidator from "../shared/utilities/passwordValidator";
import { saveSignupDetails } from "../Registration/registrationActions";

export class UserInformation extends React.Component {
  constructor(props) {
    super(props);
    const {
      fullName,
      firstName,
      lastName,
      primaryPhone,
      email,
      password,
      confirmPassword,
      organization,
      enableErrorAgaistField,
      isAPIError
    } = this.props.signupDetails;
    this.state = {
      fullName,
      firstName,
      lastName,
      primaryPhone,
      password,
      confirmPassword,
      email,
      isPasswordValid: true,
      passwordsMatch: true,
      isPhoneNumberValid: true,
      isEmailValid: true,
      passwordStrengthScore: 0,
      organization,
      isOrganizationValid: true,
      emailValidationMessage: ""
    };
    this.isValid = this.isValid.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.emailValidationMessage = this.emailValidationMessage.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    this.getPasswordStrengthLabelClass = this.getPasswordStrengthLabelClass.bind(
      this
    );
    // this.validateOrganization = this.validateOrganization.bind(this);
  }

  componentDidMount() {
    // if (this.state.email || this.state.primaryPhone) {
    //     this.isValid();
    // }

    this.isValid();
  }

  componentWillReceiveProps(nextProps) {
    const { email } = nextProps.signupDetails;
    if (email !== "") this.setState({ ...this.state, email });
    // if(enableErrorAgaistField) this.isValid();
  }

  handleChange(event) {
    const value = event.target.value;
    if (event.target.name === "fullName") {
      if (value.trim().indexOf(" ") !== -1) {
        this.setState(
          {
            fullName: value,
            firstName: value.trim().substring(0, value.lastIndexOf(" ")),
            lastName: value.trim().substring(value.lastIndexOf(" ") + 1)
          },
          this.saveUserInformation
        );
        return;
      }
      this.setState(
        {
          fullName: value,
          firstName: "",
          lastName: ""
        },
        this.saveUserInformation
      );
    }
    this.setState(
      {
        [event.target.name]: value
      },
      this.saveUserInformation
    );
  }

  validatePassword(event) {
    this.setState(
      {
        password: event.target.value,
        isPasswordValid:
          passwordValidator.validate(event.target.value) &&
          this.state.email.indexOf(event.target.value) === -1
      },
      this.saveUserInformation
    );
  }


  validateConfirmPassword(event) {
    this.setState(
      {
        confirmPassword: event.target.value,
        passwordsMatch: this.state.password === event.target.value
    },
    this.saveUserInformation
  );
  }

  validatePhoneNumber(event) {
    let number = event.target.value.replace(/\D/g, "");

    this.setState(
      {
        isPhoneNumberValid: number.length === 10,
        primaryPhone: number
      },
      this.saveUserInformation
    );
  }

  validateEmail(event) {
    const email = event.target.value;
    const isEmailValid = email.match(".+@.+\\..+");
    const state = {
      email: email,
      isEmailValid
    };
    this.setState(
      {
        ...state,
        emailValidationMessage: this.emailValidationMessage(isEmailValid, email)
      },
      this.saveUserInformation
    );
  }

  emailValidationMessage(isEmailValid, email) {
    const socialEmailList = [
      "aol",
      "gmail",
      "googlemail",
      "hotmail",
      "mail",
      "me",
      "icloud",
      "outlook",
      "rocketmail",
      "yahoo",
      "ymail",
      "webmail",
      "yandex",
      "comcast",
      "fastemail",
      "fastmail",
      "hey",
      "gmx",
      "protonmail",
      "zohomail",
      "tutanota"
    ]; //Add domains without TLD's to this array to blacklist any domain
    if (!isEmailValid) return "Please enter a valid email address.";
    if (email.split("@")[1] && email.split("@")[1].split(".")[0]) {
      if (
        isEmailValid &&
        socialEmailList.includes(email.split("@")[1].split(".")[0])
      )
        return "Must be a company email address";
    }
    return "";
  }

  formatPhoneNumber(number) {
    const phoneNumber = number.replace(/\D/g, "");
    const matches = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !matches ? number : `(${matches[1]}) ${matches[2]}-${matches[3]}`;
  }

  saveUserInformation() {
    let signupDetails = {
      isValid: this.isFormValid(),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      primaryPhone: this.state.primaryPhone,
      email: this.state.email,
      roles: ["developer"],
      password: this.state.password,
      confirmPassword: this.state.password,
      organization: this.state.organization
    };

    this.props.saveSignupDetails(signupDetails);
  }

  isValid() {
    this.validateEmail({
      target: {
        value: this.state.email
      }
    });

    this.validatePhoneNumber({
      target: {
        value: this.state.primaryPhone
      }
    });

    this.validatePassword({
      target: {
        value: this.state.password
      }
    });

    this.validateConfirmPassword({
      target: {
        value: this.state.confirmPassword
      }
    });

    return this.isFormValid();
  }

  isFormValid() {
    return (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.email !== "" &&
      this.state.email.match(".+@.+\\..+") &&
      this.state.primaryPhone !== "" &&
      this.state.primaryPhone.length === 10 &&
      this.state.password !== "" &&
      passwordValidator.validate(this.state.password) &&
      this.state.confirmPassword !== "" &&
      this.state.confirmPassword ===  this.state.password &&
      this.state.email.indexOf(this.state.password) === -1 &&
      this.state.passwordsMatch &&
      this.state.isPasswordValid &&
      // this.state.organization !== '' &&
      this.state.emailValidationMessage === ""
    );
  }

  getPasswordStrengthLabelClass() {
    if (this.state.password && this.state.password.length !== 0) {
      return passwordValidator.validate(this.state.password)
        ? "password-strength-label-green"
        : "password-strength-label-red";
    }
    return "";
  }

  render() {
    let passwordValidClass =
      "mdc-text-field--upgraded signupPage-passowrd-label";
    if (!this.state.isPasswordValid) {
      passwordValidClass =
        "mdc-text-field--upgraded mdc-input-invalid signupPage-passowrd-label";
    }

    return (
      <div className="stepper-registration-cards">
        <div className="flex-wrapper">
          {this.props.errorToaster.show && (
            <div style={{ maxWidth: "420px", padding: "10px" }}>
              <Toast
                content={this.props.errorToaster.message.subtitle}
                variant={TOAST_VARIANTS.NEGATIVE}
                id="toast-1"
              />
            </div>
          )}

          {this.props.isUserExists === false &&
            this.props.inviteStatus === "pending" && (
              <div style={{ maxWidth: "420px", padding: "10px" }}>
                <div className="sign-up-hearder-message-mobile">
                  {`Accept your invitation to join ${this.props.orgName} by
                  creating an account`}
                </div>
              </div>
            )}

          <div style={{ maxWidth: "420px", padding: "10px" }}>
            <Input
              size={INPUT_SIZES.STANDARD}
              id="fullName"
              label="Full Name"
              name="fullName"
              onChange={this.handleChange}
              placeholder="Full Name"
              helperText={
                <ShowHide
                  show={
                    (this.state.fullName.trim() !== "" &&
                      this.state.fullName.trim().indexOf(" ") === -1) ||
                    (this.props.signupDetails.enableErrorAgaistField &&
                      this.state.fullName.trim().indexOf(" ") === -1)
                  }
                >
                  <TextFieldHelperText
                    persistent
                    className={"mdc-label-invalid"}
                  >
                  Please enter your first name and last name
                  </TextFieldHelperText>
                </ShowHide>
              }
              enableCustomValidation
              errorMessage="Please enter your first name and last name"
              hasError={
                (this.state.fullName.trim() !== "" &&
                  this.state.fullName.trim().indexOf(" ") === -1) ||
                (this.props.signupDetails.enableErrorAgaistField &&
                  this.state.fullName.trim().indexOf(" ") === -1)
              }
              value={this.state.fullName}
            />
          </div>
          <div style={{ maxWidth: "420px", padding: "10px" }}>
            <EmailInput
              id="email"
              name="email"
              label="Work Email"
              value={this.state.email}
              placeholder="Work Email"
              isDisabled={
                this.props.isUserExists === false &&
                this.props.inviteStatus === "pending"
                  ? true
                  : false
              }
              helperText={
                <ShowHide
                  show={
                    (this.state.email !== "" &&
                      this.state.emailValidationMessage !== "") ||
                    (this.props.signupDetails.enableErrorAgaistField &&
                      this.state.emailValidationMessage !== "")
                  }
                >
                  <TextFieldHelperText persistent className="mdc-label-invalid">
                    {this.state.emailValidationMessage}
                  </TextFieldHelperText>
                </ShowHide>
              }
              onChange={this.validateEmail}
              onBlur={this.validateEmail}
              enableCustomValidation
              errorMessage={this.state.emailValidationMessage}
              hasError={
                (this.state.email !== "" &&
                  this.state.emailValidationMessage !== "") ||
                (this.props.signupDetails.enableErrorAgaistField &&
                  this.state.emailValidationMessage !== "")
              }
            />
          </div>

          <div style={{ maxWidth: "420px", padding: "10px" }}>
            <Input
              size={INPUT_SIZES.STANDARD}
              id="primaryPhone"
              label="Mobile number"
              name="primaryPhone"
              placeholder="Mobile number"
              type="tel"
              inputMode="tel"
              autoComplete="tel"
              helperText={
                <ShowHide
                  show={
                    (this.state.primaryPhone !== "" &&
                      !this.state.isPhoneNumberValid) ||
                    (this.props.signupDetails.enableErrorAgaistField &&
                      !this.state.isPhoneNumberValid)
                  }
                >
                  <TextFieldHelperText
                    persistent
                    className={"mdc-label-invalid"}
                  >
                    Please enter a valid North American phone number.
                  </TextFieldHelperText>
                </ShowHide>
              }
              hasError={
                (this.state.primaryPhone !== "" &&
                  !this.state.isPhoneNumberValid) ||
                (this.props.signupDetails.enableErrorAgaistField &&
                  !this.state.isPhoneNumberValid)
              }
              enableCustomValidation
              errorMessage="Please enter a valid North American phone number."
              value={this.formatPhoneNumber(this.state.primaryPhone)}
              onChange={this.validatePhoneNumber}
              onBlur={this.validatePhoneNumber}
            />
          </div>
          <div style={{ maxWidth: "420px", padding: "10px" }}>
            <PasswordInput
              id="password"
              label="Password"
              name="password"
              placeholder="At least 8 characters"
              size="standard"
              value={this.state.password}
              autoComplete="new-password"
              onChange={this.validatePassword}
              enableCustomValidation
              errorMessage="Min. 8 characters, mixed capitalization, 1 number, and 1 symbol"
              hasError={
                (this.state.password !== "" && !this.state.isPasswordValid) ||
                (this.props.signupDetails.enableErrorAgaistField &&
                  !this.state.isPasswordValid)
              }
            />
          </div>
          <div style={{ maxWidth: "420px", paddingTop: "10px" }}>
            <ShowHide
              show={
                !(
                  (this.state.password !== "" && !this.state.isPasswordValid) ||
                  (this.props.signupDetails.enableErrorAgaistField &&
                    !this.state.isPasswordValid)
                )
              }
            >
              <TextFieldHelperText
                persistent
                className={classnames("password-helper-text", {
                  "password-helper-text--invalid":
                    (this.state.password !== "" &&
                      !this.state.isPasswordValid) ||
                    (this.props.signupDetails.enableErrorAgaistField &&
                      !this.state.isPasswordValid)
                })}
              >
                Min. 8 characters, mixed capitalization, 1 number, and 1 symbol
              </TextFieldHelperText>
            </ShowHide>
            <PasswordStrengthBar
              className={classnames(
                "password-strength-bar",
                this.getPasswordStrengthLabelClass()
              )}
              password={
                this.state.password.length > 7 &&
                (passwordValidator.validate(this.state.password)
                  ? this.state.password
                  : "1234567890p")
              }
              minLength={8}
              scoreWords={["WEAK", "WEAK", "GOOD", "GOOD", "STRONG"]}
              barColors={[
                "#ffffff10",
                "#CC0000",
                "#008D00",
                "#008D00",
                "#008D00"
              ]}
              shortScoreWord={
                this.state.password && this.state.password.length !== 0
                  ? "TOO SHORT"
                  : ""
              }
            />
          </div>
          <div style={{ maxWidth: "420px", padding: "10px" }}>
            <PasswordInput
              id="confirm-password"
              label="Confirm Password"
              name="confirm password"
              placeholder="Re-enter the password"
              size="standard"
              value={this.state.confirmPassword}
              autoComplete="confirm-new-password"
              onChange={this.validateConfirmPassword}
              enableCustomValidation
              errorMessage="Please enter same password as above"
              hasError={
                this.state.confirmPassword !== "" &&
                this.state.password !== this.state.confirmPassword
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

UserInformation.propTypes = {
  saveSignupDetails: PropTypes.func.isRequired,
  signupDetails: PropTypes.object.isRequired,
  errorToaster: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    signupDetails: state.signupDetails,
    isUserExists: state.invite.userExists,
    inviteStatus: state.invite.status,
    orgName: state.invite.orgName,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveSignupDetails
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInformation);
