import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withRouter, Link } from "react-router-dom";
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withEntityContext } from "@cdk-prod/fortellis-entity-context";
import { Header } from "@fortellis/header";
import config from "../config/app.conf.json";
import "./header.scss";
import { LocaleConsumer } from "@fortellis/locale/dist/LocaleContext";

function AuthHeader({ auth, entityContext, location, mobile }) {
  const classes = classnames({
    "frc-header--cover": location.pathname === "/"
  });
  return (
    <LocaleConsumer>
      {localeContext => (
        <Header
          className={classes}
          mobile={mobile}
          authenticated={auth.isAuthenticated}
          entityContext={entityContext}
          localeContext={localeContext}
          login={auth.login}
          logout={auth.logout}
          nav={config.nav}
          routerLink={Link}
          selectedKey="developer"
          signup={config.signup}
          user={auth.userData}
        />
      )}
    </LocaleConsumer>
  );
}

AuthHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  entityContext: PropTypes.object,
  mobile: PropTypes.bool
};

export default withRouter(withAuth(withEntityContext(AuthHeader)));
