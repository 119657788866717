import { connect } from "react-redux";
import securitySettings from "./securitySettings";
import { actions } from "./securitySettingsActions";
import types from "./securitySettingsTypes";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => {
  return {
    apiResponse: state.securitySettings.apiResponse,
    actionType: state.securitySettings.actionType,
    factors: state.securitySettings.factors,
    message: state.securitySettings.message,
    status: state.securitySettings.status,
    shouldShowNumberChangeWarnig:
      state.securitySettings.shouldShowNumberChangeWarnig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFactors: (accessToken, userId) => {
      dispatch(actions.getFactors(accessToken, userId));
    },
    enrollFactor: (accessToken, data, userId) => {
      dispatch(actions.enrollFactor(accessToken, data, userId));
    },
    resendFactor: (accessToken, data, factorId, userId) => {
      dispatch(actions.resendFactor(accessToken, data, factorId, userId));
    },
    activateFactor: (accessToken, data, factorId, userId) => {
      dispatch(actions.activateFactor(accessToken, data, factorId, userId));
    },
    deactivateFactor: (accessToken, data, userId) => {
      dispatch(actions.deactivateFactor(accessToken, data, userId));
    },
    updateMessage: (message = "") => {
      dispatch({
        type: types.UPDATE_SECURITY_SETTINGS_SNACKBAR_MESSAGE,
        message
      });
    },
    showChangeNumberWarning: showWarning => {
      dispatch({
        type: types.SHOW_CHANGE_NUMBER_WARNING,
        showNumberChangeWarnig: showWarning
      });
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(securitySettings)
);
