import React from "react";
import Confirmation from "../Confirmation/confirmation";

const Failure = () => {
  return (
    <Confirmation
      title={"Email Verification Failure"}
      description={
        "We were unable to complete the verification of your email, please try again later.  " +
        "If the issue persists, please contact support."
      }
    />
  );
};

export default Failure;
