import React from "react";
import PropTypes from "prop-types";

/*
 * Tabs is the stateful component.
 * You can pass an index in the `selected` prop
 * to specify which tab is active by default.
 *
 * This component handles the entire tabs system.
 * It transforms its own children (if they are Tab or TabPanel) to pass the
 * required props in order to run automatically the system.
 */

class Tabs extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selected: props.selected
    };
  }

  setSelected(selected) {
    if (selected !== this.state.selected) {
      this.setState({
        selected
      });
    }
  }

  handleClick(tab) {
    return () => this.setSelected(tab);
  }

  renderTabList(child) {
    let tab = 0;

    return React.cloneElement(child, {
      children: React.Children.map(child.props.children, childTab => {
        if (childTab.type === Tab) {
          const _isActive = tab === this.state.selected;
          const _onClick = this.handleClick(tab);

          tab++;

          return React.cloneElement(childTab, {
            _isActive,
            _onClick
          });
        }

        return childTab;
      })
    });
  }

  renderChildren(children) {
    let panel = 0;

    return React.Children.map(children, child => {
      if (child.type === TabList) {
        return this.renderTabList(child);
      }

      if (child.type === TabPanel) {
        const _isActive = panel === this.state.selected;

        panel++;

        return React.cloneElement(child, {
          _isActive
        });
      }

      return child;
    });
  }

  render() {
    return (
      <div className="Tabs">{this.renderChildren(this.props.children)}</div>
    );
  }
}

const TabList = ({ children }) => <ul className="TabList">{children}</ul>;

const Tab = ({ _onClick, _isActive, children }) => (
  <li
    className={`Tab settings-tab ${_isActive ? "is-active" : ""}`}
    onClick={_onClick}
  >
    {children}
  </li>
);

const TabPanel = ({ _isActive, children }) => (
  <div className={`TabPanel  ${_isActive ? "is-active" : ""}`}>{children}</div>
);

Tabs.propTypes = {
  selected: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
};

TabList.propTypes = {
  children: PropTypes.any.isRequired
};

Tab.propTypes = {
  _onClick: PropTypes.any.isRequired,
  _isActive: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
};

TabPanel.propTypes = {
  _isActive: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
};

export { Tabs, Tab, TabList, TabPanel };
