import React from "react";
import "@cdk-uip/icons";
import PropTypes from "prop-types";
import { PrimaryButton } from "@cdk-rds/button";


const goToDealerPortal = () =>{
  window.open(process.env.REACT_APP_DEALERPORTAL_URL,'_self')
}

const RegistrationFooter = props => {
  const {
    goToLogin,
    handleSubmit,
    disableButton,
    registrationButtonDisplayText
  } = props;

  return (
    <div className="footer">
      <PrimaryButton
        type="submit"
        text={registrationButtonDisplayText}
        className="listOfOrganization__button--primary"
        onClick={() => handleSubmit()}
      ></PrimaryButton>
      <p className="login-text">
        Already have an account?{" "}
        <span className="anchor" onClick={goToLogin}>
          Sign In
        </span>
      </p>

      <div className="connect-cdk-signin-div">
        <p className="connect-cdk-signin-text">
          Are you a Dealer?{" "}
          <span className="connect-cdk-signin-link" onClick={goToDealerPortal}>
            Use your ConnectCDK dealership account to sign in.
          </span>
        </p>
      </div>
    </div>
  );
};

RegistrationFooter.propTypes = {
  goToLogin: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  registrationButtonDisplayText: PropTypes.string.isRequired
};

RegistrationFooter.defaultProps = {
  disableButton: false,
  registrationButtonDisplayText: "Create Account"
};

export default RegistrationFooter;
