import types from "./challengeTypes";
import status from "./challengeStatus";
import jsonService from "../shared/utilities/jsonService";

const initialized = {
  status: status.INITIALIZED,
  disabled: "disabled"
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.CHECK_TOKEN_POST_REQUEST:
      return initialized;
    case types.CHECK_TOKEN_POST_RECEIVE:
      var parsedToken = jsonService().parse(action.response);
      return {
        disabled: "",
        challengeSubmitted: true,
        checkTokenResponse: parsedToken,
        status: status.TOKEN_VALID,
        token: parsedToken.payload.state
      };
    case types.CHECK_TOKEN_POST_ERROR:
      console.error({
        Message: "Security Challenge Request Failed",
        Context: "CHALLENGE"
      });
      return {
        error:
          action.response ||
          "Error has occurred during the check token request!",
        status: status.TOKEN_INVALID
      };
    case types.NEW_PASSWORD_POST_REQUEST:
      return initialized;
    case types.NEW_PASSWORD_POST_RECEIVE:
      return {
        disabled: "",
        status: status.PASSWORD_SET,
        newPasswordResponse: action.response
      };
    case types.NEW_PASSWORD_POST_ERROR:
      return {
        error:
          action.response ||
          "Error has occurred during the password reset request!",
        status: status.PASSWORD_NOT_SET
      };
    default:
      return state;
  }
};
