import types from "./JoinOrganizationTypes";

const initialState = {
  organizations: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.JOINORG_GET_REQUEST:
      return {
        status: types.INITIALIZED,
        ...state
      };
    case types.JOINORG_GET_RECEIVE:
      return {
        ...state,
        ...action.response
      };
    case types.JOINORG_GET_ERROR:
      return {
        status: types.FAILED,
        type: action.type
      };
    default:
      return state;
  }
};
