import config from '../../config/app.conf.json'
import React from 'react';

const friendlyErrorParser = {
    getFriendlierErrorFromAction: (payloadMessage, defaultMessage) => {
        let friendlyError = defaultMessage;
        payloadMessage = JSON.stringify(payloadMessage);
        const errorMessages = {
            'An object with this field already exists in the current organization': {title: 'The email address is already in use',subtitle: <span dangerouslySetInnerHTML={{__html: `User already exists. Do you want to <a href=${config.appInfo.url}/login>sign in</a> instead?`}}></span>},
            'Password requirements': {title: 'Password error',subtitle: "Password should contain at least 8 characters, a lowercase letter, an uppercase letter, a number, a symbol. Your password cannot be any of your last 6 passwords."},
            'Does not match required pattern': {title: 'Your email format is incorrect',subtitle: 'Try something like jsmith@example.com.'},
            'Username must be in the form of an email address': {title: 'Your email format is incorrect',subtitle: 'Try something like jsmith@example.com.'}
        };

        for (let oktaError in errorMessages) {
            if (payloadMessage.indexOf(oktaError) !== -1) {
                friendlyError = errorMessages[oktaError];
            }
        }

        return friendlyError;
    }
};

export default friendlyErrorParser;
