import config from "../config/app.conf.json";

const getFactors = (accessToken, userId) => {
  let configUrl =
    config.api.mfaSettings.baseUrl.replace(":userId", userId) +
    config.api.mfaSettings.getFactors;
  return {
    url: configUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
};

const enrollFactor = (accessToken, data, userId) => {
  let configUrl =
    config.api.mfaSettings.baseUrl.replace(":userId", userId) +
    config.api.mfaSettings.enrollFactor;
  return {
    url: configUrl,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
};

const resendFactor = (accessToken, data, factorId, userId) => {
  let configUrl =
    config.api.mfaSettings.baseUrl.replace(":userId", userId) +
    config.api.mfaSettings.resendFactor.replace(":factorId", factorId);
  return {
    url: configUrl,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
};

const activateFactor = (accessToken, data, factorId, userId) => {
  let configUrl =
    config.api.mfaSettings.baseUrl.replace(":userId", userId) +
    config.api.mfaSettings.activateFactor.replace(":factorId", factorId);
  return {
    url: configUrl,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
};

const deactivateFactor = (accessToken, data, userId) => {
  let configUrl =
    config.api.mfaSettings.baseUrl.replace(":userId", userId) +
    config.api.mfaSettings.deactivateFactor;
  return {
    url: configUrl,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
};

export {
  getFactors,
  enrollFactor,
  resendFactor,
  activateFactor,
  deactivateFactor
};
