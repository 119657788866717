import types from "./inviteActionTypes";

const initializedState = {
  email: "",
  orgName: "",
  userExists: "",
  orgId: "",
  invitationId: "",
  status: "",
  token: "",
  requestInProgress: false
};

export default (state = initializedState, action) => {
  switch (action.type) {
    case types.INVITE_USER_GET_REQUEST:
      return { ...state, requestInProgress: true, message: "" };
    case types.INVITE_USER_GET_RESPONSE:
      return {
        ...state,
        ...action.response,
        requestInProgress: false,
        message: ""
      };
    case types.INVITE_USER_GET_ERROR:
      return {
        ...state,
        type: action.type,
        message: action.apierror.message ? action.apierror.message : "",
        requestInProgress: false
      };
    case types.SET_INVITE_TOKEN:
      return { ...state, token: action.token };
    default:
      return state;
  }
};
