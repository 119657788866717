import { CALL_API } from "@cdkglobal/react-core-reduxapi";

import changeSecurityQuestion from "../api/changeSecurityQuestion";
import {
  CHANGE_SECURITY_QUESTION_REQUEST,
  CHANGE_SECURITY_QUESTION_RECEIVE,
  CHANGE_SECURITY_QUESTION_ERROR,
  UPDATE_SECURITY_QUESTIION_MESSAGE
} from "./ChangeSecurityQuestionTypes";

export function updateSecurityQuestion(question, answer, password) {
  return async dispatch => {
    const response = await dispatch(
      callUpdateSecurityQuestion(question, answer, password)
    );
    return response.response.code === 200;
  };
}

function callUpdateSecurityQuestion(question, answer, password) {
  return {
    [CALL_API]: {
      ...changeSecurityQuestion(question, answer, password),
      types: [
        CHANGE_SECURITY_QUESTION_REQUEST,
        CHANGE_SECURITY_QUESTION_RECEIVE,
        CHANGE_SECURITY_QUESTION_ERROR
      ]
    }
  };
}

export function updateMessage(message = "") {
  return {
    type: UPDATE_SECURITY_QUESTIION_MESSAGE,
    message
  };
}
