import types from "./changePasswordTypes";

const defaultState = {
  status: types.UNINITIALIZED,
  disabled: "disabled",
  error: ""
};

const initializedState = {
  ...defaultState,
  status: types.INITIALIZED
};

function parseMessageFromErrorResp(errors) {
  const retryMsg = " Please try again.";
  const defaultErrMsg =
    "Error has occurred during the change password request." + retryMsg;
  if (!(Array.isArray(errors) && errors.length > 0)) {
    return defaultErrMsg;
  }
  let messageArray = errors[0].message || [];
  if (!(Array.isArray(messageArray) && messageArray.length > 0)) {
    return defaultErrMsg;
  }
  return `${messageArray[0].errorSummary}${retryMsg}` || defaultErrMsg;
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_POST_REQUEST:
      return initializedState;
    case types.CHANGE_PASSWORD_POST_RECEIVE:
      return {
        status: types.SUCCESS,
        disabled: true,
        error: ""
      };
    case types.CHANGE_PASSWORD_POST_ERROR:
      console.error({
        Message: "change password request failed",
        Context: "CHANGE_PASSWORD"
      });
      return {
        error: parseMessageFromErrorResp(action.apierror.payload.errors || []),
        status: types.FAILED
      };
    default:
      return state;
  }
};
