import React from "react";
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardHeader,
  CardText,
  Icon,
  Login
} from "@cdk-uip/react";
import { withRouter } from "react-router-dom";
import AppFooter from "../shared/components/AppFooter/appFooter";
import PropTypes from "prop-types";

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
  }

  login() {
    this.props.history.push({
      pathname: "/login",
      search: this.props.location.search
    });
  }

  signup() {
    this.props.history.push({
      pathname: "/signup",
      search: this.props.location.search
    });
  }

  render() {
    return (
      <Login id={"cdk-login"}>
        <Card className="successcard">
          <CardHeader className="util__text--center">
            <CardTitle className="util__text--bold" large>
              Page Not Found
            </CardTitle>
            <CardSubtitle className="util__text--secondary">
              The page you are trying to reach is either not available or not
              valid.
            </CardSubtitle>
          </CardHeader>
          <CardText className="util__text--center">
            <Icon className="page-not-found__icon">find_in_page</Icon>
          </CardText>
          <CardText className="c-text-login util__text--center util__text--secondary">
            Already have an Account?{" "}
            <span
              className="anchor c-text-login__link util__anchor--pointer"
              onClick={this.login}
            >
              {"Sign In"}
            </span>
          </CardText>
          <CardText className="c-text-signup util__text--center util__text--secondary">
            Don't have an Account?{" "}
            <span
              className="anchor c-text-signup__link util__anchor--pointer"
              onClick={this.signup}
            >
              {"Sign Up."}
            </span>
          </CardText>
        </Card>
        <AppFooter />
      </Login>
    );
  }
}

PageNotFound.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(PageNotFound);
