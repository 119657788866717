import types from "./inviteActionTypes";
import apis from "../api/invitation";
import { reduxapi } from "@cdkglobal/react-core";

export function verifyInviteUser(verifier) {
  return {
    [reduxapi.CALL_API]: {
      ...apis.invitation(verifier),
      types: [
        types.INVITE_USER_GET_REQUEST,
        types.INVITE_USER_GET_RESPONSE,
        types.INVITE_USER_GET_ERROR
      ]
    }
  };
}
export function setInviteToken(token) {
  return {
    type: types.SET_INVITE_TOKEN,
    token
  };
}
