import React, { Component } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@cdk-uip/react-dialog";
import { CircularProgress } from "@cdk-uip/react";
import { Button } from "@cdk-uip/react-button";
import "@cdk-uip/icons";
import PropTypes from "prop-types";
import { TextEditor } from "@fortellis/text-editor";
import moment from "moment";
class TermsAndConditionsDialog extends Component {
  render() {
    const { open, onCancel, tncText, updatedOn, onAccept } = this.props;
    return (
      <Dialog
        open={open}
        onCancel={onCancel}
        className="termsandconditions-dialog"
      >
        <DialogHeader>
          <div className="signup-dialog-header-container">
            Fortellis Terms and Conditions
          </div>
        </DialogHeader>
        <DialogBody scrollable>
          {!tncText && (
            <div className="tnc-circular-progress">
              <CircularProgress />
            </div>
          )}
          {tncText && (
            <div>
              <div className="tnc-updated-on">{`Updated: ${moment(
                updatedOn
              ).format("MMMM DD, YYYY")}`}</div>
              <TextEditor readOnly={true} rawEditorContentState={tncText} />
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button className="dialog-button" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="dialog-button" onClick={onAccept}>
            Accept
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

TermsAndConditionsDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  tncVersion: PropTypes.number,
  tncText: PropTypes.object,
  onAccept: PropTypes.func,
  updatedOn: PropTypes.string
};

export default TermsAndConditionsDialog;
