import resetpwd from "../api/resetpwd";
import types from "./resetPasswordTypes";
import { reduxapi } from "@cdkglobal/react-core";

export const actions = {
  resetpwd: data => {
    return {
      [reduxapi.CALL_API]: {
        ...resetpwd(data),
        types: [
          types.RESETPWD_POST_REQUEST,
          types.RESETPWD_POST_RECEIVE,
          types.RESETPWD_POST_ERROR
        ]
      }
    };
  }
};
