import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Login,
  Card,
  CardHeader,
  CardTitle,
  CardText,
  Subheading2,
  TextField,
  Button,
  Fluid,
  FluidItem,
  CircularProgress,
  Snackbar
} from "@cdk-uip/react";
import config from "../config/app.conf.json";

import SecurityQuestions from "../SecurityQuestions/securityQuestions";
import {
  updateSecurityQuestion,
  updateMessage
} from "./ChangeSecurityQuestionActions";

export class ChangeSecurityQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recoveryQuestion: "",
      recoveryAnswer: "",
      password: ""
    };

    this.disableButton = this.disableButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);
  }

  async handleSubmit() {
    const isSuccessful = await this.props.updateSecurityQuestion(
      this.state.recoveryQuestion,
      this.state.recoveryAnswer,
      this.state.password
    );
    if (isSuccessful) {
      window.location = config.clientSubDomainApps.developerNetworkApp.url;
    }
  }

  disableButton() {
    return (
      this.state.recoveryAnswer.length < 4 ||
      this.state.password === "" ||
      this.state.recoveryQuestion === ""
    );
  }

  hideSnackbar() {
    this.props.updateMessage();
  }

  render() {
    let progressIcon;
    if (this.props.isLoading) {
      progressIcon = (
        <Fluid
          className="circularprogress__outer-wrapper"
          halign="center"
          valign="middle"
        >
          <FluidItem className="circularprogress__direct-wrapper">
            <CircularProgress className="circularprogress__component" />
          </FluidItem>
        </Fluid>
      );
    }
    return (
      <Login id={"cdk-login"}>
        <Card className="resetpwdcard">
          <CardHeader className="util__text--center">
            <CardTitle className="util__text--bold" large>
              Change Your Security Question
            </CardTitle>
          </CardHeader>
          {progressIcon}
          <CardText className="successcard__form-wrapper util__text--center">
            <SecurityQuestions
              securityQuestion={this.state.recoveryQuestion}
              securityAnswer={this.state.recoveryAnswer}
              onQuestionChange={value => {
                this.setState({
                  recoveryQuestion: value
                });
              }}
              onAnswerChange={event => {
                this.setState({
                  recoveryAnswer: event.target.value
                });
              }}
            />
            <Subheading2>Confirm Password</Subheading2>
            <TextField
              required
              label={"Enter Your Current Password"}
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              onChange={event => {
                this.setState({
                  password: event.target.value
                });
              }}
            />
            <Button
              primary
              raised
              disabled={this.disableButton()}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </CardText>
        </Card>
        <Snackbar
          className=""
          show={!!this.props.message}
          timeout={8000}
          multiline={true}
          actionOnBottom={true}
          message={this.props.message}
          actionText={"Hide"}
          onAction={this.hideSnackbar}
          onClose={this.hideSnackbar}
        />
      </Login>
    );
  }
}

ChangeSecurityQuestion.propTypes = {
  updateSecurityQuestion: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    ...state.user.securityQuestion
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateSecurityQuestion,
      updateMessage
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSecurityQuestion);
