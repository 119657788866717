import React from "react";
import { ThemeProvider } from "styled-components";
import { fortellisTheme } from "@cdk-rds/core";
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink
} from "@cdk-rds/page-header";
import AppFooter from "../shared/components/AppFooter/appFooter";
import PropTypes from "prop-types";
import config from "../config/app.conf.json";

const homeURL = config.appPlatformInfo.ursl;

export const Confirmation = props => {
  return (
    <ThemeProvider theme={fortellisTheme}>
    <div style={{ backgroundColor: 'white'}}>
    {
      <PageHeaderWrapper className="listOfOrganization__Page-header">
        <PageHeaderTitle>
          <PageHeaderLink
            className="listOfOrganization__Page-link-home"
            iconPosition="leading"
            text="FORTELLIS"
            href={homeURL}
          />
        </PageHeaderTitle>
      </PageHeaderWrapper>
    }
    <div className="confirm__body">
      <div>
        <div className="confirm__title">{props.title}</div>
        {props.subtitle && (
          <div className="confirm__subtitle">{props.subtitle}</div>
        )}
        <div className="confirm__description">{props.description}</div>
        {props.isLwfRequest && (
          <div data-testid="lwfDialogDescription">
            <ul
              style={{
                textAlign: "left",
                marginTop: "10px",
                fontSize: "13px",
                fontWeight: 400
              }}
            >
              <li>
                You are almost done, please look out for the verification
                Email from <b>noreply@fortellis.io</b> in your Inbox.
              </li>
              <li>Follow the steps on the Email to "Activate Yourself"</li>
              <li>
                Didn’t receive the Email? Please check your junk/spam folder.
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="image-verify-account"></div>
    </div>
    <div className="confirmation-box__footer">
      <AppFooter />
    </div>
    </div>
    </ThemeProvider>
  );
};

Confirmation.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object,
  buttonText: PropTypes.string,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  isLwfRequest: PropTypes.bool
};

Confirmation.defaultProps = {
  buttonText: "SIGN IN",
  isLwfRequest: false,
  subtitle: ""
};

Confirmation.propTypes = {
  history: PropTypes.object.isRequired
};

export default Confirmation;
