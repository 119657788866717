import React, { Component } from "react";
import config from "../config/app.conf.json";
import { Input } from "@cdk-rds/input";
import { PasswordInput } from '@cdk-rds/password-input';
import classnames from 'classnames';
import { fortellisTheme } from "@cdk-rds/core";
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink
} from "@cdk-rds/page-header";
import {
  CardWrapper,
  CardBody,
  CardTitle,
  CardContent,
  CardSubtitle
} from "@cdk-rds/card";
import { Toast, TOAST_VARIANTS } from "@cdk-rds/toast";
import { PrimaryButton } from "@cdk-rds/button";
import AppFooter from "../shared/components/AppFooter/appFooter";

import { TextFieldHelperText } from "@cdk-uip/react";
import PropTypes from "prop-types";
import types from "./changePasswordTypes";
import { ThemeProvider } from "styled-components";
import PasswordStrengthBar from "react-password-strength-bar";
import passwordValidator from "../shared/utilities/passwordValidator";

const homeURL = config.appPlatformInfo.url;
const cardStyle = {
  boxShadow: "none",
  borderRadius: 0,
  backgroundColor: "inherit"
};
const finalResult = res => {
  console.log('Final result is ' + res);
};
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      passwordsMatch: true,
      changePasswordError: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validation = this.validation.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  validation() {
    let passwordsMatch =
      this.state.newPassword === this.state.confirmNewPassword;
    this.setState({
      passwordsMatch: passwordsMatch
    });
  }

  shouldDisable() {
    return (
      this.state.newPassword === "" ||
      this.state.confirmNewPassword === "" ||
      this.state.newPassword !== this.state.confirmNewPassword
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.changePassStatus.status === types.SUCCESS) {
      this.props.onPasswordChange({
        username: this.props.userId,
        password: this.state.newPassword
      });
    } else if (
      prevProps.changePassStatus.status === types.INITIALIZED &&
      this.props.changePassStatus.status === types.FAILED
    ) {
      this.setState({
        newPassword: "",
        confirmNewPassword: ""
      });
    }
  }

  handleSubmit() {
    if(!this.shouldDisable()) {
      this.setState({changePasswordError: ""});
      let passwordChangePayload = {
        newPassword: this.state.newPassword,
        oldPassword: this.props.tempPassword
      };
      this.props.changePassword(passwordChangePayload, this.props.userId);
    } else {
      this.setState({changePasswordError: "Some Error occured"});
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  getPasswordStrengthLabelClass() {
    if (this.state.newPassword && this.state.newPassword.length !== 0) {
        return (passwordValidator.validate(this.state.newPassword) ? 'password-strength-label-green' : 'password-strength-label-red');
    }
    return '';
}

  async handlePasswordChange(event) {
    await this.setState({
      [event.target.name]: event.target.value
    });
    this.validation();
  }

  render() {
    const passChangeStatus = this.props.changePassStatus.status;

    return (
      <ThemeProvider theme={fortellisTheme}>
        <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
        <PageHeaderWrapper className="listOfOrganization__Page-header" style={{"flex": 0}}>
          <PageHeaderTitle>
            <PageHeaderLink
              className="listOfOrganization__Page-link-home"
              iconPosition="leading"
              text="FORTELLIS"
              href={homeURL}
            />
          </PageHeaderTitle>
        </PageHeaderWrapper>
        <div className="parent-div" style={{flex: 1, padding:"0px"}}>
          <div style={{ marginLeft: "15%", paddingTop: "5%" }}>
            <CardWrapper style={cardStyle}>
              <CardBody>
                <CardTitle
                  className="listOfOrganization__text-head"
                  style={{
                    alignSelf: "center",
                    marginRight: 8,
                    width: "440px"
                  }}
                >
                  {passChangeStatus === types.SUCCESS
                    ? "Password changed successfully."
                    : "Change Password"}
                </CardTitle>
              </CardBody>
              <CardContent
                className="listOfOrganization__form-wrapper"
                style={{ alignSelf: "center" }}
              >
                {(!!this.props.error || this.state.changePasswordError !== "") ? (
                  <div>
                    <Toast
                      id="Error-title"
                      content={"Password change failed"}
                      variant={TOAST_VARIANTS.NEGATIVE}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  className="ssocard-text-field-wrapper"
                  style={{
                    width: "389px",
                    marginTop: "14px",
                    marginRight: "20px"
                  }}
                >               
                  <PasswordInput className={"u-text--is-required"}
                    enableCustomValidation={false}
                    errorMessage=""
                    hasError={false}
                    helperText="Min. 8 characters, mixed capitalization, 1 number, and 1 symbol"
                    id="newPassword"
                    isDisabled={false}
                    isReadOnly={false}
                    isFocused={true}
                    autoFocus
                    isRequired={true}
                    label="Password"
                    maxLength={100}
                    minLength={0}
                    name="newPassword"
                    onChange={this.handlePasswordChange}
                    placeholder=""
                    prefixText=""
                    size="standard"
                    suffixText=""
                    toggleHideLabel="Show value"
                    toggleShowLabel="Hide value"
                    type="text"
                    value={this.state.newPassword}
                />
                <PasswordStrengthBar
                    className={classnames("password-strength-bar", this.getPasswordStrengthLabelClass())}
                    password={(this.state.newPassword.length > 7 && (passwordValidator.validate(this.state.newPassword)?this.state.newPassword:'1234567890p'))} minLength={8} 
                    scoreWords={['WEAK', 'WEAK','GOOD', 'GOOD', 'STRONG']}
                    barColors={['#ffffff10','#CC0000', '#008D00', '#008D00', '#008D00']}
                    shortScoreWord={this.state.newPassword && this.state.newPassword.length!==0 ?'TOO SHORT':''}
                    />
                </div>

                <div
                  className="listOfOrganization__button-wrapper--primary"
                  style={{ width: "389px" }}
                >
                  <PrimaryButton
                    data-testid="changePassword"
                    isDisabled={false}
                    text="Change Password"
                    type="submit"
                    onClick={this.handleSubmit}
                    className="resetpwdcard__button--primary"
                  ></PrimaryButton>
                </div>
                <CardSubtitle
                  className="ssocard__account-text--center"
                  style={{ marginTop: "24px" }}
                >
                  Don't have an Account?{" "}
                  <span
                    className="anchor c-text-signup__link util__anchor--pointer"
                    onClick={this.props.goToSignup}
                  >
                    {"Sign Up."}
                  </span>
                </CardSubtitle>
              </CardContent>
            </CardWrapper>
          </div>
          <div className="image-wrapper">
            <div className="image-changePassword"></div>
          </div>
        </div>
        <div className="listOfOrganization__footer">
          <AppFooter />
        </div>
        </div>
      </ThemeProvider>
    );
  }
}

ChangePassword.propTypes = {
  changePassStatus: PropTypes.object,
  changePassword: PropTypes.func,
  userId: PropTypes.string,
  tempPassword: PropTypes.string,
  onPasswordChange: PropTypes.func,
  error: PropTypes.string
};

ChangePassword.defaultProps = {
  changePassStatus: {
    status: types.UN_INITALIZED
  },
  userId: "NO_USERID",
  tempPassword: "",
  error: ""
};

export default ChangePassword;
