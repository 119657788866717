import types from "./settingsChangePasswordTypes";

const initialState = {
  type: "",
  requestInProgress: false,
  isPasswordChangeSuccessful: false,
  message: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SETTINGS_CHANGE_PASSWORD_POST_REQUEST:
      return Object.assign({}, state, {
        requestInProgress: true
      });
    case types.SETTINGS_CHANGE_PASSWORD_POST_RECEIVE:
      return Object.assign({}, state, {
        type: action.type,
        message: "Your password has been updated.",
        requestInProgress: false,
        isPasswordChangeSuccessful: true,
      });
    case types.SETTINGS_CHANGE_PASSWORD_POST_ERROR:
      return Object.assign({}, state, {
        type: action.type,
        message: action.apierror.payload.errors[0].message[0].errorSummary,
        requestInProgress: false
      });
    case types.SETTINGS_UPDATE_CHANGE_PASSWORD_MESSAGE:
      return Object.assign({}, state, {
        type: action.type,
        message: action.message
      });
    default:
      return state;
  }
};
