import ldRedux from 'ld-redux';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { history } from './history';
import { intlReducer } from 'react-intl-redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { snackbarReducer } from 'react-redux-snackbar';
import { apiMiddleware } from '@cdkglobal/react-core-reduxapi';
import http from '@cdkglobal/react-core-http';
import thunk from 'redux-thunk';

import config from './config/app.conf.json';
import signIn from './Signin/signinReducer';
import resetPwd from './ResetPassword/resetPasswordReducer';
import joinOrg from './JoinOrganization/JoinOrganizationReducer';
import challenge from './Challenge/challengeReducer';
import accountActivation from './AccountActivation/accountActivationReducer';
import changePwd from './ChangePassword/changePasswordReducer';
import securityQuestion from './ChangeSecurityQuestion/ChangeSecurityQuestionReducer';
import signupDetails from './Registration/registrationReducer';
import settings from './SettingsTab/settingsReducer';
import settingsChangePassword from './SettingsTab/settingsChangePasswordReducer';
import securitySettings from './SecuritySettings/securitySettingsReducer';
import invite from './Invite/inviteReducer';

http.setDefaultOptions({
    credentials: 'include'
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const user = combineReducers({
    securityQuestion
});

const store = createStore(
    combineReducers({
        LD: ldRedux.reducer(),
        router: routerReducer,
        intl: intlReducer,
        snackbar: snackbarReducer,
        signupDetails,
        signIn,
        resetPwd,
        joinOrg,
        challenge,
        accountActivation,
        changePwd,
        user,
        settings,
        settingsChangePassword,
        securitySettings,
        invite
    }),
    composeEnhancers(applyMiddleware(thunk, routerMiddleware(history), apiMiddleware))
);

ldRedux.init(config.launchDarkly.client_id, store);
export { store };
