let uriSchema = require('../../Schema/uriSchema');

export default function() {
    let domainName;
    let pathName;
    let protocolType = 'http';
    let portNumber = 80;
    let queryString = '';

    this.domain = domainNameLocal => {
        if (domainNameLocal) {
            domainName = domainNameLocal;
        } else {
            throw new Error(`Invalid domain - ${domainNameLocal}`);
        }
        return this;
    };

    this.port = portNumberLocal => {
        if (portNumberLocal) {
            portNumber = portNumberLocal;
        } else {
            throw new Error(`Invalid port - ${portNumberLocal}`);
        }
        return this;
    };

    this.path = pathNameLocal => {
        if (pathNameLocal) {
            pathName = pathNameLocal;
        } else {
            throw new Error(`Invalid path - ${pathNameLocal}`);
        }
        return this;
    };

    this.query = queryOptions => {
        for (let queryOptionProp in queryOptions) {
            let queryValue = queryOptions[queryOptionProp];

            if (Array.isArray(queryValue)) {
                queryValue = queryValue.join(' ');
            }

            if (typeof queryValue === 'string' && (queryValue.indexOf('http') > -1)) {
                queryValue = encodeURIComponent(queryValue);
            }

            let queryContext = `${queryOptionProp}=${queryValue}`;
            queryString = queryString
                ? `${queryString}&${queryContext}`
                : `${queryContext}`;
        }
        return this;
    };

    this.protocol = protocolTypeLocal => {
        if (protocolTypeLocal) {
            protocolType = protocolTypeLocal;
        } else {
            throw new Error(`Invalid protocol - ${protocolTypeLocal}`);
        }
        return this;
    };

    this.build = () => {
        let uriDetails = {
            domain: domainName,
            port: portNumber,
            path: pathName,
            protocol: protocolType,
            query: queryString
        };

        if (!uriSchema(uriDetails)) {
            throw new Error(
                'Invalid url details: '.concat(JSON.stringify(uriDetails))
            );
        }

        return `${protocolType}://${domainName}/${pathName}?${queryString}`;
    };
}
