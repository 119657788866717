import React, { Component } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@cdk-uip/react-dialog";
import { Button } from "@cdk-uip/react-button";
import "@cdk-uip/icons";
import PropTypes from "prop-types";
import { TextEditor } from "@fortellis/text-editor";
import moment from "moment";
class SignInTncDialog extends Component {
  render() {
    const {
      open,
      onAccept,
      onReject,
      tncText,
      tncStatus,
      updatedOn
    } = this.props;
    return (
      <Dialog open={open} className="signin-tnc-dialog">
        <DialogHeader>
          <div className="signin-tnc-dialog-header-title">
            Updated Terms and Conditions
          </div>
          <div className="signin-tnc-dialog-header-subtitle">
            The Terms and Conditions have changed. Please accept, in order to
            proceed.
          </div>
        </DialogHeader>
        <DialogBody scrollable>
          <div className="tnc-updated-on">{`Updated: ${moment(updatedOn).format(
            "MMMM DD, YYYY"
          )}`}</div>
          {tncText && (
            <TextEditor readOnly={true} rawEditorContentState={tncText} />
          )}
        </DialogBody>
        <DialogFooter>
          <Button className="dialog-button" onClick={onReject}>
            Cancel
          </Button>
          <Button
            disabled={tncStatus.requesting}
            className="dialog-button"
            onClick={onAccept}
          >
            Accept
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

SignInTncDialog.propTypes = {
  open: PropTypes.bool,
  tncStatus: PropTypes.object,
  tncVersion: PropTypes.number,
  tncText: PropTypes.object,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  updatedOn: PropTypes.string
};

export default SignInTncDialog;
