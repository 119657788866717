import { listOfOrg } from "../api/joinOrg";
import types from "./JoinOrganizationTypes";
import { reduxapi } from "@cdkglobal/react-core";

export function joinOrg(accessToken, domain) {
  return {
    [reduxapi.CALL_API]: {
      ...listOfOrg(accessToken, domain),
      types: [
        types.JOINORG_GET_REQUEST,
        types.JOINORG_GET_RECEIVE,
        types.JOINORG_GET_ERROR
      ]
    }
  };
}
