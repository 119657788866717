import types from "./registrationTypes";
import friendlyErrorParser from "../shared/utilities/friendlyErrorParser";
import payloadParser from "../shared/utilities/payloadParser";

const initialState = {
    fullName: '',
    firstName: '',
    lastName: '',
    email: '',
    primaryPhone: '',
    roles: ['developer'],
    subscribedToUpdates: true,
    password: '',
    confirmPassword: '',
    recovery_question: {
        question: '',
        answer: ''
    },
    termsAccepted: false,
    captchaResponseToken: '',
    isValid: false,
    errorMessage: '',
    enableErrorAgaistField: false,
    isAPIError: false,
    registrationButtonDisplayText: 'Create Account',
    formIsSubmitting: false,
    tncVersion: true,
    isInvitedUser: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_SIGN_UP_DETAILS:
      return {
        ...state,
        ...action.signupDetails,
        isAPIError: false
      };
    case types.SIGNUP_POST_ERROR:
      let userFriendlyMessage = {
        title: "Signup Error.",
        subtitle: "Error has occurred during the signup!"
      };
      try {
        let payloadMessage = payloadParser.getErrorMessageFromPayload(action);
        userFriendlyMessage = friendlyErrorParser.getFriendlierErrorFromAction(
          payloadMessage,
          userFriendlyMessage
        );
      } catch (e) {
        console.error({
          Message: e,
          Context: "SIGN-UP-ERROR-WHILE-MSG-PARSING"
        });
      }
      console.error({
        Message: userFriendlyMessage,
        Context: "SIGN-UP"
      });
      return {
        ...state,
        errorMessage: userFriendlyMessage,
        formIsSubmitting: false,
        registrationButtonDisplayText: "Submit",
        isAPIError: true
      };
    case types.SIGNUP_POST_RECEIVE:
      return {
        ...state,
        formIsSubmitting: true,
        registrationButtonDisplayText: "Finished!",
        isAPIError: false
      };
    case types.SIGNUP_POST_REQUEST:
      return {
        ...state,
        formIsSubmitting: true,
        registrationButtonDisplayText: "Submitting...",
        isAPIError: false
      };

    case types.LATEST_TNC_GET_REQUEST:
      return {
        ...state,
        requestingLatestTnc: true
      };
    case types.LATEST_TNC_GET_RECEIVE:
      return {
        ...state,
        requestingLatestTnc: false,
        createdOn: action.response.created_on,
        tncVersion: action.response.version,
        tncText: JSON.parse(action.response.content)
      };
    case types.LATEST_TNC_GET_ERROR:
      return {
        ...state,
        requestingLatestTnc: false,
        tncVersion: null,
        tncText: null
      };
    case types.SET_INVITED_USER:
      return {
          ...state,
          isInvitedUser: action.isInvitedUser
      };  
    default:
      return state;
  }
};
