import React from "react";
import PropTypes from "prop-types";
import { fortellisTheme } from "@cdk-rds/core";
import {
  PageHeaderTitle,
  PageHeaderWrapper,
  PageHeaderLink
} from "@cdk-rds/page-header";
import {
  CardWrapper,
  CardBody,
  CardTitle,
  CardContent,
  CardSubtitle
} from "@cdk-rds/card";
import {
  Fluid,
  FluidItem,
  CircularProgress
} from "@cdk-uip/react";
import AppFooter from "../shared/components/AppFooter/appFooter";
import status from "./challengeStatus";
import { ThemeProvider } from "styled-components";
const cardStyle = {
  boxShadow: "none",
  borderRadius: 0,
  backgroundColor: "inherit"
};
function ChallengeCard({ title, content, homeURL, snackbar, currentStatus }) {
  return (
    <ThemeProvider theme={fortellisTheme}>
        <div style={{height:"100vh", minHeight:"100vh",display: "flex", flexDirection: "column"}}>
        <PageHeaderWrapper className="listOfOrganization__Page-header" style={{"flex": 0}}>
          <PageHeaderTitle>
            <PageHeaderLink
              className="listOfOrganization__Page-link-home"
              iconPosition="leading"
              text="FORTELLIS"
              href={homeURL}
            />
          </PageHeaderTitle>
        </PageHeaderWrapper>
        <div className="parent-div" style={{flex: 1, padding:"0px"}}>
          <div style={{ marginLeft: "15%", paddingTop: "5%" }}>
            <CardWrapper style={cardStyle}>
              <CardBody>
                <CardTitle
                  className="listOfOrganization__text-head"
                  style={{
                    alignSelf: "center",
                    marginRight: 8,
                    marginLeft: 0,
                    width: "500px"
                  }}
                >
                  {title}
                </CardTitle>
              </CardBody>
              </CardWrapper>
        <div
          style={{
            display: currentStatus === status.INITIALIZED ? "block" : "none",
            minHeight: "50px"
          }}
        >
          <Fluid
            className="circularprogress__outer-wrapper"
            halign="center"
            valign="middle"
          >
            <FluidItem className="circularprogress__direct-wrapper">
              <CircularProgress className="circularprogress__component" />
            </FluidItem>
          </Fluid>
        </div>
        {content}
        
        
      </div>
      <div className="image-wrapper">
            <div className="image-changePassword"></div>
          </div>
      {snackbar}
      </div>
      
        <div className="ssocard__footer">
          <AppFooter />
        </div>
      </div>
      </ThemeProvider>
  );
}

ChallengeCard.propTypes = {
  title: PropTypes.element,
  content: PropTypes.element,
  snackbar: PropTypes.element,
  homeURL: PropTypes.string,
  currentStatus: PropTypes.string
};

export default ChallengeCard;
