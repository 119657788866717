import config from "../config/app.conf.json";
import http from "@cdkglobal/react-core-http";

export const listOfOrg = (accessToken, userEmailDomain) => {
  http.clearDefaultOptions();
  let configUrl = config.api.joinOrg.replace(":domain", userEmailDomain);
  return {
    url: configUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
};
