export default {
  GET_FACTORS_RECEIVE: "GET_FACTORS_RECEIVE",
  GET_FACTORS_ERROR: "GET_FACTORS_ERROR",
  GET_FACTORS_REQUEST: "GET_FACTORS_REQUEST",
  ENROLL_FACTOR_POST_RECEIVE: "ENROLL_FACTOR_POST_RECEIVE",
  ENROLL_FACTOR_POST_ERROR: "ENROLL_FACTOR_POST_ERROR",
  ENROLL_FACTOR_POST_REQUEST: "ENROLL_FACTOR_POST_REQUEST",
  RESEND_FACTOR_POST_REQUEST: "RESEND_FACTOR_POST_REQUEST",
  RESEND_FACTOR_POST_RECEIVE: "RESEND_FACTOR_POST_RECEIVE",
  RESEND_FACTOR_POST_ERROR: "RESEND_FACTOR_POST_ERROR",
  ACTIVATE_FACTOR_POST_REQUEST: "ACTIVATE_FACTOR_POST_REQUEST",
  ACTIVATE_FACTOR_POST_RECEIVE: "ACTIVATE_FACTOR_POST_RECEIVE",
  ACTIVATE_FACTOR_POST_ERROR: "ACTIVATE_FACTOR_POST_ERROR",
  DEACTIVATE_FACTOR_POST_REQUEST: "DEACTIVATE_FACTOR_POST_REQUEST",
  DEACTIVATE_FACTOR_POST_RECEIVE: "DEACTIVATE_FACTOR_POST_RECEIVE",
  DEACTIVATE_FACTOR_POST_ERROR: "DEACTIVATE_FACTOR_POST_ERROR",
  INITIALIZED: "INITIALIZED",
  UN_INITALIZED: "UN_INITALIZED",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  UPDATE_SECURITY_SETTINGS_SNACKBAR_MESSAGE:
    "UPDATE_SECURITY_SETTINGS_SNACKBAR_MESSAGE",
  SHOW_CHANGE_NUMBER_WARNING: "SHOW_CHANGE_NUMBER_WARNING"
};
